import { useMemo } from 'react';

import formatLabel from '@chessarena/components/lib/utils/formatLabel';
import { usePaywallPopupContext } from '@components/molecules/popups/PaywallPopup/_context';

import { ePaywallPopupStep } from './_types';
import { useApplicationContext } from '@application';
import { useNewProPlanPrices } from '@utils/hooks/_useProPlanPrice.hook';
import { ePriceId } from 'shared/types';

export const usePaywallPopupPeriods = () => {
  const { localization } = useApplicationContext();

  const { step, userPlan } = usePaywallPopupContext();

  const { annualFull, annualByMonth, monthly, discount } =
    useNewProPlanPrices();

  const periods = useMemo(() => {
    return [
      {
        id: ePriceId.PRO_ANNUAL,
        title: localization.paywall.payment_settings.periods.items.annual.title,
        price: formatLabel(
          localization.paywall.payment_settings.periods.items.annual.price,
          { value: annualByMonth }
        ),
        description: formatLabel(
          localization.paywall.payment_settings.periods.items.annual
            .description,
          { value: annualFull }
        ),
        discount: formatLabel(
          localization.paywall.payment_settings.periods.items.annual.discount,
          { value: discount }
        ),
      },
      {
        id: ePriceId.PRO_MONTHLY,
        title:
          localization.paywall.payment_settings.periods.items.monthly.title,
        price: formatLabel(
          localization.paywall.payment_settings.periods.items.monthly.price,
          { value: monthly }
        ),
        description: formatLabel(
          localization.paywall.payment_settings.periods.items.monthly
            .description,
          { value: discount }
        ),
        disabled: userPlan === ePriceId.PRO_MONTHLY,
      },
    ];
  }, [
    localization.paywall.payment_settings.periods.items,
    annualByMonth,
    annualFull,
    discount,
    monthly,
    userPlan,
  ]);

  return step === ePaywallPopupStep.PAYMENT_SETTINGS ? periods : undefined;
};

import React, { FC } from 'react';
import styled from 'styled-components';
import styles from './_fide_users_list.module.scss';
import { BaseButton } from 'shared/atoms/buttons/_baseButton';

export interface IFideUserListConfirmButton {
  onClick: () => void;
  text: string;
}

export const FideUserListConfirmButton: FC<IFideUserListConfirmButton> = ({
  onClick,
  text,
}) => {
  return (
    <$ConfirmButton
      theme="white_inverted"
      buttonType="primary"
      type="submit"
      className={styles.complete_button}
      onClick={onClick}
    >
      {text}
    </$ConfirmButton>
  );
};

const $ConfirmButton = styled(BaseButton)`
  min-width: 149px;
  padding-top: 11px;
  height: 45px;
`;

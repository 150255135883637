var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SwitchCheckbox } from '../../../atoms';
import { CardForm } from '../_cardForm';
import { LoadingBaseButton } from '../../buttons';
import { PromocodeForm } from '../_promocodeForm';
import { $PaymentBody, $PaymentContainer, $PaymentForm, $PaymentLeft, $PaymentRight, paymentCheckboxStyles, $FormError, $PaymentFooter, $PaymentInfo, paymentButtonStyles, } from './_styles';
import { cardCvcFormater, cardNumberFormater } from '../../../../utils/_formatters';
import { useForm } from '../../../../hooks/_useForm.hook';
import { cardNumberValidator } from '../../../../utils/_validators';
/**
 * Форма оплаты
 * @param {ReactComponent} paymentInfo - компонент с инфо срава от кнопки оплаты
 * @param {ReactComponent} button - компонент с кнопкой
 * @param {ReactComponent} formContent - дополнительный контент
 * @param {string} className - внешний класс
 * @param {Function} onSubmit- колбэк сабмита формы
 * @param {boolean} paymentRequest - выполняется ли запрос на оплату
 * @param {Function} promocodeHandler- колбэк сабмита промокода
 * @param {boolean} promocodeRequest - выполняется ли запрос на проверку промокода
 * @param {number} promocodeApplied - применён ли промокод
 * @param {string} promocodeError - текст ошибки проверки промокода
 * @param {string} error - сообщение об ошибке
 * @param {Function} resetPaymentError - функция сброса ошибки
 * @param {boolean} showPromocodeForm - открытие поля для ввода промокода
 * @param {Function} showPaypal - показывать ли кнопку оплаты paypal
 */
export const PaymentForm = ({ paymentInfo, className = '', onSubmit, paymentRequest, promocodeHandler, promocodeRequest, promocodeError, error, resetPaymentError, button, formContent, promocodeApplied = false, showPromocodeForm = true, showPaypal = false, paypalInited = false, theme, }) => {
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (error && error.param)
            return;
        resetPaymentError();
        if (!isValid) {
            setShowErrors(true);
            return;
        }
        return onSubmit(inputState);
    });
    const { inputState, onChange, isValid, onSubmit: $onSubmit } = useForm({
        card_number: {
            value: '',
            required: true,
            formatter: (val) => cardNumberFormater(val),
            validator: (val) => cardNumberValidator(val),
        },
        exp_month: {
            value: '',
            required: true,
        },
        exp_year: {
            value: '',
            required: true,
        },
        securitycode: {
            value: '',
            required: true,
            formatter: (val) => cardCvcFormater(val),
            validator: (val) => (val.length < 3 ? 'Incorrect code' : null),
        },
        name: {
            value: '',
            required: true,
        },
    }, handleSubmit);
    const [havePromocode, setHavePromocode] = useState(false);
    const [promocode, setPromocode] = useState('');
    const [showErrors, setShowErrors] = useState(false);
    const defaultButton = (React.createElement($LoadingBaseButton, { theme: "white", loading: paymentRequest }, "Checkout"));
    useEffect(() => {
        if (error && error.param === 'number') {
            resetPaymentError();
        }
    }, [inputState.card_number.value]);
    useEffect(() => {
        if (error && error.param === 'exp_month') {
            resetPaymentError();
        }
    }, [inputState.exp_month.value]);
    useEffect(() => {
        if (error && error.param === 'exp_year') {
            resetPaymentError();
        }
    }, [inputState.exp_year.value]);
    useEffect(() => {
        if (error && error.param === 'name') {
            resetPaymentError();
        }
    }, [inputState.name.value]);
    useEffect(() => {
        if (error && error.param === 'cvc') {
            resetPaymentError();
        }
    }, [inputState.securitycode.value]);
    useEffect(() => {
        if (error && !error.param) {
            resetPaymentError();
        }
    }, [
        inputState.card_number.value,
        inputState.exp_month.value,
        inputState.exp_year.value,
        inputState.name.value,
        inputState.securitycode.value,
    ]);
    return (React.createElement($PaymentContainer, { className: className },
        React.createElement($PaymentForm, { onSubmit: $onSubmit },
            React.createElement($PaymentBody, null,
                React.createElement($PaymentLeft, null,
                    React.createElement(CardForm, { showErrors: showErrors, cardState: inputState, onChange: onChange, showPaypal: showPaypal, paypalInited: paypalInited, theme: theme })),
                showPromocodeForm && (React.createElement($PaymentRight, null,
                    React.createElement($SwitchCheckbox, { checked: havePromocode, changeHandler: () => setHavePromocode(!havePromocode) }, "I have a promo code"),
                    havePromocode && (React.createElement(PromocodeForm, { promocode: promocode, setPromocode: setPromocode, promocodeApplied: promocodeApplied, promocodeHandler: promocodeHandler, promocodeRequest: promocodeRequest, promocodeError: promocodeError }))))),
            error && React.createElement($FormError, null, error.message),
            formContent ? formContent : null,
            React.createElement($PaymentFooter, null,
                button ? button : defaultButton,
                paymentInfo && React.createElement($PaymentInfo, null, paymentInfo)))));
};
const $LoadingBaseButton = styled(LoadingBaseButton) `
  ${paymentButtonStyles}
`;
const $SwitchCheckbox = styled(SwitchCheckbox) `
  ${paymentCheckboxStyles}
`;

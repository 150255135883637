import GameBoardSettingsMoves from '@chessarena/components/lib/game/GameBoardSettingsMoves';
import { GameBoardSettingsPopup } from '@chessarena/components/lib/game/GameBoardSettingsPopup';
import { FC, memo } from 'react';
import { useBoardSettingsPopupContext } from '../context';

export const LastMoveSelector: FC = memo(function LastMovesField() {
  const {
    actions: { onLastMovesChange },
    computed: { lastMovesData },
    state: {
      currentSettings: { lastMoveTheme },
    },
  } = useBoardSettingsPopupContext();

  return (
    <GameBoardSettingsPopup.Field label={'Last moves'}>
      <GameBoardSettingsMoves
        id="last-moves"
        onMoveHandleChange={onLastMovesChange}
        moveInputRadioValue={lastMoveTheme}
        moveListItems={lastMovesData}
      />
    </GameBoardSettingsPopup.Field>
  );
});

import React from 'react';
import { Preloader } from '../../preloaders';
import { ProgressiveImage } from '../../../atoms';
/**
 * Компонент: изображение с ленивой загрузкой
 * @param {string} src - source
 * @param {string} placeholder - placeholder
 * @param {string} preloaderSize - размер прелодера
 * @param {string} alt - alt
 * @param {string} className - внешний класс
 */
export const LazyImage = ({ src, alt, preloaderSize, placeholder = '', className = '', }) => {
    return (React.createElement(ProgressiveImage, { src: src, placeholder: placeholder, rootMargin: "0% 0% 20% 0%", threshold: [0] }, (src, loading) => {
        return loading ? (React.createElement("div", { className: className },
            React.createElement(Preloader, { size: preloaderSize }))) : (React.createElement("img", { className: className, src: src, alt: alt, draggable: false }));
    }));
};

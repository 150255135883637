import React, { useEffect, Fragment, useMemo } from 'react';
import { useRouter } from 'next/router';
import styles from './_fide_popup.module.scss';
import { BasePopup, TabSelector, BaseRadio } from 'shared/atoms';
import { fideAccountSelectErrorCodes } from 'shared/types';
import { FideForm } from './fragments/_FideForm';
import { FideUsersList } from './fragments/_FideUsersList';
import { HaveFide } from './fragments/_HaveFide';
import { useFideSubscriptionStore } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';
import { openPopup } from '@utils/_router';
import { usePopupsContext } from '@store/context/popupsContext/_context';
import { useApplicationContext } from '@application';

// TODO: описание компонента и вынести интерфейс
export const FidePopup: React.FC = () => {
  const router = useRouter();

  const { localization: l } = useApplicationContext();
  const userData = useUserDataStore('data');
  const userDataRequest = useUserDataStore('data_request');
  const fideError = useFideSubscriptionStore('fide_error');
  const fideUsers = useFideSubscriptionStore('find_fide_users');

  const {
    state: {
      fide: { currentTab },
    },
    computed: {
      fide: { freePro, tabs },
    },
    actions: { setFideCurrentTab, closeFidePopup, setFideId, setPhoto },
  } = usePopupsContext();

  const from = router.query.from as string;

  const changeTabHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFideCurrentTab(e.target.value);
  };

  const nextStep = (): void => {
    const params = new URLSearchParams();
    params.append('popup', 'fide-verification');
    if (from) {
      params.append('from', from);
    }
    if (freePro) {
      params.append('free_pro', 'true');
    }

    openPopup(`?${params.toString()}`);
  };

  useEffect(() => {
    if (!userData && !userDataRequest) {
      closeFidePopup();
    }
  }, [userData, userDataRequest]);

  useEffect(() => {
    const isAlreadyAssigned =
      fideError?.fide_id &&
      typeof fideError.fide_id !== 'string' &&
      fideError.fide_id.code === fideAccountSelectErrorCodes.ALREADY_ASSIGNED;

    if (freePro || isAlreadyAssigned) {
      setFideCurrentTab(tabs[1].value);
    }
  }, [fideError?.fide_id, freePro]);

  const subtitleText: string | JSX.Element = useMemo(() => {
    if (currentTab === tabs[0].value) {
      return l.registration.fill_in_the_fide;
    } else {
      if (
        fideError?.fide_id &&
        typeof fideError.fide_id !== 'string' &&
        fideError.fide_id.masked_email
      ) {
        return (
          <Fragment>
            <span>{l.registration.account_is_linked} </span>
            <span>{`${fideError.fide_id.masked_email} `}</span>
            <span>{l.registration.email_address_is_yours} </span>
            <a
              className={styles.support_link}
              href="mailto:support@chessarena.com"
            >
              support@chessarena.com
            </a>
            <span>{` ${l.registration.if_you_believe}`}</span>
          </Fragment>
        );
      }

      if (!fideUsers) {
        return l.registration.fill_in_the_fide;
      } else {
        if (!fideUsers.results.length) {
          return (
            <Fragment>
              <span>{l.registration.we_didnt_find} </span>
              <a
                className={styles.support_link}
                href="mailto:support@chessarena.com"
              >
                support@chessarena.com
              </a>
            </Fragment>
          );
        } else {
          return l.registration.we_found_players;
        }
      }
    }
  }, [fideError, currentTab, fideUsers]);

  // поправить ImageUpload
  useEffect(() => {
    if (currentTab === 'i_have') setPhoto('');
    if (currentTab === 'create') setFideId(null);
  }, [currentTab]);

  useEffect(() => {
    if (
      userData &&
      (userData.fide_id || userData.fide_verified_status !== null)
    ) {
      closeFidePopup();
    }
  }, [userData]);

  return (
    <BasePopup
      className={styles.popup}
      setShowPopup={closeFidePopup}
      color="pink"
    >
      <div className={styles.container}>
        <div className={styles.heading}>
          <h3 className={styles.title}>{l.registration.new_pro_account}</h3>
        </div>

        <div className={styles.subheading}>
          <p className={styles.info_text}>{subtitleText}</p>
        </div>

        {fideUsers && fideUsers.results.length ? (
          <FideUsersList users={fideUsers.results} nextStep={nextStep} />
        ) : (
          <Fragment>
            {!freePro && (
              <Fragment>
                <div className={styles.popup_tabs_container}>
                  <TabSelector
                    className={styles.popup_tabs}
                    tabs={tabs}
                    currentTab={currentTab}
                    setCurrentTab={setFideCurrentTab}
                    type="white_outline"
                  />
                </div>
                <div className={styles.popup_radio_container}>
                  {tabs.map((tab) => (
                    <BaseRadio
                      key={tab.value}
                      className={styles.popup_radio}
                      value={tab.value || ''}
                      selected={currentTab}
                      changeHandler={changeTabHandler}
                    >
                      {tab.text}
                    </BaseRadio>
                  ))}
                </div>
              </Fragment>
            )}

            {currentTab === 'create' ? (
              <FideForm nextStep={nextStep} />
            ) : (
              <HaveFide />
            )}
          </Fragment>
        )}
      </div>
    </BasePopup>
  );
};

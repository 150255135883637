var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { $Container, $AvatarStyle, $IsOnlineStatus } from './_styles';
import { LazyImage } from '../../../molecules/images';
import { useClassNames } from '../../../../hooks/_useClassNames.hook';
/**
 * Компонент аватара пользователя с ленивой загрузкой
 * @param {string || null} image - аватар пользователя
 * @param {string} size - размер аватара
 * @param {boolean} clickable - кликабельный ли аватар
 * @param {function} changeHandler - обработчик события нажатия на аватар
 * @param {string} className - внешний класс
 * @param {boolean} isOnline - флаг показывающий онлайн ли пользователь
 * @param {object} otherProps - любые другие значения, передаваемые компоненту
 */
export const LazyAvatar = (_a) => {
    var { size = '40px', image, clickable = false, clickHandler, preloaderSize = '40px', className = '', isOnline } = _a, otherProps = __rest(_a, ["size", "image", "clickable", "clickHandler", "preloaderSize", "className", "isOnline"]);
    const defaultImage = 'https://gaming-images.worldchess.com/media/default-user-avatar.svg';
    let isDefaultImage = false;
    const getAvatarImage = (image) => {
        if (!image || !image.length) {
            isDefaultImage = true;
            return defaultImage;
        }
        return image;
    };
    const avatarClasses = useClassNames({
        ['clickable']: clickable,
        [className]: true,
    });
    return (React.createElement($Container, { size: size, className: avatarClasses },
        React.createElement($LazyImage, Object.assign({ src: getAvatarImage(image), alt: "Event cover", size: size, preloaderSize: preloaderSize, isDefaultImage: isDefaultImage, clickable: clickable }, otherProps)),
        isOnline && React.createElement($IsOnlineStatus, null)));
};
const $LazyImage = styled(LazyImage) `
  ${$AvatarStyle}
`;

import {
  IChessboardSettingsContextState,
  TChessboardSettingsActions,
} from '../_types';
import { useBoardThemesSliderThemeItems } from './_useBoardThemesSliderThemeItems.hook';
import { useLastMovesItems } from './_useLastMovesItems.hook';
import { useLegalMovesItems } from './_useLegalMovesItems.hook';
import { useSaveButtonData } from './_useSaveButtonData.hook';
import { useSwitchItems } from './_useSwitchItems.hook';

export const useComputed = (
  state: IChessboardSettingsContextState,
  actions: TChessboardSettingsActions
) => {
  const lastMovesData = useLastMovesItems(state);
  const legalMovesData = useLegalMovesItems(state);
  const boardThemesSliderItems = useBoardThemesSliderThemeItems();
  const switchItems = useSwitchItems(state, actions);
  const saveButton = useSaveButtonData(state, boardThemesSliderItems);

  return {
    lastMovesData,
    legalMovesData,
    boardThemesSliderItems,
    switchItems,
    saveButton,
  };
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { $Text, $Radio, $Input, $RadioWrapper } from './_styles';
import { areObjectsEqual } from '../../../../helpers/_equality';
import { useClassNames } from '../../../../hooks/_useClassNames.hook';
function arePropsEqual(prevProps, nextProps) {
    return areObjectsEqual(prevProps, nextProps);
}
/**
 * radio-переключатель
 * @param {boolean} disabled - блокирует доступ и изменение значения флага
 * @param {string} selected  - текущее выбраное значение
 * @param {string} value - значение инпута
 * @param {string} className - внешний класс
 * @param {function} changeHandler - обработчик события изменения значения флага
 * @param {object} otherProps - любые другие значения, передаваемые полю ввода
 */
export const BaseRadio = React.memo((_a) => {
    var { disabled = false, selected, changeHandler, className = '', value, children } = _a, otherProps = __rest(_a, ["disabled", "selected", "changeHandler", "className", "value", "children"]);
    const labelClasses = useClassNames({
        [className]: true,
        ['disabled']: disabled,
    });
    return (React.createElement($RadioWrapper, Object.assign({ className: labelClasses }, otherProps),
        React.createElement($Input, { type: "radio", value: value, checked: value === selected, disabled: disabled, onChange: changeHandler }),
        React.createElement($Radio, null),
        children && React.createElement($Text, null, children)));
}, arePropsEqual);

import styled from 'styled-components';
import { baseRow, Game_P1, shapeColors } from '../../../../styles';
export const $TableRow = styled.div `
  display: flex;
  align-items: center;

  &:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  &:not(:first-child) {
    margin-bottom: 3px;
  }
`;
export const $TableCell = styled.div `
  ${baseRow('flex-start')}
  ${Game_P1()}
  text-align: left;
  color: ${shapeColors['black']};

  &:not(:first-child) {
    margin-left: 15px;
  }
`;

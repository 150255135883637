import { LinkButton } from '@components/atoms/buttons/_LinkButton';
import { useApplicationContext } from '@application';
import { IUpgradeButton, eFideIdStatus } from '@types';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { BaseButton } from 'shared/atoms/buttons/_baseButton';
import styles from './_upgrade_button.module.scss';
import { ePopupPaths } from '@constants';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { useUserStatus, UserStatus } from '@utils/hooks/_useUserSatatus';
import { ePaywallPopupType } from '../popups/PaywallPopup/_types';

/** Кнопка для получения fide аккаунта
 * @param {Function} GTMDataLayerEvent - передача GTM аналитики
 * @param {string} className - внешний класс
 * @param {string} buttonClassName - внешний класс кнопки
 * @param {boolean} showUpgradeWhenOnCheck - показываем ли задизейбленный текст Upgrade Now, когда юзер ожидает верификации аккаунта
 */
export const UpgradeButton: React.FC<IUpgradeButton> = ({
  GTMDataLayerEvent,
  className = '',
  buttonClassName = '',
  showUpgradeWhenOnCheck = false,
}) => {
  const router = useRouter();

  const userStatus = useUserStatus();
  const fideIdStatus = useFideIdStatus();

  const { localization: l } = useApplicationContext();

  const onButtonClick = useCallback(() => {
    GTMDataLayerEvent?.({});
  }, [GTMDataLayerEvent]);

  if (
    !showUpgradeWhenOnCheck &&
    [eFideIdStatus.ON_CHECK, eFideIdStatus.REJECTED].includes(fideIdStatus)
  ) {
    return (
      <BaseButton
        className={`${styles.button} ${className}`}
        disabled={true}
        buttonType="primary"
        onClick={onButtonClick}
      >
        {l?.common?.find_opponent}
      </BaseButton>
    );
  }

  return !showUpgradeWhenOnCheck && userStatus === UserStatus.SUBSCRIBED ? (
    <LinkButton
      className={className}
      theme="green"
      href={{
        pathname: router.asPath.split('?')[0],
        query: `popup=${ePopupPaths.FIDE}`,
      }}
      buttonType="primary"
      buttonClassName={`${styles.button} ${buttonClassName}`}
      onClick={onButtonClick}
      shallow={true}
    >
      {l?.common?.link_fide}
    </LinkButton>
  ) : (
    <LinkButton
      className={className}
      buttonClassName={`${styles.button} ${buttonClassName}`}
      theme="green"
      buttonType="primary"
      disabled={showUpgradeWhenOnCheck}
      href={{
        pathname: router.asPath.split('?')[0],
        query: {
          popup: ePopupPaths.PAYWALL,
          type: ePaywallPopupType.GAMES,
        },
      }}
      onClick={onButtonClick}
      shallow={true}
    >
      {l?.common?.upgrade_now}
    </LinkButton>
  );
};

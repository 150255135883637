import {
  IChessboardSettingsAction,
  IChessboardSettingsContextState,
  eChessboardSettingsContextActionType,
} from './_types';

export const boardSettingsPopupReducer = (
  state: IChessboardSettingsContextState,
  { key, payload }: IChessboardSettingsAction
): IChessboardSettingsContextState => {
  switch (key) {
    // case eChessboardSettingsContextActionType.UPDATE_LOCALIZATION:
    //   return {
    //     ...state,
    //     localization: payload as IChessboardSettingsLocalization,
    //   };
    case eChessboardSettingsContextActionType.SET_OPENED:
      return {
        ...state,
        opened: payload,
      };
    case eChessboardSettingsContextActionType.UPDATE_SETTINGS:
      return {
        ...state,
        currentSettings: payload,
      };
    case eChessboardSettingsContextActionType.UPDATE_SETTING:
      return {
        ...state,
        currentSettings: {
          ...state.currentSettings,
          [payload.key]: payload.value,
        },
      };
  }
};

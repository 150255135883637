import React, { useMemo } from 'react';

import { analyzers } from '@constants';
import styles from './_heading.module.scss';
import { useApplicationContext } from '@application';
import { LazyImage } from 'shared/molecules/images/_lazyImage';
import { RoundedBorderWithStar } from '@components/atoms/icons/_RoundedBorderWithStar';

interface IHeading {
  fideId: number;
}

export const Heading: React.FC<IHeading> = ({ fideId }) => {
  const { localization: l } = useApplicationContext();

  const analyzer = useMemo(() => {
    return analyzers.find(({ fide_id }) => fideId === fide_id);
  }, [fideId]);

  if (!analyzer) return null;

  return (
    <div className={styles.heading}>
      <div className={styles.avatar_container}>
        <div className={styles.image_container}>
          <LazyImage src={analyzer.photo} className={styles.avatar_image} />
        </div>

        <RoundedBorderWithStar
          width={126}
          height={121}
          className={styles.avatar_border}
        />
      </div>

      <p className={styles.title_text}>
        {`${analyzer.full_name} ${l.popups.analysis.will_comment}`}
      </p>
    </div>
  );
};

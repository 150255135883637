import { ISliderItemProps } from '@chessarena/components/lib/game/GameBoardSettingsPopupSlider/types';
import { useMemo } from 'react';
import { IChessboardSettingsContextState } from '../_types';
import { useDataHasChanged } from './_useDataHasChanged';

type ISettingsButtonProps = {
  type: 'yellow' | 'inverse';
  text: string;
};

export const useSaveButtonData = (
  state: IChessboardSettingsContextState,
  boardThemesSliderItems: ISliderItemProps[]
) => {
  const {
    currentSettings: { boardTheme },
  } = state;

  const dataHasChanged = useDataHasChanged(state);

  const buttonProps = useMemo<ISettingsButtonProps>(() => {
    const currentSliderItem = boardThemesSliderItems.find(
      (item) => item.value === boardTheme
    );

    if (currentSliderItem?.isPremium) {
      return {
        type: 'yellow',
        text: 'Get Pro account to unlock all features',
      };
    }

    if (dataHasChanged) {
      return {
        type: 'inverse',
        text: 'Save and close settings',
      };
    }

    return {
      type: 'inverse',
      text: 'Close Settings',
    };
  }, [boardTheme, boardThemesSliderItems, dataHasChanged]);

  return buttonProps;
};

import React from 'react';
import { $Button, $Icon, $Text, $A, $LinkButton } from './_styles';
/**
 * Кнопка-иконка
 * @param {ReactNode} children - копонент иконки (пример: <IconButton><BoardSettingsIcon /></IconButton>)
 * @param {boolean} disabled  - блокирует доступ и изменение кнопки
 * @param {string} theme - определяет тему кнопки
 * @param {ReactNode} text  - текст под иконкой
 * @param {string} className - внешний класс
 * @param {object} otherProps - любые другие значения, передаваемые кнопке
 * @param {string} link - ссылка (внутренняя или внешняя)
 */
export const IconButton = ({ children, theme = 'white', text = null, className = '', iconClassName = '', textClassName = '', link, disabled, onClick = () => { }, }) => {
    const buttonContent = (React.createElement(React.Fragment, null,
        React.createElement($Icon, { className: iconClassName }, children),
        text && React.createElement($Text, { className: textClassName }, text)));
    const linkButton = (React.createElement($LinkButton, { disabled: disabled, withText: !!text, theme: theme }, buttonContent));
    if (link) {
        return (React.createElement($A, { href: link, className: className, target: "_blank" }, linkButton));
    }
    return (React.createElement($Button, { className: className, disabled: disabled, onClick: onClick, withText: !!text, theme: theme }, buttonContent));
};

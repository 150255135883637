import GameBoardAdditionalSettings from '@chessarena/components/lib/game/GameBoardAdditionalSettings';
import { GameBoardSettingsPopup } from '@chessarena/components/lib/game/GameBoardSettingsPopup';
import { FC, memo } from 'react';
import { useBoardSettingsPopupContext } from '../context';

export const AdditionalSettings: FC = memo(function AdditionalSettingsField() {
  const {
    computed: { switchItems },
  } = useBoardSettingsPopupContext();

  return (
    <GameBoardSettingsPopup.Field label={'Game'}>
      <GameBoardAdditionalSettings inputSwitchItems={switchItems} />
    </GameBoardSettingsPopup.Field>
  );
});

import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Крестик
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} theme - тема иконки (толстая/тонкая)
 * @param {string} className - внешний класс
 * @param {Function} onClick - обработчик клика
 */
export const CloseIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', theme = 'slim', onClick, className = '', }) => {
    const closeIcons = {
        slim: (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 11 12", xmlns: "http://www.w3.org/2000/svg", onClick: onClick },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.2 6l4.8 4.8-.7.7-4.8-4.8-4.8 4.8-.7-.7L4.8 6 0 1.2.7.5l4.8 4.8L10.3.5l.7.7L6.2 6z", fill: fill }))),
        fat: (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 18 19", xmlns: "http://www.w3.org/2000/svg", onClick: onClick },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.4 9.5L0 3l2.1-2 6.4 6.4L14.9.9 17 3l-6.4 6.5L17 16l-2.1 2-6.4-6.4-6.4 6.5L0 16l6.4-6.5z", fill: fill }))),
    };
    return closeIcons[theme];
};

import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Рейтинг
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 */
export const RatingIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', strokeWidth = 1.2, className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 16 17" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 1L1.5 4.75V12.25L8 16L14.5 12.25V4.75L8 1Z", stroke: fill, strokeWidth: strokeWidth, strokeMiterlimit: "10", fill: "none" })));

import { usePaywallPopupContext } from './_context';
import { ePaywallPopupStep } from './_types';
import { useApplicationContext } from '@application';

export const usePaywallPopupDescription = () => {
  const { localization } = useApplicationContext();
  const { step, type } = usePaywallPopupContext();

  switch (step) {
    case ePaywallPopupStep.INFO:
      return localization.paywall.info.descriptions[type];
    case ePaywallPopupStep.PAYMENT_SETTINGS:
      return localization.paywall.payment_settings.periods.title;
    default:
      return null;
  }
};

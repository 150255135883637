import React, { useMemo, useState } from 'react';
import { BlackAndWhiteIcon, CloseIcon } from 'shared/atoms';

import { CopyButton } from '@components/molecules/buttons/_CopyButton';
import { closePopup } from '@utils/_router';
import styles from './_widget_popup.module.scss';

const apiUrl = process.env.NEXT_PUBLIC_API_URL;

export const WidgetPopup: React.FC = () => {
  const [widgetCurrentTheme, setWidgetCurrentTheme] = useState<
    'dark' | 'light'
  >('dark');

  const boardUrl = window.location.origin + window.location.pathname;

  const gameId = useMemo(() => {
    const pathname = window.location.pathname;
    const urlBoardId = pathname.split('game/')[1];
    return urlBoardId;
  }, []);

  const widgetCode = `<div style="position: relative; width: 600px; max-width: 100%; padding-bottom: min(calc(100% + 210px), 810px);"><iframe src="https://widget.dev.worldchess.com/?theme=${widgetCurrentTheme}&cb-theme=worldchess&game-data-url=${apiUrl}online/gaming/${gameId}/&url=${boardUrl}" frameBorder="0" style="display: block; position: absolute; width: 100%; height: 100%; border-radius: 10px;"></iframe></div>`;

  return (
    <div className={styles.widget_popup_wrapper}>
      <div className={styles.widget_popup}>
        <div className={styles.widget_popup_top_wrapper}>
          <BlackAndWhiteIcon
            className={`${styles.widget_change_theme} ${
              widgetCurrentTheme === 'light' ? styles.light : ''
            }`}
            width={34}
            height={34}
            onClick={() =>
              setWidgetCurrentTheme(
                widgetCurrentTheme === 'dark' ? 'light' : 'dark'
              )
            }
          />
          <CopyButton
            mode="button"
            theme="white_inverted"
            buttonType="secondary"
            copyText={widgetCode}
            className={styles.widget_copy}
          >
            <span className={styles.widget_copy_text}>Copy Code</span>
          </CopyButton>
          <CloseIcon
            className={styles.widget_close}
            width={20}
            height={20}
            onClick={() => closePopup()}
          />
        </div>
        <div
          style={{
            position: 'relative',
            width: '600px',
            maxWidth: '100%',
            paddingBottom: 'min(calc(100% + 210px), 810px)',
          }}
        >
          <iframe
            src={`https://widget.dev.worldchess.com/?theme=${widgetCurrentTheme}&cb-theme=worldchess&game-data-url=${apiUrl}online/gaming/${gameId}/&url=${boardUrl}`}
            frameBorder="0"
            style={{
              display: 'block',
              position: 'absolute',
              width: '100%',
              height: '100%',
              borderRadius: '10px',
            }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

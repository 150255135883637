import { usePopupsContext } from '@store/context/popupsContext/_context';
import React from 'react';
import { BasePopup } from 'shared/atoms';
import styles from './_registration_popup.module.scss';
import { ePopupPaths } from '@constants';
import { useApplicationContext } from '@application';
import { openPopup } from '@utils/_router';
import { useRouter } from 'next/router';
import { RegistrationForm } from './fragments/_RegistrationForm';

export const RegistrationPopup: React.FC = () => {
  const router = useRouter();
  const from = router.query.from as string;

  const { localization: l } = useApplicationContext();

  const {
    actions: { closeRegistrationPopup },
  } = usePopupsContext();

  const nextStep = (): void => {
    const params = new URLSearchParams();

    params.append('popup', 'confirm');
    if (from) {
      params.append('from', from);
    }

    openPopup(`?${params.toString()}`);
  };

  const handleClickToShowSignInForm = () => {
    openPopup(`?popup=${ePopupPaths.SIGN_IN}`);
  };

  return (
    <BasePopup
      setShowPopup={closeRegistrationPopup}
      className={styles.popup}
      contentClassName={styles.popup_content}
      color="grey"
    >
      <div className={styles.container}>
        <div className={styles.heading}>
          <h3 className={styles.title}>{l.registration.new_account}</h3>
          <div className={styles.have_account}>
            <p className={styles.have_account_text}>
              {l.registration.have_an_account}
            </p>
            <p className={styles.sign_in} onClick={handleClickToShowSignInForm}>
              {l.registration.sign_in}
            </p>
          </div>
        </div>

        <div className={styles.subheading}>
          <p className={styles.subheading_text}>
            {l.registration.enter_details}
          </p>
        </div>

        <RegistrationForm nextStep={nextStep} />
      </div>
    </BasePopup>
  );
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TimeControlButton } from '../../../atoms';
import { $Wrapper, $Row, $ControlButton } from './_styles';
/**
 * Компонент выбора типа игры в шахматы
 * @param {IGroupedTimeControl[]} timeControls - сгруппированные таймконтролы по типу доски
 * @param {IAdvancedTimeControl} selectedTimeControl - выбранный таймконтрол
 * @param {function} setTimeControl - обработчик выбора таймконтрола
 * @param {ITimeControlSelectorLocalization} translation - локализация
 * @param {string} className - стили компонента
 * @param {function} GTMDataLayerEvent - передает событие для GTM
 */
export const TimeControlSelector = (_a) => {
    var { selectedTimeControl, timeControls, setTimeControl, translation, disabled = false, className = '', GTMDataLayerEvent } = _a, otherProps = __rest(_a, ["selectedTimeControl", "timeControls", "setTimeControl", "translation", "disabled", "className", "GTMDataLayerEvent"]);
    const localization = Object.assign({ min: 'min', board_types: {
            rapid: 'Rapid',
            blitz: 'Blitz',
            bullet: 'Bullet',
        } }, translation);
    if (!timeControls)
        return null;
    return (React.createElement($Wrapper, Object.assign({ className: className }, otherProps), timeControls.map((timeControl) => (React.createElement($Row, { key: timeControl.board_type }, timeControl.controls.map((control, index) => (React.createElement($ControlButton, { as: TimeControlButton, key: control.id, time: control.start_time_number, addition: control.increment_number > 0
            ? `+${control.increment_number}`
            : `${localization.min}`, title: index === 0
            ? `${localization.board_types[timeControl.name]}`
            : '', onClick: () => {
            setTimeControl(control);
            GTMDataLayerEvent === null || GTMDataLayerEvent === void 0 ? void 0 : GTMDataLayerEvent(Object.assign(Object.assign({}, control), { name: timeControl.name }));
        }, active: (selectedTimeControl === null || selectedTimeControl === void 0 ? void 0 : selectedTimeControl.id) === control.id, disabled: disabled }))))))));
};

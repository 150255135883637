import React, { useCallback, useEffect } from 'react';

import { useApplicationContext } from '@application';
import { IPopup } from '@types';
import styles from './_form.module.scss';
import { UseFormState } from 'shared/hooks/_useForm.hook';
import { getPrice, formatNumber } from 'shared/helpers/_common';
import { getMsFromNow, msToTime } from 'shared/helpers/_game';
import { useCountdown } from '@utils/hooks/_useCountdown';
import { PaymentForm, LoadingBaseButton } from 'shared/molecules';
import { ICardInputs } from 'shared/types';
import { useRefreshServerData } from '@utils/hooks/_useRefreshServerData.hook';
import { PaymentInfo } from './_PaymentInfo';
import { tournamentActions } from '@store/storeshed';
import { useTournamentStore } from '@store/storeshed';
import { paymentActions, userDataActions } from '@store/storeshed';
import { usePaymentStore } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';

const JOIN_TIME_LEFT = 3600000; // 60 минут - граница, с которой показываем время до конца регистрации

interface IForm extends IPopup {
  alreadyPaid: boolean;
  paypalInited: boolean;
  timeIsOver: boolean;
  setTimeIsOver: (timeIsOver: boolean) => void;
}

export const Form: React.FC<IForm> = ({
  alreadyPaid,
  paypalInited,
  timeIsOver,
  setTimeIsOver,
  closePopup,
}) => {
  const { milliseconds, setMilliseconds, startTimer, paused } = useCountdown({
    timeInMilliseconds: 0,
    autostart: false,
  });

  const userData = useUserDataStore('data');
  const paymentRequest = usePaymentStore('buy_title_request');
  const paymentError = usePaymentStore('payment_error');
  const promocodeRequest = usePaymentStore('promocode_request');
  const promocodeError = usePaymentStore('promocode_error');
  const promocode = usePaymentStore('promocode');

  const refreshData = useRefreshServerData();

  const { localization: l } = useApplicationContext();

  const { getTournament } = tournamentActions;
  const tournamentData = useTournamentStore('tournament_data');

  const showMillisec = (ms: number): boolean => {
    return ms < JOIN_TIME_LEFT;
  };

  const promocodeHandler = useCallback(
    (coupon: string): void => {
      if (promocodeRequest) return;

      paymentActions.checkCoupon(coupon);
    },
    [promocodeRequest]
  );

  const successCallback = async (): Promise<void> => {
    userDataActions.getMeData();
    if (tournamentData?.id) {
      await getTournament(tournamentData?.id);
    }
    closePopup();
    refreshData();
  };

  const handleSubmit = (form: UseFormState<ICardInputs>): void => {
    if (
      paymentRequest ||
      !process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY ||
      !tournamentData?.product_sku
    )
      return;

    paymentActions.buyProduct({
      stripe_key: process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY,
      card: form,
      sku: tournamentData.product_sku.stripe_id,
      coupon: promocode?.id,
      successCallback,
    });
  };

  const resetPaymentError = useCallback((): void => {
    paymentActions.setPaymentError(null);
  }, []);

  useEffect(() => {
    return () => {
      paymentActions.setPromocode(null);
    };
  }, []);

  useEffect(() => {
    if (tournamentData) {
      const msToEnd = getMsFromNow(tournamentData.signup_finish);
      setMilliseconds(msToEnd > 0 ? msToEnd : 0);
    }
  }, [tournamentData?.signup_finish]);

  useEffect(() => {
    if (milliseconds > 0 && tournamentData?.signup_opened && paused) {
      startTimer();
      setTimeIsOver(false);
    }

    if (milliseconds <= 0) {
      setTimeIsOver(true);
    }
  }, [milliseconds]);

  if (!tournamentData?.product_sku) return null;

  const canPay =
    !timeIsOver && tournamentData.signup_opened && !!userData && !alreadyPaid;

  return (
    <PaymentForm
      className={styles.popup_payment}
      paymentInfo={<PaymentInfo />}
      onSubmit={handleSubmit}
      promocodeHandler={promocodeHandler}
      paymentRequest={paymentRequest}
      promocodeRequest={promocodeRequest}
      promocodeApplied={!!promocode?.percentOff}
      promocodeError={promocodeError}
      error={paymentError}
      resetPaymentError={resetPaymentError}
      showPaypal={canPay}
      paypalInited={paypalInited}
      button={
        <LoadingBaseButton
          theme="white"
          className={styles.payment_button}
          loading={paymentRequest}
          disabled={!canPay}
        >
          {showMillisec(milliseconds) && (
            <span className={styles.time}>{msToTime(milliseconds)}</span>
          )}
          <span className={styles.button_text}>{l.popups.common.checkout}</span>
          <span className={styles.price}>
            {`€${getPrice(
              formatNumber(tournamentData.product_sku.price / 100, 2),
              promocode?.percentOff
            )}`}
          </span>
        </LoadingBaseButton>
      }
    />
  );
};

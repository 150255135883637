import { useMemo } from 'react';

import { UserStatus, useUserStatus } from '@utils/hooks/_useUserSatatus';

export const useUserPaidSubscription = () => {
  const userStatus = useUserStatus();

  return useMemo(() => {
    return userStatus === UserStatus.SUBSCRIBED;
  }, [userStatus]);
};

import { Dispatch } from 'react';
import { useBoardSettingsActions } from './_actions';
import { useComputed } from './hooks/useComputed.hook';
import { IChessboardSettings } from '@store/storeshed';

export const enum eChessboardSettingsContextActionType {
  UPDATE_SETTINGS,
  UPDATE_SETTING,
  SET_OPENED,
  SET_HAS_UNSAVED_CHANGES,
}

interface IChessboardSettingsActionMessage {
  key: eChessboardSettingsContextActionType;
  payload?: unknown;
}

interface ISetOpened extends IChessboardSettingsActionMessage {
  key: eChessboardSettingsContextActionType.SET_OPENED;
  payload: boolean;
}

interface IUpdateSettings extends IChessboardSettingsActionMessage {
  key: eChessboardSettingsContextActionType.UPDATE_SETTINGS;
  payload: IChessboardSettings;
}

type TChessboardSettingsKeys = keyof IChessboardSettings;
interface IUpdateSetting extends IChessboardSettingsActionMessage {
  key: eChessboardSettingsContextActionType.UPDATE_SETTING;
  payload: {
    key: TChessboardSettingsKeys;
    value: IChessboardSettings[TChessboardSettingsKeys];
  };
}

export type IChessboardSettingsAction =
  | ISetOpened
  | IUpdateSettings
  | IUpdateSetting;

export type IChessboardSettingsContextDispatch =
  Dispatch<IChessboardSettingsAction>;

export type TChessboardSettingsActions = ReturnType<
  typeof useBoardSettingsActions
>;

export type TChessboardSettingsComputed = ReturnType<typeof useComputed>;

export interface IChessboardSettingsContextProps {
  state: IChessboardSettingsContextState;
  actions: TChessboardSettingsActions;
  dispatch: IChessboardSettingsContextDispatch;
  computed: TChessboardSettingsComputed;
}

export interface IChessboardSettingsLocalization {
  popup_title?: string;
  board_pieces?: string;
  sounds?: string;
  premove?: string;
  last_move?: string;
  legal_moves?: string;
  done?: string;
  cancel?: string;
  auto_queen?: string;
  show_dots?: string;
  hide_dots?: string;
  textbook?: string;
  standard?: string;
  arrow?: string;
  highlight?: string;
  arma?: string;
  standart?: string;
  worldchess?: string;
}

export interface IChessboardSettingsContextState {
  currentSettings: IChessboardSettings;
  playerCurrentSettings: IChessboardSettings;
  translation: Required<IChessboardSettingsLocalization>;
  opened: boolean;
}

import React, { memo, useCallback, useEffect } from 'react';

import { ExternalClosingPopup } from 'shared/atoms';
import { SignInForm } from 'shared/molecules';

import {
  AppleAuthButton,
  GoogleAuthButton,
  FacebookAuthButton,
} from '@chessarena/components/lib/auth/socialAuthButtons';
import { useApplicationContext } from '@application';
import { useLoginData } from './_useLoginData.hook';
import styles from './_styles.module.scss';
import { userDataActions, loginActions } from '@store/storeshed';
import { useAuthStore, authStore } from '@store/storeshed';
import { useLoginStore, loginStore } from '@store/storeshed';
import { closePopup, openPopup } from '@utils/_router';
import { useSocialAuthActions } from '@utils/hooks/_useSocialAuthActions.hook';
import { ePopupPaths } from '@constants';

interface IActivationCode {
  code: string;
  email?: string;
  nextStep: () => void;
}

export const SignInPopup: React.FC = memo(function SignInPopup() {
  const { localization: l } = useApplicationContext();
  const userUid = useAuthStore('uid');
  const activationCodeError = useLoginStore('activation_code_error');

  const { login, inRequest, error, showActivationCodePopup, token } =
    useLoginData();

  const { authWithApple, authWithFacebook, authWithGoogle } =
    useSocialAuthActions();

  useEffect(() => {
    if (token === null) return;
    authStore.dispatch({
      token,
      logged: true,
    });
    userDataActions.getMe();
    closePopup();
  }, [token]);

  const redirectToActivationForm = useCallback((email: string): void => {
    openPopup(`?popup=${ePopupPaths.CONFIRM}&email=${email}`);
  }, []);

  const showRegisterForm = useCallback((): void => {
    openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
  }, []);

  const showPasswordForm = useCallback((): void => {
    openPopup(`?popup=${ePopupPaths.PASSWORD_RESET}`);
  }, []);

  const resendActivationCode = useCallback(
    (props: { email: string; showForm: () => void }) => {
      loginActions.resendActivationCode(props.email, props.showForm);
    },
    []
  );

  const activationCode = useCallback(
    ({ code, email, nextStep }: IActivationCode) => {
      loginActions.activationCode({
        code,
        nextStep,
        email,
        updateAuthToken: false,
      });
    },
    []
  );

  const setActivationCodeError = useCallback((error: string | null) => {
    loginActions.setActivationCodeError(error);
  }, []);

  useEffect(() => {
    return () => {
      loginStore.dispatch({
        login_error: null,
        show_activation_button: false,
      });
    };
  }, []);

  const resetError = useCallback(() => {
    loginActions.setLoginError(null);
  }, []);

  if (!userUid) return null;
  return (
    <ExternalClosingPopup
      showPopup={true}
      setShowPopup={closePopup}
      clickAreaStyles={{ zIndex: 1000 }}
      component={
        <SignInForm
          style={{ zIndex: 1001 }}
          login={login}
          loginError={error}
          inRequest={inRequest}
          closePopup={closePopup}
          setShowRegisterForm={showRegisterForm}
          setShowPasswordForm={showPasswordForm}
          baseUrl={process.env.NEXT_PUBLIC_GAME_URL}
          resendActivationCode={resendActivationCode}
          activationCode={activationCode}
          activationCodeError={activationCodeError}
          setActivationCodeError={setActivationCodeError}
          showActivationButton={showActivationCodePopup}
          redirectToActivationForm={redirectToActivationForm}
          l={l}
          className={styles.sign_in}
          resetError={resetError}
        >
          <div className={styles.social_auth_buttons_wrapper}>
            <AppleAuthButton onClick={authWithApple} />
            <GoogleAuthButton onClick={authWithGoogle} />
            <FacebookAuthButton onClick={authWithFacebook} />
          </div>
        </SignInForm>
      }
    />
  );
});

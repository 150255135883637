var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useClassNames } from '../../../../hooks/_useClassNames.hook';
import { $Input, $CheckboxWrapper, $Checkbox, $Content } from './_styles';
/**
 * Компонент ввода флага
 * @param {boolean} disabled - блокирует доступ и изменение значения флага
 * @param {boolean} checked - определяет, включен ли флаг (значение флага)
 * @param {string} size - размер чекбокса
 * @param {string} className - внешний класс
 * @param {function} changeHandler - обработчик события изменения значения флага
 * @param {object} otherProps - любые другие значения, передаваемые полю ввода
 */
export const SwitchCheckbox = React.memo((_a) => {
    var { disabled = false, checked = false, changeHandler, className = '', children, size = 'normal' } = _a, otherProps = __rest(_a, ["disabled", "checked", "changeHandler", "className", "children", "size"]);
    const labelClasses = useClassNames({
        [className]: true,
        ['checkbox_wrapper']: true,
        ['disabled']: disabled,
    });
    const checkboxClasses = useClassNames({
        ['checkbox']: true,
        [size]: !!size,
    });
    return (React.createElement($CheckboxWrapper, Object.assign({ className: labelClasses }, otherProps),
        React.createElement($Input, { type: "checkbox", checked: checked, disabled: disabled, onChange: changeHandler }),
        React.createElement($Checkbox, { className: checkboxClasses }),
        children && React.createElement($Content, null, children)));
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacebookAuthButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const _internal_1 = require("./_internal");
// TODO: Доработать, когда будет готов итоговый дизайн авторизаии
/**
 * Google social authorization button
 * @param {() => void} onClick
 * @param {boolean} showTitle - is text "Facebook" shown
 */
const FacebookAuthButton = (props) => {
    return ((0, jsx_runtime_1.jsx)(_internal_1.BaseAuthButton, Object.assign({ title: "Facebook" }, props, { children: (0, jsx_runtime_1.jsxs)("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsx)("path", { d: "M22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 16.9912 5.65684 21.1283 10.4375 21.8785V14.8906H7.89844V12H10.4375V9.79688C10.4375 7.29063 11.9305 5.90625 14.2146 5.90625C15.3084 5.90625 16.4531 6.10156 16.4531 6.10156V8.5625H15.1922C13.95 8.5625 13.5625 9.3334 13.5625 10.125V12H16.3359L15.8926 14.8906H13.5625V21.8785C18.3432 21.1283 22 16.9912 22 12Z", fill: "#1877F2" }), (0, jsx_runtime_1.jsx)("path", { d: "M15.8926 14.8906L16.3359 12H13.5625V10.125C13.5625 9.33418 13.95 8.5625 15.1922 8.5625H16.4531V6.10156C16.4531 6.10156 15.3088 5.90625 14.2146 5.90625C11.9305 5.90625 10.4375 7.29063 10.4375 9.79688V12H7.89844V14.8906H10.4375V21.8785C11.4729 22.0405 12.5271 22.0405 13.5625 21.8785V14.8906H15.8926Z", fill: "white" })] }) })));
};
exports.FacebookAuthButton = FacebookAuthButton;

import styled from 'styled-components';
import { textColors, transparentColor, baseText, buttonColors } from '../../../../styles';
export const $Radio = styled.span `
  display: inline-block;
  width: 26px;
  min-width: 26px;
  height: 26px;
  position: relative;
  border-radius: 100%;
  border: 1px solid ${transparentColor(buttonColors['grey_light'], 0.7)};
  transition: 0.1s;
  cursor: pointer;

  // circle
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background-color: ${buttonColors['black']};
    opacity: 0;
    transition: opacity 0.1s;
    will-change: opacity;
  }
`;
export const $Input = styled.input `
  visibility: hidden;
  width: 0;
  height: 0;

  &:checked {
    + ${$Radio} {
      border: 1px solid ${buttonColors['white']};

      &:before {
        opacity: 1;
      }
    }
  }

  &:disabled {
    + ${$Radio} {
      cursor: default;
      border-color: ${transparentColor(buttonColors['white'], 0.5)};

      &:before {
        background-color: ${buttonColors['grey_deep']};
      }
    }

    // circle style if input disabled and checked
    &:checked {
      + ${$Radio} {
        border-color: ${transparentColor(buttonColors['white'], 0.5)};

        &:before {
          background-color: ${buttonColors['grey_deep']};
        }
      }
    }
  }
`;
export const $Text = styled.p `
  margin-left: 10px;
  ${baseText({ color: textColors['white'], fontSize: '22px' })}
`;
export const $RadioWrapper = styled.label `
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;

  &:not(.disabled) {
    &:hover {
      ${$Radio} {
        border: 1px solid ${buttonColors['white']};
      }
    }
  }

  &.disabled {
    cursor: default;

    ${$Text} {
      color: ${transparentColor(buttonColors['white'], 0.5)};
      opacity: 0.5;
    }
  }
`;

import { BoardPreview } from '@components/molecules/BoardPreview';
import { eLastMoveTheme } from 'chessgun/ui';
import { useMemo } from 'react';
import { IChessboardSettingsContextState } from '../_types';
import { $CroppedBoardWrapper } from './_common.styles';
/**
 * Хук собирающий все данные для отображения настройки: Last moves
 */
export const useLastMovesItems = ({
  currentSettings: { boardTheme },
}: IChessboardSettingsContextState) => {
  const lastMoves = useMemo(
    () => [
      {
        movesContent: (
          <$CroppedBoardWrapper>
            <BoardPreview
              size={280}
              theme={boardTheme}
              fenPos="1bq/ppp/1n/8/8/8/8/8"
            />
          </$CroppedBoardWrapper>
        ),
        radioButtonValue: 'none',
        radioButtonTitle: 'None',
      },
      {
        movesContent: (
          <$CroppedBoardWrapper>
            <BoardPreview
              size={280}
              theme={boardTheme}
              lastMove={{ type: eLastMoveTheme.HIGHLIGHT, uci: 'a8b6' }}
              fenPos="1bq/ppp/1n/8/8/8/8/8"
            />
          </$CroppedBoardWrapper>
        ),
        radioButtonValue: 'highlight',
        radioButtonTitle: 'Highlight',
      },
      {
        movesContent: (
          <$CroppedBoardWrapper>
            <BoardPreview
              size={280}
              theme={boardTheme}
              lastMove={{ type: eLastMoveTheme.ARROW, uci: 'a8b6' }}
              fenPos="1bq/ppp/1n/8/8/8/8/8"
            />
          </$CroppedBoardWrapper>
        ),
        radioButtonValue: 'arrow',
        radioButtonTitle: 'Arrow',
      },
    ],
    [boardTheme]
  );

  return lastMoves;
};

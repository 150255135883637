import { useMemo } from 'react';
import { ISliderItemProps } from '@chessarena/components/lib/game/GameBoardSettingsPopupSlider/types';
import { eBoardThemes } from 'shared/types';
import { useUserPaidSubscription } from './_useUserPaidSubscription.hook';
import { useSettingsBoardThemes } from './_useSettingsBoardThemes.hook';

/**
 * This hook adapts board themes for board settings slider
 */
export const useBoardThemesSliderThemeItems =
  (): ISliderItemProps<eBoardThemes>[] => {
    const hasSubscription = useUserPaidSubscription();
    const boardsThemes = useSettingsBoardThemes();

    const boardThemesSliderItems = useMemo(
      () =>
        boardsThemes.map((board) => ({
          value: board.value,
          name: board.name,
          content: board.content,
          isPremium: board.paid && !hasSubscription, // checking if board is paid and user have the subscribtion
        })),
      [hasSubscription, boardsThemes]
    );

    return boardThemesSliderItems;
  };

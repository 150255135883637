import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Лого Bureau
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 */
export const ChallengeIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 13 14" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.0001 13.0526L10.5732 10.3342L11.7393 9.3358L10.7919 8.22804L9.77886 9.44511L7.30826 6.66843L12.3879 0.473713L11.834 0L6.59405 5.87405L1.35405 0L0.800172 0.473713L5.87984 6.66843L3.40924 9.44511L2.39622 8.22804L1.44879 9.3358L2.61486 10.3342L0.187988 13.0526L1.29575 14L3.60601 11.1869L4.77208 12.1781L5.7195 11.0703L4.36395 10.2541L6.59405 7.54297L8.82415 10.2541L7.4686 11.0703L8.41602 12.1781L9.58209 11.1869L11.8924 14L13.0001 13.0526Z", fill: fill })));

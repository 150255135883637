import React, { useState } from 'react';

import { ICopyButton } from '@types';
import { useApplicationContext } from '@application';
import styles from './_copy_button.module.scss';
import { CheckIcon } from 'shared/atoms/icons';
import { BaseButton } from 'shared/atoms/buttons';

/** Кнопка для копирования текста
 * @param {'text' | 'button'} mode - вид кнопки
 * @param {string} copyText - текст для копирования
 * @param {string} className - внешний класс
 */
export const CopyButton: React.FC<ICopyButton> = ({
  mode = 'text',
  copyText,
  className = '',
  children,
  ...otherProps
}) => {
  const { localization: l } = useApplicationContext();
  const [copied, setCopied] = useState(false);

  const copyLink = (): void => {
    navigator.clipboard.writeText(copyText);
  };

  const clickHandler = (): void => {
    copyLink();
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const buttonContent = copied ? (
    <div className={styles.copied}>
      <CheckIcon className={styles.copied_icon} fill="#A2C91F" />
      <span className={styles.copied_text}>{l.common.copied}</span>
    </div>
  ) : (
    children
  );

  if (mode === 'button') {
    return (
      <BaseButton
        className={`${className} ${styles.copied_button}`}
        onClick={clickHandler}
        {...otherProps}
      >
        {buttonContent}
      </BaseButton>
    );
  }

  return (
    <div className={className} onClick={clickHandler}>
      {buttonContent}
    </div>
  );
};

import React, { useCallback, memo, useMemo } from 'react';
import { useRouter } from 'next/router';

import { ExternalClosingPopup } from 'shared/atoms/popups/_externalClosingPopup';
import { useApplicationContext } from '@application';
import { ePopupPaths, routesConstants } from '@constants';
import styles from './_styles.module.scss';
import {
  AppleAuthButton,
  GoogleAuthButton,
  FacebookAuthButton,
} from '@chessarena/components/lib/auth/socialAuthButtons';
import { SignInInviteForm } from './fragments/_SignInInviteForm';
import { loginActions } from '@store/storeshed';
import { useAuthStore } from '@store/storeshed';
import { useLoginStore } from '@store/storeshed';
import { openPopup, closePopup } from '@utils/_router';
import { useSocialAuthActions } from '@utils/hooks/_useSocialAuthActions.hook';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';

interface IActivationCode {
  code: string;
  email?: string;
  nextStep: () => void;
}

export const SignInInvitePopup: React.FC = memo(function SignInInvitePopup() {
  const { localization: l } = useApplicationContext();
  const loginError = useLoginStore('login_error');
  const loginRequest = useLoginStore('login_request');
  const activationCodeError = useLoginStore('activation_code_error');
  const showActivationButton = useLoginStore('show_activation_button');

  const {
    state: { inviteFriendName },
  } = useLobbyContext();

  const { authWithApple, authWithFacebook, authWithGoogle } =
    useSocialAuthActions();

  const userUid = useAuthStore('uid');
  const logged = useAuthStore('logged');

  const router = useRouter();

  const redirectToActivationForm = useCallback((email: string): void => {
    loginActions.setShowActivationButton(false);
    openPopup(`?popup=${ePopupPaths.CONFIRM}&email=${email}`);
  }, []);

  const showRegisterForm = useCallback((): void => {
    openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
  }, []);

  const showPasswordForm = useCallback((): void => {
    openPopup(`?popup=${ePopupPaths.PASSWORD_RESET}`);
  }, []);

  const resendActivationCode = useCallback(
    (props: { email: string; showForm: () => void }) => {
      loginActions.resendActivationCode(props.email, props.showForm);
    },
    []
  );

  const activationCode = useCallback(
    ({ code, email, nextStep }: IActivationCode) => {
      loginActions.activationCode({
        code,
        nextStep,
        email,
        updateAuthToken: false,
      });
    },
    []
  );

  const setActivationCodeError = useCallback((error: string | null) => {
    loginActions.setActivationCodeError(error);
  }, []);

  const login = useCallback(
    async (email: string, password: string) => {
      try {
        await loginActions.login({ email, password, callback: closePopup });
        // dispatch(
        //   accountActions.accountLogin({
        //     email,
        //     password,
        //     callback: closePopup,
        //   })
        // );
      } catch (error) {
        console.log(error);
      }
    },
    [closePopup]
  );

  const closeSignInInvitePopupAndRedirectHome = useCallback(
    (redirectToHome: boolean) => {
      closePopup();

      if (redirectToHome) router.push(routesConstants.HOME);
    },
    [closePopup, router]
  );

  const clickAreaStyles = useMemo(() => ({ zIndex: 1000 }), []);

  if (!userUid || logged) return null;

  return (
    <ExternalClosingPopup
      showPopup={true}
      setShowPopup={closeSignInInvitePopupAndRedirectHome}
      clickAreaStyles={clickAreaStyles}
      component={
        <SignInInviteForm
          userUid={userUid}
          inviteFriendName={inviteFriendName}
          closePopup={closeSignInInvitePopupAndRedirectHome}
          login={login}
          loginError={loginError || null}
          inRequest={loginRequest || false}
          setShowRegisterForm={showRegisterForm}
          setShowPasswordForm={showPasswordForm}
          baseUrl={process.env.NEXT_PUBLIC_GAME_URL}
          resendActivationCode={resendActivationCode}
          activationCode={activationCode}
          activationCodeError={activationCodeError}
          setActivationCodeError={setActivationCodeError}
          showActivationButton={showActivationButton}
          redirectToActivationForm={redirectToActivationForm}
          l={l}
          className={styles.sign_in}
        >
          <div className={styles.social_auth_buttons_wrapper}>
            <AppleAuthButton onClick={authWithApple} />
            <GoogleAuthButton onClick={authWithGoogle} />
            <FacebookAuthButton onClick={authWithFacebook} />
          </div>
        </SignInInviteForm>
      }
    />
  );
});

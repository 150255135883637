import React, { useState, useEffect, useMemo } from 'react';
import {
  BaseInput,
  ValidatorInputDate,
  TabSelector,
  ImageUpload,
} from 'shared/atoms';
import { LoadingBaseButton } from 'shared/molecules';
import { syncedDate } from 'shared/helpers/_common';
import { useMobile } from 'shared/hooks/_useMobile.hook';
import { FormCountries } from './_FormCountries';
import styles from './_fide_form.module.scss';
import { fideSubscriptionActions, paymentActions } from '@store/storeshed';
import { useFideSubscriptionStore } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';
import { useApplicationContext } from '@application';
import { usePopupsContext } from '@store/context/popupsContext/_context';

enum eErrors {
  TRY_UPLOADING_ANOTHER_PHOTO = 'Please try uploading another photo',
  INCORRECT_DATA = 'An error has occurred. Check the correctness of the entered data, or try later',
}

interface IFideForm {
  nextStep: () => void;
}

export const FideForm: React.FC<IFideForm> = ({ nextStep }) => {
  const isMobile = useMobile();
  const { localization: l } = useApplicationContext();
  const userData = useUserDataStore('data');
  const fideError = useFideSubscriptionStore('fide_error');
  const findFideRequest = useFideSubscriptionStore('find_fide_request');

  const {
    state: {
      fide: { photo },
    },
    computed: {
      fide: {
        formData: { inputState, isValid, updateValue, onChange },
      },
    },
    actions: { setPhoto },
  } = usePopupsContext();

  const genders = useMemo(() => {
    return [
      { id: 'male', value: 'male', text: l.registration.male },
      { id: 'female', value: 'female', text: l.registration.female },
    ];
  }, [l]);

  const imagePlaceholder = useMemo(
    () =>
      isMobile
        ? l.registration.upload_your_arena_avatar
        : l.registration.drag_your_arena_avatar_here,
    [
      isMobile,
      l.registration.drag_your_arena_avatar_here,
      l.registration.upload_your_arena_avatar,
    ]
  );

  const [error, setError] = useState<string | null>(null);
  const [showErrors, setShowErrors] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!photo) {
      setPhoto('');
      setError('Please try uploading another photo');
    }

    if (!isValid || !photo) {
      setShowErrors(true);
      return;
    }

    const fullName = `${inputState.first_name.value} ${inputState.last_name.value}`;

    fideSubscriptionActions.findFidePlayers(
      {
        full_name: fullName,
        birth_year: inputState.date_of_birth.value.substring(0, 4),
      },
      nextStep
    );
  };

  const changePhoto = (img: string): void => {
    if (img.length) {
      fideSubscriptionActions.setFideError(null);
    }

    setPhoto(img);

    if (error === eErrors.TRY_UPLOADING_ANOTHER_PHOTO) {
      setError(null);
    }
  };

  useEffect(() => {
    return () => {
      paymentActions.setPromocode(null);
    };
  }, []);

  useEffect(() => {
    if (fideError) {
      if (fideError.fide_id) {
        setError(null);
      } else {
        if ('photo' in fideError) {
          setPhoto('');
          setError(eErrors.TRY_UPLOADING_ANOTHER_PHOTO);
        } else {
          setError(eErrors.INCORRECT_DATA);
        }
      }
    } else {
      setError(null);
    }
  }, [fideError]);

  useEffect(() => {
    if (userData) {
      if (userData.full_name && userData.full_name.length) {
        const full_name = userData.full_name.split(' ');
        const first_name = full_name[0];

        updateValue('first_name', first_name, true);

        if (full_name.length > 1) {
          const last_name = userData.full_name.substring(
            first_name.length + 1,
            userData.full_name.length - 1
          );

          updateValue('last_name', last_name, true);
        }
      }

      if (userData.first_name && userData.first_name.length) {
        updateValue('first_name', userData.first_name, true);
      }

      if (userData.last_name && userData.last_name.length) {
        updateValue('last_name', userData.last_name, true);
      }

      if (userData.country) {
        updateValue('federation', userData.country.toString(), true);
        updateValue('nationality', userData.country.toString(), true);
      }

      if (userData.gender) {
        updateValue('gender', userData.gender, true);
      }

      if (userData.birth_date) {
        updateValue('date_of_birth', userData.birth_date, true);
      }
    }
  }, [userData]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.form_inputs}>
        <div className={styles.form_data}>
          <div className={styles.form_row}>
            <BaseInput
              className={styles.first_name}
              name="first_name"
              type="text"
              value={inputState.first_name.value}
              theme="secondary"
              onChange={onChange}
              placeholder={l.registration.first_name}
              showError={
                (showErrors && !inputState.first_name.value) ||
                !!inputState.first_name.errorMessage
              }
              errorText={inputState.first_name.errorMessage}
            />
            <BaseInput
              className={styles.last_name}
              name="last_name"
              type="text"
              value={inputState.last_name.value}
              theme="secondary"
              onChange={onChange}
              placeholder={l.registration.last_name}
              showError={
                (showErrors && !inputState.last_name.value) ||
                !!inputState.last_name.errorMessage
              }
              errorText={inputState.last_name.errorMessage}
            />
          </div>

          <div className={styles.form_row}>
            <ValidatorInputDate
              placeholder={l.registration.birth_date}
              name="date_of_birth"
              onChange={onChange}
              value={inputState.date_of_birth.value}
              type="date"
              min="1900-01-01"
              theme="secondary"
              max={syncedDate().toISOString().slice(0, 10)}
              className={styles.birth_date}
              showError={showErrors && !inputState.date_of_birth.value}
            />
            <BaseInput
              className={styles.birth_place}
              name="place_of_birth"
              type="text"
              value={inputState.place_of_birth.value}
              theme="secondary"
              onChange={onChange}
              placeholder={l.registration.birth_place}
              showError={showErrors && !inputState.place_of_birth.value}
            />
          </div>

          <FormCountries showErrors={showErrors} />

          <TabSelector
            className={styles.form_tabs}
            tabs={genders}
            currentTab={inputState.gender.value}
            setCurrentTab={(gender) => updateValue('gender', gender)}
            type="white_outline"
            showError={showErrors && !inputState.gender.value}
          />
        </div>
        <div className={styles.form_avatar}>
          <ImageUpload
            theme="avatar"
            changeHandler={changePhoto}
            showError={
              error === 'Please try uploading another photo' && !photo.length
            }
            className={styles.image_upload}
            placeholder={imagePlaceholder}
            errorText={!photo.length ? imagePlaceholder : undefined}
          />
          {!photo.length && (
            <p className={styles.avatar_info}>
              {l.registration.the_photo_will_be}
            </p>
          )}
        </div>
      </div>

      {error && <div className={styles.form_error}>{error}</div>}

      <div className={styles.bottom}>
        <LoadingBaseButton
          theme="white"
          buttonType="primary"
          type="submit"
          className={styles.complete_button}
          loading={findFideRequest}
        >
          {l.registration.confirm}
        </LoadingBaseButton>
      </div>
    </form>
  );
};

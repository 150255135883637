import { useMemo } from 'react';
import { IChessboardSettingsContextState } from '../_types';
import { useBoardSettingsData } from '@store/storeshed';

export const useDataHasChanged = (state: IChessboardSettingsContextState) => {
  const boardTheme = useBoardSettingsData('boardTheme');
  const lastMoveTheme = useBoardSettingsData('lastMoveTheme');
  const legalMovesTheme = useBoardSettingsData('legalMovesTheme');
  const soundsTheme = useBoardSettingsData('soundsTheme');
  const premovesTheme = useBoardSettingsData('premovesTheme');
  const autoPromotion = useBoardSettingsData('autoPromotion');

  return useMemo(() => {
    const { currentSettings } = state;

    return (
      boardTheme !== currentSettings.boardTheme ||
      lastMoveTheme !== currentSettings.lastMoveTheme ||
      legalMovesTheme !== currentSettings.legalMovesTheme ||
      soundsTheme !== currentSettings.soundsTheme ||
      premovesTheme !== currentSettings.premovesTheme ||
      autoPromotion !== currentSettings.autoPromotion
    );
  }, [
    boardTheme,
    lastMoveTheme,
    legalMovesTheme,
    soundsTheme,
    premovesTheme,
    autoPromotion,
    state,
  ]);
};

import styled, { css } from 'styled-components';
import { screenSizes, textColors, baseRow, baseText, transparentColor, } from '../../../../styles';
export const $Promocode = styled.div `
  margin-top: 15px;
  ${baseRow()};
`;
export const promocodeInputStyles = css `
  width: 100%;
  max-width: 271px;
`;
export const promocodeApplyStyles = css `
  margin-left: 15px;
  width: 130px;
  min-width: 120px;
  height: 45px;
  font-size: 22px;
  padding: 11px 10px 10px;

  @media ${screenSizes.MOBILE} {
    margin-left: 10px;
  }
`;
export const $PromocodeApplied = styled.div `
  ${baseRow()};
  margin-left: 15px;
  width: 130px;
  min-width: 120px;
  height: 45px;
  ${baseText({ color: textColors.black, fontSize: '22px' })};
  border: 1px solid ${transparentColor(textColors.black, 0.5)};
  border-radius: 3px;

  &::before {
    content: '';
    display: block;
    transform: rotate(45deg);
    width: 10px;
    height: 22px;
    border-right: 1.5px solid ${textColors.black};
    border-bottom: 1.5px solid ${textColors.black};
    margin-right: 12px;
    margin-bottom: 3px;
    margin-left: 8px;
  }

  @media ${screenSizes.MOBILE} {
    margin-left: 10px;
  }
`;
export const $PromocodeInfo = styled.p `
  ${baseText({ color: textColors.black })};
  margin-top: 10px;
  max-width: 410px;
`;

import styled, { css } from 'styled-components';
import { textColors, Sign_in_H1, Sign_in_P1, baseColumn } from '../../../../styles';
export const $Content = styled.div `
  height: 100%;
  ${baseColumn()};
  flex: 1;
`;
export const $Title = styled.h2 `
  ${Sign_in_H1()};
`;
export const $Form = styled.form `
  ${baseColumn('flex-start')};
  flex: 1;
`;
export const $Text = styled.p `
  ${Sign_in_P1()};
  margin: 14px 0 35px 0;
`;
export const inputStyles = css `
  margin-top: 10px;
  width: 100%;
`;
export const buttonStyles = css `
  width: 100%;
  height: 60px;
  margin-top: auto;
`;
export const $ActivationCodeError = styled.p `
  color: ${textColors.orange};
`;

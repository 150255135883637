import { FC, memo } from 'react';

import { GameBoardSettingsPopup } from '@chessarena/components/lib/game/GameBoardSettingsPopup';
import GameBoardSettingsPopupSlider from '@chessarena/components/lib/game/GameBoardSettingsPopupSlider';

import { useBoardSettingsPopupContext } from '../context';

export const BoardSlider: FC = memo(function BoardField() {
  const {
    actions: { onBoardChange, onSubscribeToUnlockClick },
    computed: { boardThemesSliderItems },
    state: {
      currentSettings: { boardTheme },
    },
  } = useBoardSettingsPopupContext();

  return (
    <GameBoardSettingsPopup.Field label={'Board'}>
      <GameBoardSettingsPopupSlider
        sliderItems={boardThemesSliderItems}
        active={boardTheme}
        onChange={onBoardChange}
        premiumProps={{
          bannerTitle: 'Pro', // TODO: add i18n
          textButtonTitle: 'Subscribe to unlock', // TODO: add i18n
          onTextButtonClick: onSubscribeToUnlockClick,
        }}
      />
    </GameBoardSettingsPopup.Field>
  );
});

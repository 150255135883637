"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameBoardSettingsPopup = void 0;
const GameBoardSettingsPopup_1 = require("./GameBoardSettingsPopup");
const GameBoardSettingsPopupContainer_1 = require("./GameBoardSettingsPopupContainer");
const GameBoardSettingsPopupDivider_1 = require("./GameBoardSettingsPopupDivider");
const GameBoardSettingsPopupStickyButton_1 = require("./GameBoardSettingsPopupStickyButton");
const GameBoardSettingsPopupTitle_1 = require("./GameBoardSettingsPopupTitle");
const GameBoardSettingsPopupField_1 = require("./GameBoardSettingsPopupField");
const GameBoardSettingsPopupComposition = GameBoardSettingsPopup_1.GameBoardSettingsPopup;
exports.GameBoardSettingsPopup = GameBoardSettingsPopupComposition;
GameBoardSettingsPopupComposition.Title = GameBoardSettingsPopupTitle_1.GameBoardSettingsPopupTitle;
GameBoardSettingsPopupComposition.ContainerSettings = GameBoardSettingsPopupContainer_1.GameBoardSettingsPopupContainer;
GameBoardSettingsPopupComposition.Divider = GameBoardSettingsPopupDivider_1.GameBoardSettingsPopupDivider;
GameBoardSettingsPopupComposition.Field = GameBoardSettingsPopupField_1.GameBoardSettingsPopupField;
GameBoardSettingsPopupComposition.StickyButton = GameBoardSettingsPopupStickyButton_1.GameBoardSettingsPopupStickyButton;

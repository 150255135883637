import { usePaywallPopupContext } from './_context';
import { ePaywallPopupStep } from './_types';
import { useApplicationContext } from '@application';

export const usePaywallPopupTitle = () => {
  const { localization } = useApplicationContext();
  const { step, type, awaitingPaypal } = usePaywallPopupContext();

  switch (step) {
    case ePaywallPopupStep.INFO:
      return localization.paywall.info.titles[type];
    case ePaywallPopupStep.PAYMENT_SETTINGS:
      if (awaitingPaypal)
        return localization.paywall.payment_settings.awaitung_paypal;
      return localization.paywall.payment_settings.title;
    default:
      return null;
  }
};

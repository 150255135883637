import { useRouter } from 'next/router';
import React, { memo, useMemo } from 'react';

import { ePopupPaths } from '@constants';
import { AnalysisPopup } from './AnalysisPopup';
import { ChallengePopup } from './ChallengePopup';
import { ChessboardSettingsPopup } from './ChessboardSettingsPopup';
import { ConfirmPopup } from './ConfirmPopup';
import { FidePopup } from './FidePopup';
import { FideVerificationPopup } from './FideVerificationPopup';
import { PasswordPopup } from './PasswordPopup';
import { RegistrationPopup } from './RegistrationPopup';
import { TournamentPaymentPopup } from './TournamentPaymentPopup';
import { SignInInvitePopup } from './_SignInInvitePopup';
import { SignInPopup } from './_SignInPopup';
import { WidgetPopup } from './_WidgetPopup';
import { PaywallPopup } from './PaywallPopup';

export const PopupsRouter: React.FC = memo(function PopupRouter() {
  const router = useRouter();

  const popupName = useMemo(() => {
    const query = router.query;

    return query.popup as ePopupPaths;
  }, [router.query]);

  return useMemo(() => {
    switch (popupName) {
      case ePopupPaths.REGISTRATION:
        return <RegistrationPopup />;
      case ePopupPaths.CONFIRM:
        return <ConfirmPopup />;
      case ePopupPaths.PASSWORD:
        return <PasswordPopup type={popupName} />;
      case ePopupPaths.PASSWORD_RESET:
        return <PasswordPopup type={popupName} />;
      case ePopupPaths.PASSWORD_CHANGE:
        return <PasswordPopup type={popupName} />;
      case ePopupPaths.SET_PASSWORD:
        return <PasswordPopup type={popupName} />;

      case ePopupPaths.FIDE:
        return <FidePopup />;
      case ePopupPaths.FIDE_VERIFICATION:
        return <FideVerificationPopup />;

      case ePopupPaths.WIDGET:
        return <WidgetPopup />;

      case ePopupPaths.TOURNAMENT_PAYMENT:
        return <TournamentPaymentPopup />;

      case ePopupPaths.CHALLENGE:
        return <ChallengePopup />;

      case ePopupPaths.CHESSBOARD_SETTINGS:
        return <ChessboardSettingsPopup />;

      case ePopupPaths.SIGN_IN:
        return <SignInPopup />;
      case ePopupPaths.SIGN_IN_INVITE:
        return <SignInInvitePopup />;

      case ePopupPaths.ANALYSIS:
        return <AnalysisPopup />;
      case ePopupPaths.PAYWALL:
        return <PaywallPopup />;
      default:
        return null;
    }
  }, [popupName]);
});

/**
 * Форматтер номера карты (1234 1234 1234 123[4])
 * @param {string} value
 * @returns {result} {string}
 */
export const cardNumberFormater = (value) => {
    const replaced = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = replaced.match(/\d{4,15}\d{0,1}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0; i < match.length; i += 4) {
        parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
        return parts.join(' ');
    }
    else {
        return replaced;
    }
};
/**
 * Форматтер cvc-кода карты (123[4])
 * @param {string} value
 * @returns {result} {string}
 */
export const cardCvcFormater = (value) => {
    const replaced = value.replace(/[^0-9]/gi, '');
    return replaced.substring(0, 4);
};

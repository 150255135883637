import React, { useEffect, Fragment, useMemo } from 'react';

import { useApplicationContext } from '@application';
import styles from './_tabs.module.scss';
import { TabSelector, BaseRadio } from 'shared/atoms';
import { useTournamentStore } from '@store/storeshed';

interface ITabs {
  currentTab: string | null;
  setCurrentTab: (urrentTab: string | null) => void;
}

export const Tabs: React.FC<ITabs> = ({ currentTab, setCurrentTab }) => {
  const { localization: l } = useApplicationContext();
  const tournamentData = useTournamentStore('tournament_data');

  const tabs = useMemo(() => {
    return [
      { id: 1, value: 'i_pay', text: l.profile.payment.i_pay },
      {
        id: 2,
        value: 'somebody_pay',
        text: l.profile.payment.somebody_pay,
        disabled: true,
      },
    ];
  }, [l]);

  const changeTabHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTab(e.target.value);
  };

  useEffect(() => {
    setCurrentTab(tabs[0].value);
  }, []);

  if (!tournamentData) return null;

  return (
    <Fragment>
      <div className={styles.popup_tabs_container}>
        <TabSelector
          className={styles.popup_tabs}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          type="white_outline"
        />
      </div>
      <div className={styles.popup_radio_container}>
        {tabs.map((tab) => (
          <BaseRadio
            key={tab.value}
            className={styles.popup_radio}
            value={tab.value}
            selected={currentTab}
            changeHandler={changeTabHandler}
            disabled={tab.disabled}
          >
            {tab.text}
          </BaseRadio>
        ))}
      </div>
    </Fragment>
  );
};

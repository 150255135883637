import { UpgradeButton } from '@components/molecules/buttons/_UpgradeButton';
import { eFideIdStatus } from '@types';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { UserStatus, useUserStatus } from '@utils/hooks/_useUserSatatus';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Link from 'next/link';
import React, { useEffect, useMemo, useState } from 'react';
import {
  BaseButton,
  ChallengeIcon,
  CloseIcon,
  RightArrowIcon,
} from 'shared/atoms';
import { syncedMoment } from 'shared/helpers/_common';
import { useClassNames } from 'shared/hooks/_useClassNames.hook';
import { Preloader } from 'shared/molecules';
import { GameRatingMode, IChallengeItem } from 'shared/types';
import styles from './_challenge_options_button.module.scss';
dayjs.extend(utc);

interface IChallengeOptionsButton {
  currentRating: string;
  existChallenge: boolean;
  opponentHasFide: boolean;
  handleSubmitChallenge: () => void;
  declinedChallenge: IChallengeItem | null;
}

const REJECT_DELAY = 300;

/**
 * Фрагмент с кнопкой создания челенджа
 * (зависит от типа выбранного рейтинга, залогинен ли пользователь, есть ли про подписка )
 */
export const ChallengeOptionsButton: React.FC<IChallengeOptionsButton> = ({
  currentRating,
  handleSubmitChallenge,
  existChallenge,
  opponentHasFide,
  declinedChallenge,
}) => {
  const [remainingDelay, setRemainingDelay] = useState(0);

  const userStatus = useUserStatus();
  const fideIdStatus = useFideIdStatus();

  const remainingDelayText = dayjs.utc(remainingDelay * 1000).format('mm:ss');

  const fideGameSelected = useMemo(() => {
    return currentRating === GameRatingMode.FIDERATED;
  }, [currentRating]);

  const buttonClassNames = useClassNames({
    [styles.challenge_popup_button]: true,
    [styles.active_challenge]: existChallenge,
  });

  const disableButton = useMemo<boolean>(() => {
    if (!remainingDelay && !fideGameSelected) return false;

    return !opponentHasFide || fideIdStatus !== eFideIdStatus.CONNECTED;
  }, [remainingDelay, fideGameSelected, opponentHasFide, fideIdStatus]);

  useEffect(() => {
    if (declinedChallenge) {
      const gap =
        REJECT_DELAY - syncedMoment().diff(declinedChallenge.created_at, 's');
      setRemainingDelay(gap > 0 ? gap : 0);
    }
  }, [declinedChallenge]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (remainingDelay) {
      interval = setInterval(() => {
        setRemainingDelay(remainingDelay - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [declinedChallenge, remainingDelay]);

  return !fideGameSelected ||
    (userStatus === UserStatus.SUBSCRIBED &&
      fideIdStatus === eFideIdStatus.CONNECTED) ? (
    <div className={styles.container}>
      <BaseButton
        onClick={() => handleSubmitChallenge()}
        buttonType="primary"
        theme="coral"
        className={buttonClassNames}
        disabled={disableButton}
      >
        <ChallengeIcon
          width={17}
          height={19}
          className={styles.challenge_button_icon}
        />
        <span className={styles.challenge_button_text}>
          Challenge to a game
        </span>

        <div className={styles.challenge_popup_preloader_container}>
          <Preloader size="44px" />
          <CloseIcon
            width={12}
            height={12}
            className={styles.challenge_popup_close_icon}
          />
        </div>
      </BaseButton>
      {existChallenge && (
        <div className={styles.right_text_block}>
          {"Wait for the player's response"}
        </div>
      )}
      {!!remainingDelay && (
        <div className={styles.right_text_block}>
          Rejected. Try again in {remainingDelayText}
          <Link
            prefetch={false}
            href={'/'}
            className={styles.find_another_opponent}
          >
            <span>Find another opponent</span>
            <RightArrowIcon
              width={6}
              height={12}
              className={styles.find_another_opponent_icon}
            />
          </Link>
        </div>
      )}
    </div>
  ) : (
    <UpgradeButton className={styles.find_link} />
  );
};

import React, { useState, useEffect } from 'react';
import { BaseInput } from 'shared/atoms';
import { LoadingBaseButton } from 'shared/molecules';
import { CountrySelect } from './_CountrySelect';
import styles from './_registration_form.module.scss';
import { SocialAuthButtons } from './_SocialAuthButtons';
import { useRegistrationData, registrationActions } from '@store/storeshed';
import { useApplicationContext } from '@application';
import { usePopupsContext } from '@store/context/popupsContext/_context';

interface IRegistrationForm {
  nextStep: () => void;
}

export const RegistrationForm: React.FC<IRegistrationForm> = ({ nextStep }) => {
  const { localization: l } = useApplicationContext();
  const inRequest = useRegistrationData('registration_request');
  const emailCheckError = useRegistrationData('email_check_error');
  const endRegistrationRequest = useRegistrationData(
    'end_registration_request'
  );
  const endRegistrationError = useRegistrationData('end_registration_error');

  const {
    state: {
      registration: { freePro },
    },
    computed: {
      registration: {
        paid,
        formData: { inputState, isValid, onChange },
      },
    },
    actions: { setIsVerification },
  } = usePopupsContext();

  const [emailError, setEmailError] = useState<string | null>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [passwordRepeatError, setPasswordRepeatError] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (emailCheckError) {
      registrationActions.setEmailCheckError(null);
    }
  }, [inputState.email.value]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!isValid) {
      setShowErrors(true);
      return;
    }

    if (inRequest) {
      return;
    }

    registrationActions.registration({
      email: inputState.email.value,
      firstName: inputState.first_name.value,
      lastName: inputState.last_name.value,
      country: inputState.country.value,
      isPaid: paid,
      password: inputState.password.value,
      freePro: freePro,
      nextStep,
      setIsVerification,
    });
  };

  useEffect(() => {
    if (inputState.email.errorMessage) {
      setEmailError(inputState.email.errorMessage);
      return;
    }

    if (emailCheckError) {
      setEmailError(l.registration.error[emailCheckError]);
      return;
    }

    setEmailError(null);
  }, [inputState.email.errorMessage, emailCheckError]);

  useEffect(() => {
    if (
      inputState.password_repeat.value !== inputState.password.value &&
      inputState.password_repeat.value
    ) {
      setPasswordRepeatError('Passwords do not match');
    } else {
      setPasswordRepeatError(null);
    }

    if (endRegistrationRequest) {
      registrationActions.setEndRegistrationError(false);
    }
  }, [inputState.password_repeat.value, inputState.password.value]);

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.form_top}>
        <div className={styles.form_row}>
          <BaseInput
            className={styles.input}
            name="first_name"
            type="text"
            placeholder={l.registration.first_name}
            value={inputState.first_name.value}
            theme={paid ? 'primary' : 'login'}
            onChange={onChange}
            showError={
              (showErrors && !inputState.first_name.value) ||
              !!inputState.first_name.errorMessage
            }
            errorText={inputState.first_name.errorMessage}
          />
          <BaseInput
            className={styles.input}
            name="last_name"
            type="text"
            placeholder={l.registration.last_name}
            value={inputState.last_name.value}
            theme={paid ? 'primary' : 'login'}
            onChange={onChange}
            showError={
              (showErrors && !inputState.last_name.value) ||
              !!inputState.last_name.errorMessage
            }
            errorText={inputState.last_name.errorMessage}
          />
        </div>

        <div className={styles.form_row}>
          <CountrySelect showErrors={showErrors} />
        </div>
      </div>

      <div className={styles.form_bottom}>
        <div className={styles.form_row}>
          <BaseInput
            className={styles.input_email}
            name="email"
            type="text"
            placeholder={l.common.email}
            value={inputState.email.value}
            theme={paid ? 'primary' : 'login'}
            onChange={onChange}
            showError={
              !!emailCheckError ||
              (showErrors && !inputState.email.value) ||
              !!inputState.email.errorMessage
            }
            errorText={emailError}
          />
        </div>

        <div className={styles.form_row}>
          <BaseInput
            className={styles.input}
            name="password"
            type="password"
            placeholder={l.registration.password}
            value={inputState.password.value}
            theme={paid ? 'primary' : 'login'}
            onChange={onChange}
          />

          <BaseInput
            className={styles.input}
            name="password_repeat"
            type="password"
            placeholder={l.registration.once_again}
            value={inputState.password_repeat.value}
            theme={paid ? 'primary' : 'login'}
            onChange={onChange}
            errorText={passwordRepeatError}
          />
        </div>
      </div>

      <p
        className={`${styles.error} ${
          inputState.password.errorMessage || endRegistrationError
            ? styles.show
            : ''
        }`}
      >
        {l.registration.password_should_be}
      </p>

      <SocialAuthButtons />

      <div className={styles.form_footer}>
        <div className={styles.button_container}>
          <LoadingBaseButton
            type="submit"
            theme="white"
            buttonType="primary"
            className={styles.button}
            loading={inRequest}
            disabled={
              !!emailError ||
              !isValid ||
              !!inputState.email.errorMessage ||
              !!passwordRepeatError
            }
          >
            {l.registration.confirm}
          </LoadingBaseButton>
        </div>
        <div className={styles.bottom_right}>
          <div className={styles.terms}>
            <p>{l.registration.by_creating}</p>
            <a
              href="https://support.chessarena.com/en/articles/6740851-terms-and-conditions"
              target="_blank"
              className={styles.terms_link}
              rel="noreferrer"
            >
              {l.common.terms_and_conditions}
            </a>
            <a
              href="https://support.chessarena.com/en/articles/6902938-privacy-policy"
              target="_blank"
              className={styles.terms_link}
              rel="noreferrer"
            >
              {l.common.privacy_policy}
            </a>
          </div>
        </div>
      </div>
    </form>
  );
};

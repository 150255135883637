import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { BasePopup, BaseInput } from 'shared/atoms';
import { useForm } from 'shared/hooks/_useForm.hook';
import { Preloader, LoadingBaseButton } from 'shared/molecules';
import { passwordValidtor } from 'shared/utils/_validators';
import { IPasswordPopup, IPasswordForm } from '@types';
import { ePopupPaths } from '@constants';
import { Header } from './fragment/_Header';
import styles from './_password_popup.module.scss';
import { editProfileActions } from '@store/storeshed';
import { registrationActions, loginActions } from '@store/storeshed';
import { useEditProfileStore } from '@store/storeshed';
import { useLoginStore } from '@store/storeshed';
import { useRegistrationData } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';
import { openPopup } from '@utils/_router';
import { useApplicationContext } from '@application';
import { usePopupsContext } from '@store/context/popupsContext/_context';

/**
 * Всплывающее окно с паролем для создания аккаунта и сброса пароля
 * @param {function} closePopup - событие закрытия всплывающего окна
 * @param {boolean} fide - выбрана ли FIDE подписка
 * @param {string} popupName - тип попапа (password-reset, password-change, password)
 */
export const PasswordPopup: React.FC<IPasswordPopup> = ({ type }) => {
  console.log('type', type);
  const router = useRouter();

  const {
    state: {
      registration: { updates },
    },
    computed: {
      registration: { paid },
    },
    actions: { closeRegistrationPopup },
  } = usePopupsContext();

  const [isFide, setIsFide] = useState(paid);
  const [showPreloader, setShowPreloader] = useState(
    type === ePopupPaths.SET_PASSWORD
  );
  const [passwordRepeatError, setPasswordRepeatError] = useState<string | null>(
    null
  );

  const userData = useUserDataStore('data');
  const editProfileRequest = useEditProfileStore('edit_profile_request');

  const passwordError = useRegistrationData('password_error');
  const currentPasswordError = useRegistrationData('current_password_error');

  const { localization: l } = useApplicationContext();

  const error = useRegistrationData('end_registration_error');
  const registrationData = useRegistrationData('registration_data');
  const activationCodeError = useLoginStore('activation_code_error');

  const { inputState, onChange, isValid } = useForm<IPasswordForm>({
    password: {
      value: '',
      required: true,
      validator: (val) => passwordValidtor(val),
    },
    current_password: {
      value: '',
      validator: (val) => passwordValidtor(val),
    },
    password_repeat: {
      value: '',
      required: true,
    },
  });

  const prevStep = (): void => {
    openPopup(`?popup=${ePopupPaths.CONFIRM}`);
  };

  const nextStep = (): void => {
    closeRegistrationPopup();
  };

  const onCurrentPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (currentPasswordError) {
        registrationActions.setCurrentPasswordError(null);
      }
      onChange(e);
    },
    [onChange, currentPasswordError]
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!isValid || passwordRepeatError || error) {
      return;
    }

    if (type === ePopupPaths.PASSWORD && registrationData !== null) {
      registrationActions.registrationEnd({
        uid: registrationData.uid,
        token: registrationData.token,
        new_password: inputState.password.value,
        userData: {
          receive_newsletters: `${updates}`,
        },
        nextStep,
      });
    }
    if (
      (type === ePopupPaths.PASSWORD_RESET ||
        type === ePopupPaths.SET_PASSWORD) &&
      registrationData !== null
    ) {
      registrationActions.registrationEnd({
        uid: registrationData.uid,
        token: registrationData.token,
        new_password: inputState.password.value,
        email: registrationData.email,
        nextStep,
      });
    }

    if (type && type === ePopupPaths.PASSWORD_CHANGE) {
      editProfileActions.changePassword({
        currentPassword: inputState.current_password.value,
        newPassword: inputState.password.value,
        nextStep: closeRegistrationPopup,
      });
    }
  };

  useEffect(() => {
    if (
      inputState.password_repeat.value !== inputState.password.value &&
      inputState.password_repeat.value
    ) {
      setPasswordRepeatError('Passwords do not match');
    } else {
      setPasswordRepeatError(null);
    }

    if (error) {
      registrationActions.setEndRegistrationError(false);
    }
  }, [inputState.password_repeat.value, inputState.password.value]);

  useEffect(() => {
    if (
      userData?.subscriptions.length !== 0 &&
      userData?.subscriptions.length !== undefined &&
      type !== ePopupPaths.SET_PASSWORD
    ) {
      setIsFide(true);
    }
  }, [userData?.subscriptions, type]);

  useEffect(() => {
    // const params = new URLSearchParams(router.query);
    const email = router.query.email as string;
    const token = router.query.token as string;
    const uid = router.query.uid as string;

    if (
      type === ePopupPaths.PASSWORD_RESET &&
      !registrationData &&
      uid &&
      token &&
      email
    ) {
      registrationActions.setRegistrationData({
        uid,
        token,
        email,
      });

      // params.delete('email');
      // params.delete('token');
      // params.delete('uid');
      router.replace({
        // search: params.toString(),
        query: {
          ...router.query,
          email: undefined,
          token: undefined,
          uid: undefined,
        },
      });
    }
  }, [registrationData]);

  useEffect(() => {
    if (type !== ePopupPaths.SET_PASSWORD) return;

    const code = router.query.code as string;
    const email = router.query.email as string;

    if (!code || !email) {
      closeRegistrationPopup();
      return;
    }

    loginActions.activationCode({
      code: code,
      email: email,
      nextStep: () => setShowPreloader(false),
    });
  }, [type, router.query, closeRegistrationPopup]);

  useEffect(() => {
    return () => {
      registrationActions.setCurrentPasswordError(null);
      registrationActions.setPasswordError(null);
    };
  }, []);

  const disabled = useMemo(
    () => !isValid || !!passwordRepeatError || !!activationCodeError,
    [isValid, passwordRepeatError, activationCodeError]
  );

  return (
    <BasePopup
      setShowPopup={closeRegistrationPopup}
      className={styles.popup}
      contentClassName={styles.popup_content}
      color={isFide ? 'pink' : 'grey'}
    >
      {showPreloader && !activationCodeError ? (
        <Preloader />
      ) : (
        <div className={`${styles.container} ${isFide ? styles.payed : ''}`}>
          <Header popupType={type} prevStep={prevStep} l={l} />

          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.input_row}>
              {type === ePopupPaths.PASSWORD_CHANGE && (
                <BaseInput
                  className={styles.input_password}
                  name="current_password"
                  type="password"
                  placeholder={l.registration.current_password}
                  value={inputState.current_password.value}
                  theme={isFide ? 'primary' : 'login'}
                  onChange={onCurrentPasswordChange}
                  errorText={currentPasswordError}
                />
              )}

              <div className={styles.input_column}>
                <BaseInput
                  className={styles.input_password}
                  name="password"
                  type="password"
                  placeholder={
                    type === ePopupPaths.PASSWORD_CHANGE
                      ? l.registration.new_password
                      : l.registration.password
                  }
                  value={inputState.password.value}
                  theme={isFide ? 'primary' : 'login'}
                  onChange={onChange}
                />

                <BaseInput
                  className={styles.input_password}
                  name="password_repeat"
                  type="password"
                  placeholder={l.registration.once_again}
                  value={inputState.password_repeat.value}
                  theme={isFide ? 'primary' : 'login'}
                  onChange={onChange}
                  errorText={passwordRepeatError}
                />
              </div>
            </div>

            {(inputState.password.errorMessage || error) && (
              <pre className={styles.error}>
                {l.registration.password_should_be}
              </pre>
            )}

            {passwordError && (
              <div className={styles.password_error_block}>{passwordError}</div>
            )}

            {typeof activationCodeError === 'string' && (
              <div className={styles.password_error_block}>
                {activationCodeError}
              </div>
            )}

            <div className={styles.bottom}>
              <LoadingBaseButton
                type="submit"
                theme="white"
                buttonType="primary"
                className={styles.confirm}
                loading={editProfileRequest}
                disabled={disabled}
              >
                {type !== ePopupPaths.PASSWORD_CHANGE
                  ? l.registration.continue
                  : l.registration.change}
              </LoadingBaseButton>
            </div>
          </form>
        </div>
      )}
    </BasePopup>
  );
};

import { BoardPreview } from '@components/molecules/BoardPreview';
import { useMemo } from 'react';
import { IChessboardSettingsContextState } from '../_types';
import { $CroppedBoardWrapper } from './_common.styles';

/**
 * Хук собирающий все данные для отображения настройки: Legal moves
 */
export const useLegalMovesItems = ({
  currentSettings: { boardTheme },
}: IChessboardSettingsContextState) => {
  const legalMoves = useMemo(
    () => [
      {
        movesContent: (
          <$CroppedBoardWrapper>
            <BoardPreview
              size={280}
              theme={boardTheme}
              fenPos="1bq/ppp/1n/8/8/8/8/8"
            />
          </$CroppedBoardWrapper>
        ),
        radioButtonValue: 'none',
        radioButtonTitle: 'None',
      },
      {
        movesContent: (
          <$CroppedBoardWrapper>
            <BoardPreview
              size={280}
              theme={boardTheme}
              fenPos="1bq/ppp/1n/8/8/8/8/8"
              possibleMoves={['a8']}
            />
          </$CroppedBoardWrapper>
        ),
        radioButtonValue: 'dots',
        radioButtonTitle: 'Dots',
      },
    ],
    [boardTheme]
  );

  return legalMoves;
};

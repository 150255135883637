"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("../style");
const PaywallPupupPeriod = (props) => {
    const { id, active, title, price, discount, description, disabled, onClick } = props;
    const handleClick = (0, react_1.useCallback)(() => {
        if (onClick && !disabled)
            onClick(id);
    }, [disabled, id, onClick]);
    return ((0, jsx_runtime_1.jsxs)(style_1.$PaywallPupupPeriod, { "data-component": "PaywallPupupPeriod", "$active": active, "$disabled": disabled, onClick: handleClick, children: [discount && ((0, jsx_runtime_1.jsx)(style_1.$PaywallPupupPeriodDiscount, { "$active": active, children: discount })), (0, jsx_runtime_1.jsx)(style_1.$PaywallPupupPeriodTitle, { children: title }), (0, jsx_runtime_1.jsx)(style_1.$PaywallPupupPeriodPrice, { children: price }), (0, jsx_runtime_1.jsx)(style_1.$PaywallPupupPeriodDescription, { children: description })] }));
};
exports.default = PaywallPupupPeriod;

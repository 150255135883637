import { mediaBreakpoints } from '@chessarena/components/lib/common/constants';
import { GameBoardSettingsPopup } from '@chessarena/components/lib/game/GameBoardSettingsPopup';
import Button from '@chessarena/components/lib/general/Button';
import useWindowDimension from '@chessarena/components/lib/utils/useWindowDimension';
import { FC, memo } from 'react';
import { useBoardSettingsPopupContext } from '../context';

export const SaveButton: FC = memo(function SaveButton() {
  const { width: windowWidth } = useWindowDimension();
  const isTabletView = windowWidth > mediaBreakpoints.tablet;

  const {
    actions: { closePopupAndSaveHandler },
    computed: { saveButton },
  } = useBoardSettingsPopupContext();

  return (
    <GameBoardSettingsPopup.StickyButton>
      <Button
        size="s"
        display={isTabletView ? 'inline' : 'block'}
        onClick={closePopupAndSaveHandler}
        type={saveButton.type}
      >
        {saveButton.text}
      </Button>
    </GameBoardSettingsPopup.StickyButton>
  );
});

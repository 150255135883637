import GameBoardSettingsMoves from '@chessarena/components/lib/game/GameBoardSettingsMoves';
import { GameBoardSettingsPopup } from '@chessarena/components/lib/game/GameBoardSettingsPopup';
import { FC, memo } from 'react';
import { useBoardSettingsPopupContext } from '../context';

export const PossibleMovesSelector: FC = memo(function LegalMovesField() {
  const {
    actions: { onLegalMovesChange },
    computed: { legalMovesData },
    state: {
      currentSettings: { legalMovesTheme },
    },
  } = useBoardSettingsPopupContext();

  return (
    <GameBoardSettingsPopup.Field label={'Legal moves'}>
      <GameBoardSettingsMoves
        id="legal-moves"
        onMoveHandleChange={onLegalMovesChange}
        moveInputRadioValue={legalMovesTheme}
        moveListItems={legalMovesData}
      />
    </GameBoardSettingsPopup.Field>
  );
});

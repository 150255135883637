import styled from 'styled-components';
import { baseColumn, baseRow, screenSizes } from '../../../../styles';
export const $Wrapper = styled.div `
  position: relative;
  height: 100%;
  ${baseColumn('flex-start')};

  @media ${screenSizes.MOBILE} {
    flex-direction: row;
  }
`;
export const $ControlButton = styled.div `
  margin-bottom: -1px;
  margin-left: -1px;
  transition: 333ms ease-out;

  &:hover {
    transition: 0s;
  }

  &:last-child {
    border-radius: 0 0 3px 0;
  }

  @media ${screenSizes.MOBILE} {
    &:last-child {
      border-radius: 0 0 3px 0;
    }
  }

  @media ${screenSizes.PHONE} {
    width: 100%;
  }
`;
export const $Row = styled.div `
  position: relative;
  ${baseRow('flex-start')};

  &:first-child {
    ${$ControlButton} {
      &:first-child {
        border-radius: 3px 0 0 0;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
      }
    }
  }

  &:last-child {
    ${$ControlButton} {
      &:first-child {
        border-radius: 0 0 0 3px;
      }
    }
  }

  @media ${screenSizes.MOBILE} {
    flex-direction: column;

    &:first-child {
      ${$ControlButton} {
        &:first-child {
          border-radius: 3px 0 0 0;
        }

        &:last-child {
          border-radius: 0 0 3px 3px;
        }
      }
    }

    &:last-child {
      ${$ControlButton} {
        &:first-child {
          border-radius: 0 3px 0 0;
        }
      }
    }
  }

  @media ${screenSizes.PHONE} {
    flex: 1 0 auto;
  }
`;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$InputLeftAddon = exports.$InputInner = exports.$Input = exports.$InputHtmlInput = exports.$InputIcon = exports.$InputBase = exports.$InputWrapper = exports.$InputElement = exports.$InputLabelDescription = exports.$InputLabel = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const constants_1 = require("../common/constants");
function getTheme(theme) {
    return {
        placeholder: {
            color: theme.component_color.input.placeholder.default,
        },
        label: {
            top: {
                normal: { s: '6px', m: '15px', l: '22px' },
                small: { s: '-5px', m: '0', l: '7px' },
            },
            left: { s: '0', m: '0', l: '0' },
            right: { s: '0', m: '0', l: '0' },
            fontSize: {
                normal: { s: '16px', m: '20px', l: '24px' },
                small: { s: '10px', m: '10px', l: '12px' },
            },
            lineHeight: {
                normal: { s: '16px', m: '20px', l: '24px' },
                small: { s: '10px', m: '10px', l: '12px' },
            },
            fontWeight: {
                normal: 300,
                small: 300,
            },
            paddingBottom: { s: '8px', m: '12px', l: '16px' },
            gap: { s: '4px', m: '8px', l: '12px' },
            colors: {
                normal: theme.component_color.input_box.label.default,
                hovered: theme.component_color.input.label.hover,
                focused: theme.component_color.input.label.active,
                disabled: theme.component_color.input.label.disabled,
                error: theme.component_color.input.label.error,
            },
        },
        labelDescription: {
            colors: {
                normal: theme.semantic_color.text.secondary_invers,
            },
            fontSize: {
                normal: { s: '14px', m: '18px', l: '22px' },
            },
            lineHeight: {
                normal: { s: '16px', m: '20px', l: '24px' },
            },
        },
        element: {
            height: { s: '32px', m: '48px', l: '64px' },
            lineHeight: { s: '16px', m: '24px', l: '28px' },
            fontSize: { s: '16px', m: '20px', l: '24px' },
            fontWeight: { s: 300, m: 300, l: 300 },
            padding: {
                normal: { s: '4px 7px', m: '7px 14px', l: '20px' },
                withLabel: { s: '4px 7px', m: '7px 14px', l: '20px' },
                normalWithIcon: {
                    s: '4px 7px',
                    m: '7px 14px',
                    l: '19px',
                },
                withLabelwithIcon: { s: '4px 7px', m: '7px 14px', l: '20px' },
                withCurrency: { s: '4px 7px 4px 24px', m: '7px 14px 7px 24px', l: '20px 20px 20px 24px' },
            },
            backgroundColors: {
                disabled: 'transparent',
                hovered: 'transparent',
                focused: 'transparent',
                error: 'transparent',
                normal: 'transparent',
            },
            borderColors: {
                normal: theme.component_color.input.line.default,
                hovered: theme.component_color.input.line.hover,
                focused: theme.component_color.input.line.active,
                disabled: theme.component_color.input.line.disabled,
                error: theme.component_color.input.line.error,
            },
            borderRadius: { s: '4px', m: '4px', l: '4px' },
            colors: {
                normal: theme.component_color.input.content.default,
                hovered: theme.component_color.input.content.hover,
                focused: theme.component_color.input.content.active,
                disabled: theme.component_color.input.content.disabled,
                error: theme.component_color.input.content.default,
            },
        },
        icon: {
            colors: {
                normal: theme.component_color.button.combo_button.secondary.content.default,
                hovered: theme.component_color.button.combo_button.secondary.content.hover,
                active: theme.component_color.button.combo_button.secondary.content.hover,
                disabled: theme.component_color.button.combo_button.secondary.content.disabled,
            },
            position: {
                right: { s: '8px', m: '14px', l: '20px' },
                bottom: { s: '16px', m: '24px', l: '32px' },
            },
        },
    };
}
function getInputWidth(props) {
    if (typeof props.$width === 'number')
        return `${props.$width}px`;
    return props.$width || '100%';
}
function getInputLabelColor(props, sHovered) {
    if (props.$disabled)
        return getTheme(props.theme).label.colors.disabled;
    if (props.$error)
        return getTheme(props.theme).label.colors.error;
    if (props.$focused)
        return getTheme(props.theme).label.colors.focused;
    if (sHovered)
        return getTheme(props.theme).label.colors.hovered;
    return getTheme(props.theme).label.colors.normal;
}
function getInputLabelDescriptionColor(props, sHovered) {
    if (props.$disabled)
        return getTheme(props.theme).label.colors.disabled;
    if (props.$error)
        return getTheme(props.theme).label.colors.error;
    if (props.$focused)
        return getTheme(props.theme).label.colors.focused;
    if (sHovered)
        return getTheme(props.theme).label.colors.hovered;
    return getTheme(props.theme).labelDescription.colors.normal;
}
function getInputLabelPaddingBottom(props) {
    return getTheme(props.theme).label.paddingBottom[props.$size];
}
function getInputLabelGap(props) {
    return getTheme(props.theme).label.gap[props.$size];
}
function getInputLabelDescriptionFontSize(props) {
    return getTheme(props.theme).labelDescription.fontSize.normal[props.$size];
}
function getInputLabelDescriptionLineHeight(props) {
    return getTheme(props.theme).labelDescription.lineHeight.normal[props.$size];
}
// function getInputLabelTop(props: $InputLabelProps) {
//   if (props.$small) return getTheme(props.theme).label.top.small[props.$size];
//   return getTheme(props.theme).label.top.normal[props.$size];
// }
// function getInputLabelLeft(props: $InputLabelProps) {
//   return getTheme(props.theme).label.left[props.$size];
// }
// function getInputLabelRight(props: $InputLabelProps) {
//   return getTheme(props.theme).label.right[props.$size];
// }
function getInputLabelFontWeight(props) {
    return getTheme(props.theme).label.fontWeight.normal;
}
function getInputLabelFontSize(props) {
    return getTheme(props.theme).label.fontSize.normal[props.$size];
}
function getInputLabelLineHeight(props) {
    return getTheme(props.theme).label.lineHeight.normal[props.$size];
}
function getInputElementFontWeight(props) {
    return getTheme(props.theme).element.fontWeight[props.$size];
}
function getInputElementFontSize(props) {
    return getTheme(props.theme).element.fontSize[props.$size];
}
function getInputElementHeight(props) {
    return getTheme(props.theme).element.height[props.$size];
}
function getInputElementLineHeight(props) {
    return getTheme(props.theme).element.lineHeight[props.$size];
}
function getInputElementColor(props) {
    if (props.$disabled)
        return getTheme(props.theme).element.colors.disabled;
    if (props.$focused)
        return getTheme(props.theme).element.colors.focused;
    if (props.$error)
        return getTheme(props.theme).element.colors.error;
    return getTheme(props.theme).element.colors.normal;
}
function getInputElementBackgroundColor(props) {
    if (props.$disabled)
        return getTheme(props.theme).element.backgroundColors.disabled;
    if (props.$focused)
        return getTheme(props.theme).element.backgroundColors.focused;
    if (props.$error)
        return getTheme(props.theme).element.backgroundColors.error;
    return getTheme(props.theme).element.backgroundColors.normal;
}
function getInputElementBorderColor(props) {
    if (props.$disabled)
        return getTheme(props.theme).element.borderColors.disabled;
    if (props.$focused)
        return getTheme(props.theme).element.borderColors.focused;
    if (props.$error)
        return getTheme(props.theme).element.borderColors.error;
    return getTheme(props.theme).element.borderColors.normal;
}
function getInputElementBorderRadius(props) {
    return getTheme(props.theme).element.borderRadius[props.$size];
}
function getInputElementPadding(props) {
    if (props.$withCurrency) {
        return getTheme(props.theme).element.padding.withCurrency[props.$size];
    }
    if (props.$withLabel) {
        if (props.$withIcon && props.$textAlign !== 'center') {
            return getTheme(props.theme).element.padding.withLabelwithIcon[props.$size];
        }
        if (props.$withCurrency) {
            return getTheme(props.theme).element.padding.withCurrency[props.$size];
        }
        return getTheme(props.theme).element.padding.withLabel[props.$size];
    }
    if (props.$withIcon && props.$textAlign !== 'center') {
        return getTheme(props.theme).element.padding.normalWithIcon[props.$size];
    }
    return getTheme(props.theme).element.padding.normal[props.$size];
}
function getInputIconColor(props, sHovered) {
    if (props.$disabled)
        return getTheme(props.theme).icon.colors.disabled;
    if (props.$active)
        return getTheme(props.theme).icon.colors.active;
    if (sHovered)
        return getTheme(props.theme).icon.colors.hovered;
    return getTheme(props.theme).icon.colors.normal;
}
function getInputIconPositionRight(props) {
    return getTheme(props.theme).icon.position.right[props.$size];
}
function getInputIconPositionBottom(props) {
    return getTheme(props.theme).icon.position.bottom[props.$size];
}
exports.$InputLabel = styled_components_1.default.label `
  display: flex;
  flex-direction: column;
  gap: ${getInputLabelGap};
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  font-family: ${constants_1.fonts.primary};
  font-weight: ${(props) => getInputLabelFontWeight(props)};

  font-size: ${(props) => getInputLabelFontSize(props)};
  line-height: ${(props) => getInputLabelLineHeight(props)};
  color: ${(props) => getInputLabelColor(props)};
  padding-bottom: ${getInputLabelPaddingBottom};
`;
exports.$InputLabelDescription = styled_components_1.default.div `
  display: block;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  font-family: ${constants_1.fonts.primary};
  font-weight: ${(props) => getInputLabelFontWeight(props)};

  font-size: ${(props) => getInputLabelDescriptionFontSize(props)};
  line-height: ${(props) => getInputLabelDescriptionLineHeight(props)};
  color: ${getInputLabelDescriptionColor};
`;
exports.$InputElement = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: 0 transparent;
  opacity: 1;
  width: 100%;
  max-width: 100%;
  border-radius: 0;
  transition:
    border-color 0.32s ease-out,
    background-color 0.32s ease-out,
    color 0.32s ease-out;
  font-family: ${constants_1.fonts.primary};
  text-align: ${(props) => (props.$textAlign ? props.$textAlign : 'left')};
  color: ${(props) => getInputElementColor(props)};
  background: ${(props) => getInputElementBackgroundColor(props)};

  border: 1px solid ${(props) => getInputElementBorderColor(props)};
  border-radius: ${getInputElementBorderRadius};
  min-height: ${(props) => getInputElementHeight(props)};
  font-weight: ${(props) => getInputElementFontWeight(props)};
  line-height: ${(props) => getInputElementLineHeight(props)};
  font-size: ${(props) => getInputElementFontSize(props)};
  padding: ${(props) => getInputElementPadding(props)};
`;
exports.$InputWrapper = styled_components_1.default.div `
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  ${(props) => props.$required &&
    (0, styled_components_1.css) `
      &::before {
        content: '';
        z-index: 2;
        position: absolute;
        left: 0;
        top: 50%;

        display: flex;
        width: 6px;
        height: 6px;
        background-color: ${({ theme }) => theme.semantic_color.text.decrease};
        border-radius: 100%;
        transform: translateX(-2px) translateY(-2px);
        transform-origin: center;
      }
    `}
`;
exports.$InputBase = (0, styled_components_1.default)(exports.$InputElement).attrs((props) => ({
    type: 'text',
    spellCheck: 'false',
    autoCorrect: 'off',
    autoComplete: props.$autoComplete ? 'on' : 'off',
})) `
  appearance: none;

  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
`;
exports.$InputIcon = styled_components_1.default.div `
  position: absolute;
  box-sizing: border-box;
  right: ${getInputIconPositionRight};
  bottom: ${getInputIconPositionBottom};
  transform: translateY(50%);
  cursor: ${(props) => (props.$clickable && !props.$disabled ? 'pointer' : 'default')};
  pointer-events: ${(props) => (props.$clickable ? 'auto' : 'none')};
  color: ${(props) => getInputIconColor(props)};
  transition: color 0.32s ease-out;

  &:hover {
    color: ${(props) => getInputIconColor(props, true)};
  }
`;
exports.$InputHtmlInput = styled_components_1.default.input `
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 0;
  width: 0;
  opacity: 0;
  border: none;
  outline: none;
`;
exports.$Input = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: block;
  width: ${(props) => getInputWidth(props)};
`;
exports.$InputInner = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active,
  & input:-webkit-autofill:valid,
  & select:-webkit-autofill,
  & select:-webkit-autofill:hover,
  & select:-webkit-autofill:focus {
    -webkit-transition-delay: 99999s;
  }
  & input:-ms-input-placeholder,
  & textarea:-ms-input-placeholder {
    color: ${(props) => getTheme(props.theme).placeholder.color};
    opacity: 1;
  }
  & input::-moz-placeholder,
  & textarea::-moz-placeholder {
    color: ${(props) => getTheme(props.theme).placeholder.color};
    opacity: 1;
  }
  & input::-webkit-input-placeholder,
  & textarea::-webkit-input-placeholder {
    color: ${(props) => getTheme(props.theme).placeholder.color};
    opacity: 1;
  }

  & ${exports.$InputLabel} {
    color: ${(props) => getInputLabelColor(props)};
  }

  &:hover {
    & ${exports.$InputLabel} {
      color: ${(props) => getInputLabelColor(props, true)};
    }
  }
`;
exports.$InputLeftAddon = styled_components_1.default.div `
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px 0 8px 8px;
  transition: color 0.32s ease-out;
  color: ${(props) => getTheme(props.theme).element.colors.normal};

  font-family: ${constants_1.fonts.primary};
  font-size: ${(props) => getInputElementFontSize(props)};
  line-height: ${(props) => getInputElementLineHeight(props)};
  font-weight: 300;

  &:has(+ input:focus) {
    color: ${(props) => getTheme(props.theme).element.colors.focused};
  }
`;

import { GameBoardSettingsPopup } from '@chessarena/components/lib/game/GameBoardSettingsPopup';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ChessboardSettingsContextProvider } from './internal/context';
import { useBoardCellSize } from './internal/hooks/useBoardCellSize.hook';
import { AdditionalSettings } from './internal/sections/AdditilnalSettings';
import { BoardSlider } from './internal/sections/BoardSlider';
import { LastMoveSelector } from './internal/sections/LastMoveSelector';
import { PossibleMovesSelector } from './internal/sections/PossibleMovesSelector';
import { SaveButton } from './internal/sections/SaveButton';
import { closePopup } from '@utils/_router';

export const ChessboardSettingsPopup: React.FC = () => {
  const cellSize = useBoardCellSize();

  const title = (
    <GameBoardSettingsPopup.Title>Settings</GameBoardSettingsPopup.Title>
  );
  return (
    <ThemeProvider theme={{ cellSize }}>
      <ChessboardSettingsContextProvider>
        <GameBoardSettingsPopup
          title={title}
          onClose={closePopup}
          zIndex={1011} // TODO: поправить глобально
        >
          <GameBoardSettingsPopup.ContainerSettings>
            <BoardSlider />

            <GameBoardSettingsPopup.Divider />

            <LastMoveSelector />

            <PossibleMovesSelector />

            <GameBoardSettingsPopup.Divider />
            <AdditionalSettings />
          </GameBoardSettingsPopup.ContainerSettings>

          <SaveButton />
        </GameBoardSettingsPopup>
      </ChessboardSettingsContextProvider>
    </ThemeProvider>
  );
};

import styled, { css } from 'styled-components';
import { baseColumn, Game_P3, textColors, buttonColors, transparentColor, baseTransition, } from '../../../../styles';
export const $Icon = styled.div `
  path {
    ${baseTransition}
  }
`;
export const $Text = styled.p `
  ${baseTransition}
`;
export const $A = styled.a `
  display: flex;
  text-decoration: none;
`;
export const $Button = styled.button `
  position: relative;
  border: none;
  background-color: inherit;
  cursor: pointer;
  ${baseColumn()}
  ${baseTransition};

  &:disabled {
    cursor: default;
  }

  ${({ withText }) => {
    return withText
        ? css `
          ${$Icon} {
            position: relative;
            width: 25px;
            height: 25px;
          }

          ${$Text} {
            margin-top: 5px;
            ${Game_P3(textColors['grey'])}
          }

          &:hover {
            ${$Text} {
              transition: 0s;
              color: ${textColors['white']};
            }
          }

          &:disabled {
            ${$Icon} {
              path {
                fill: ${buttonColors['grey_deep']};
              }
            }
            ${$Text} {
              color: ${textColors['grey']};
            }
          }
        `
        : css `
          &:hover {
            ${$Icon} {
              path {
                transition: 0s;
                fill: ${buttonColors['brown']};
              }
            }
          }

          &:disabled {
            ${$Icon} {
              path {
                fill: ${buttonColors['brown_dark_lighter']};
              }
            }
          }
        `;
}}

  ${({ theme }) => {
    switch (theme) {
        case 'brown':
            return css `
          ${$Icon} {
            path {
              fill: ${buttonColors['brown']};
            }
          }

          &:hover:not([disabled]) {
            ${$Icon} {
              path {
                transition: 0s;
                fill: ${buttonColors['white']};
              }
            }
          }
        `;
        case 'white_opacity':
            return css `
          ${$Icon} {
            path {
              fill: ${transparentColor(buttonColors['white'], 0.5)};
            }
          }

          &:hover:not([disabled]) {
            ${$Icon} {
              path {
                transition: 0s;
                fill: ${transparentColor(buttonColors['white'], 0.8)};
              }
            }
          }
        `;
    }
}}
`;
export const $LinkButton = styled($Button) `
  flex: 1;
`;

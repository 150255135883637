import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ISignInInviteForm } from './_types';
import { $SigninForm, $IconButton } from './_styles';
import { IconButton, CloseIcon } from 'shared/atoms';
import { PasswordResetForm } from 'shared/molecules';
import { Form } from './fragments/_Form';
import { Heading } from './fragments/_Heading';
import { ConfirmationPopup } from '@components/molecules/popups/_ConfirmationPopup';
import { AlertPopup } from '@components/molecules/popups/_AlertPopup';
import { loginActions } from '@store/storeshed';
import { useAlertPopup } from '@utils/hooks/_useAlertPopup.hook';
import { useConfirmationPopup } from '@utils/hooks/_useConfirmationPopup.hook';

/**
 * Форма логина
 * @param {Function} setShowSignInForm - задает состояние формы логина
 * @param {Function} setShowRegisterForm - закрытие формы входа
 * @param {Function} setShowPasswordForm - закрытие формы восстановления пароля
 * @param {string} loginError - текст ошибки авторизации
 * @param {Function} login - обработчик запроса на логин
 * @param {boolean} inRequest  - состояние запроса на логин
 * @param {string} l - локализации
 * @param {Function} resendActivationCode - запрос на проверочный код
 * @param {Function} activationCode - проверка проверочного кода
 * @param {string} activationCodeError - сообщение об ошибке
 * @param {Function} setActivationCodeError - установка сообщения об ошибке
 * @param {boolean} showActivationButton - показывать ли кнопку активации аккаунта
 * @param {Function} redirectToActivationForm - переход к активации аккаунта
 * @param {object} otherProps - любые другие значения, передаваемые компоненту
 */
export const SignInInviteForm: React.FC<
  PropsWithChildren<ISignInInviteForm>
> = ({
  children,
  inviteFriendName,
  userUid,
  closePopup,
  setShowRegisterForm,
  setShowPasswordForm,
  login,
  loginError,
  inRequest,
  resendActivationCode,
  activationCode,
  activationCodeError,
  setActivationCodeError,
  showActivationButton,
  redirectToActivationForm,
  l,
  className = '',
  ...otherProps
}) => {
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  const {
    isConfirmPopupShown,
    confirmPopupRef,
    onAccept,
    onDecline,
    showConfirmationPopup,
    confirmText,
  } = useConfirmationPopup();

  const {
    isAlertPopupShown,
    alertPopupRef,
    onClose,
    alertText,
    showAlertPopup,
  } = useAlertPopup();

  const popups = useMemo(() => {
    return {
      confirm: showConfirmationPopup,
      alert: showAlertPopup,
    };
  }, [showConfirmationPopup, showAlertPopup]);

  const onClosePopup = useCallback(() => {
    loginActions.setLoginError(null);
    closePopup(true);
  }, [closePopup]);

  const resetError = useCallback(() => {
    loginActions.setLoginError(null);
  }, []);

  return (
    <Fragment>
      <$SigninForm className={className} {...otherProps}>
        <$IconButton as={IconButton} onClick={onClosePopup}>
          <CloseIcon width={20} height={20} fill="#ADACAC" />
        </$IconButton>

        <Heading
          userUid={userUid}
          inviteFriendName={inviteFriendName}
          showPasswordReset={showPasswordReset}
          closePopup={closePopup}
          setShowRegisterForm={setShowRegisterForm}
          popups={popups}
        />

        {showPasswordReset && (
          <PasswordResetForm
            resendActivationCode={resendActivationCode}
            setShowPasswordForm={setShowPasswordForm}
            activationCode={activationCode}
            activationCodeError={activationCodeError}
            setActivationCodeError={setActivationCodeError}
            l={l}
          />
        )}

        <Form
          loginError={loginError}
          inRequest={inRequest}
          showActivationButton={showActivationButton}
          showPasswordReset={showPasswordReset}
          login={login}
          redirectToActivationForm={redirectToActivationForm}
          setShowPasswordReset={setShowPasswordReset}
          resetError={resetError}
        >
          {children}
        </Form>
      </$SigninForm>

      {isConfirmPopupShown && (
        <ConfirmationPopup
          ref={confirmPopupRef}
          promptText={confirmText}
          onAccept={onAccept}
          onDecline={onDecline}
        />
      )}

      {isAlertPopupShown && (
        <AlertPopup ref={alertPopupRef} onClick={onClose} text={alertText} />
      )}

      <div onClick={onClosePopup} />
    </Fragment>
  );
};

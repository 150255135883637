import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Галочка
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 */
export const CheckIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 27 20", fill: "none" },
    React.createElement("rect", { x: "24.8701", width: "3", height: "25", transform: "rotate(45 24.8701 0)", fill: fill }),
    React.createElement("rect", { x: "11.4351", y: "17.6777", width: "3", height: "13", transform: "rotate(135 11.4351 17.6777)", fill: fill })));

import React from 'react';
import styles from './_styles.module.scss';
import { useApplicationContext } from '@application';
import { eFideIdStatus } from '@types';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { useUserStatus, UserStatus } from '@utils/hooks/_useUserSatatus';

interface IHomeFideRatedText {
  userRating: number;
}

/**
 * Фрагмент с текстом в настройке игры для фиде игры
 * (зависит от типа выбранного рейтинга, залогинен ли пользователь, есть ли про подписка )
 */
export const FideRatedText: React.FC<IHomeFideRatedText> = ({ userRating }) => {
  const { localization: l } = useApplicationContext();
  const userStatus = useUserStatus();
  const fideIdStatus = useFideIdStatus();

  if (userStatus !== UserStatus.SUBSCRIBED) {
    return (
      <div className={styles.text}>
        {l?.main_page?.game?.text.no_pro_account}
      </div>
    );
  }

  if (fideIdStatus === eFideIdStatus.ON_CHECK) {
    return (
      <div className={styles.text}>
        {l?.main_page?.game?.text.fide_approval}
      </div>
    );
  }

  if (fideIdStatus === eFideIdStatus.REJECTED) {
    return (
      <div className={styles.text}>
        {l?.main_page?.game?.text.fide_rejected}
      </div>
    );
  }

  if (fideIdStatus === eFideIdStatus.NOT_CONNECTED) {
    return (
      <div className={styles.text}>
        {l?.main_page?.game?.text.missed_fields}
      </div>
    );
  }

  return (
    <div className={styles.text}>
      {l?.main_page?.game?.text.fide_rated}{' '}
      {userRating || l?.main_page?.game?.text.undefined_yet}
    </div>
  );
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment } from 'react';
import { StarIcon } from '../../icons';
import { $Button, $Addition, $Row, $Time, $Title, $StarIconWrapper, } from './_styles';
/**
 * Кнопка выбора режима игры
 * @param {function} onClick- обработчик нажатия на кнопку
 * @param {object} style - стили кнопки
 * @param {object} otherProps - любые другие значения, передаваемые кнопке
 */
export const TimeControlButton = (_a) => {
    var { active = false, title = '', time, addition = 'min', className = '' } = _a, otherProps = __rest(_a, ["active", "title", "time", "addition", "className"]);
    return (React.createElement($Button, Object.assign({ className: className, active: active, haveTitle: title.length > 0 }, otherProps),
        title.length > 0 && (React.createElement(Fragment, null,
            React.createElement($Title, null, title),
            React.createElement($StarIconWrapper, null,
                React.createElement(StarIcon, { width: 14, height: 14, className: 'star_icon' })))),
        React.createElement($Row, null,
            React.createElement($Time, null, time),
            React.createElement($Addition, null, addition))));
};

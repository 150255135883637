import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CloseIcon, IconButton } from '../..';
import { $PopupWrapper, $ClickArea, $Popup, $Content, $IconButtonStyles, } from './_styles';
/**
 * Попап
 * @param {ReactComponent} children - компонент, который необходимо отобразить
 * @param {function} setShowPopup  - задает состояние текущего окна
 * @param {string} color - цвет попапа
 * @param {boolean} closeOnWrapper - закрывать ли при нажатии на область вокруг
 * @param {string} className - внешний класс
 * @param {string} contentClassName - внешний класс для контента
 */
export const BasePopup = ({ setShowPopup, color, className = '', contentClassName = '', closeOnWrapper = false, children, }) => {
    const onClick = () => {
        if (typeof setShowPopup === 'function') {
            setShowPopup(false);
        }
    };
    useEffect(() => {
        if (!document.getElementById('popup-styles')) {
            const style = document.createElement('style');
            style.id = 'popup-styles';
            style.innerHTML = `
        .fix {
          position: fixed;
          width: 100%;
          height: 100%;
        }
        .scroll {
          overflow-y: scroll;
        }
      `;
            document.getElementsByTagName('head')[0].appendChild(style);
        }
        if (document.body.clientHeight > window.innerHeight) {
            document.body.classList.add('scroll');
        }
        document.body.classList.add('fix');
        return () => {
            if (document.body.classList.contains('scroll')) {
                document.body.classList.remove('scroll');
            }
            document.body.classList.remove('fix');
        };
    }, []);
    return (React.createElement($PopupWrapper, null,
        React.createElement($ClickArea, { onClick: closeOnWrapper ? onClick : () => null }),
        React.createElement($Popup, { className: className, theme: color },
            React.createElement($IconButton, { theme: "white_opacity", onClick: onClick },
                React.createElement(CloseIcon, { width: 20, height: 20 })),
            React.createElement($Content, { className: contentClassName }, children))));
};
const $IconButton = styled(IconButton) `
  ${$IconButtonStyles}
`;

/* eslint-disable @typescript-eslint/no-explicit-any */

import { userDataActions } from '@store/storeshed';
import { useEffect, useState, useRef, MutableRefObject } from 'react';

interface IButtonStyles {
  shape?: 'rect' | 'pill';
  color?: 'gold' | 'blue' | 'silver' | 'white' | 'black';
  layout?: 'horizontal' | 'vertical';
  label?: 'checkout' | 'paypal' | 'buynow' | 'pay' | 'installment';
  size?: 'small';
  tagline?: 'false' | 'true';
  height?: number;
}

/**
 * Хук для инициализации и обработки платежей через paypal
 * @param {string} customId - данные для оплаты (user_uid, tournament_id, etc.)
 * @param {string} paymentSuccess - подтвердилась ли оплата
 * @param {string} showPaypalButton - отображать кнопку пейпал?
 * @returns {boolean} paypalInited - инициализирован ли скрипт пейпала
 * @returns {boolean} awaitingPaypal - состояние ожидания подтверждения оплаты
 */
export function usePaypal({
  customId,
  paymentSuccess,
  showPaypalButton,
  type,
  price,
  height,
  closeAfterPayment,
  successCallback,
}: {
  customId: string | null | undefined;
  paymentSuccess: boolean;
  showPaypalButton: boolean;
  type: 'subscription' | 'product';
  price?: number | null;
  height?: number;
  closeAfterPayment?: boolean;
  successCallback?: () => void;
}): { paypalInited: boolean; awaitingPaypal: boolean } {
  const intervalRef: MutableRefObject<ReturnType<typeof setInterval> | null> =
    useRef(null);

  const [paypalScriptLoaded, setPaypalScriptLoaded] = useState(false);
  const [paypalInited, setPaypalInited] = useState(false);
  const [awaitingPaypal, setAwaitingPaypal] = useState(false);

  useEffect(() => {
    if (awaitingPaypal) {
      userDataActions.getMeData();

      const interval = setInterval(async () => {
        await userDataActions.getMeData();
      }, 3000);

      intervalRef.current = interval;
    }
  }, [awaitingPaypal]);

  useEffect(() => {
    if (paymentSuccess) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      if (successCallback) {
        successCallback();
      }

      setAwaitingPaypal(false);
    }
  }, [paymentSuccess]);

  useEffect(() => {
    const paypalScript = document.getElementById(
      type === 'subscription'
        ? 'subscription-paypal-script'
        : 'product-paypal-script'
    );

    if (!paypalScript) {
      const paypalId =
        process.env.NEXT_PUBLIC_ENV === 'master'
          ? 'Aa-dDK_p-oa117iXmevY7Lgx8GFFPrXSsF-73L5GOQa0Qh-T3PEvhnmRyWrOmqEWQzh9cDwPkGQDVN-F'
          : 'AVURTdMSzYqeDhKdOFv3YWn7cly-yCDor438qx-RwJN0KGTVel2vZ7Zbg75Tx4wSSOqPJqKf5BUOTAE3';

      const srcText =
        type === 'subscription'
          ? '&vault=true&intent=subscription'
          : '&currency=EUR';

      const script = document.createElement('script');
      script.id =
        type === 'subscription'
          ? 'subscription-paypal-script'
          : 'product-paypal-script';
      script.async = true;
      script.src = `https://www.paypal.com/sdk/js?client-id=${paypalId}${srcText}&disable-funding=credit,card,venmo`;
      script.dataset.sdkIntegrationSource = 'button-factory';

      document.body.appendChild(script);

      script.addEventListener('load', function () {
        setPaypalScriptLoaded(true);
      });
    } else {
      setPaypalScriptLoaded(true);
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (
      !showPaypalButton ||
      !customId ||
      awaitingPaypal ||
      !paypalScriptLoaded ||
      (type === 'product' && !price)
    ) {
      return;
    }

    console.log('start init');

    const plan_id =
      process.env.NEXT_PUBLIC_ENV === 'master'
        ? 'P-6AJ73809WG448031HMHBRSNY'
        : 'P-5KA03046F50586503MG66EAI';

    const style: IButtonStyles = {
      shape: 'rect',
      color: 'gold',
      layout: 'horizontal',
      label: 'checkout',
      size: 'small',
      tagline: 'false',
    };

    if (height && height >= 25 && height <= 55) {
      style.height = height;
    }

    try {
      (window as any).paypal
        .Buttons({
          style,

          onInit: function () {
            setPaypalInited(true);
          },

          createSubscription:
            type === 'subscription'
              ? function (_data: any, actions: any) {
                  return actions.subscription.create({
                    plan_id,
                    custom_id: customId,
                  });
                }
              : undefined,

          createOrder:
            type === 'product'
              ? function (_data: any, actions: any) {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: { value: price, currency_code: 'EUR' },
                        custom_id: customId,
                      },
                    ],
                  });
                }
              : undefined,

          onError: function (err: string) {
            console.log('onError', err);
          },

          onApprove: async function (_data: any, actions: any) {
            if (actions.subscription) {
              if (closeAfterPayment && successCallback) {
                successCallback();
              } else {
                setAwaitingPaypal(true);
              }
            }

            if (actions.order) {
              await actions.order.capture().then(function () {});

              if (closeAfterPayment && successCallback) {
                successCallback();
              } else {
                setAwaitingPaypal(true);
              }
            }
          },
        })
        .render('#paypal-button-container');
    } catch (error) {
      console.log('paypal init error', error);
    }
  }, [customId, showPaypalButton, paypalScriptLoaded]);

  return { paypalInited, awaitingPaypal };
}

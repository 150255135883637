import React, { useState, useEffect, useMemo } from 'react';
import { BaseInput, BaseSelect } from 'shared/atoms';
import { syncedMoment } from 'shared/helpers/_common';
import { useMobile } from 'shared/hooks/_useMobile.hook';
import { LoadingBaseButton } from 'shared/molecules';
import { useApplicationContext } from '@application';
import styles from './_have_fide.module.scss';
import { fideSubscriptionActions } from '@store/storeshed';
import { useFideSubscriptionStore } from '@store/storeshed';

export const HaveFide: React.FC = () => {
  const isMobile = useMobile();

  const { localization: l } = useApplicationContext();
  const inRequest = useFideSubscriptionStore('find_fide_request');

  const years = useMemo(() => {
    return Array.from(Array(100).keys()).map((x) => {
      const year = syncedMoment().year() + (x - 99);
      return { label: year, value: year };
    });
  }, []);

  const [user, setUser] = useState('');
  const [year, setYear] = useState('');
  const [isId, setIsId] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!isId) {
      if (!user || !year) {
        setShowErrors(true);
        return;
      }

      fideSubscriptionActions.setFideError(null);
      fideSubscriptionActions.findFidePlayers({
        full_name: user,
        birth_year: year,
      });
    } else {
      fideSubscriptionActions.setFideError(null);
      fideSubscriptionActions.findFidePlayers({ fide_id: Number(user) });
    }

    setShowErrors(false);
  };

  useEffect(() => {
    setIsId(!isNaN(Number(user)) && !!user.length);
  }, [user]);

  return (
    <form onSubmit={handleSubmit} className={styles.have_fide_form}>
      <BaseInput
        className={styles.input_id}
        name="user"
        type="text"
        value={user}
        theme="secondary"
        onChange={(e) => setUser(e.target.value)}
        placeholder={
          isMobile
            ? l.registration.name_or_id_short
            : l.registration.name_or_id_long
        }
        showError={showErrors && !user.length}
      />
      <BaseSelect
        className={styles.input_year}
        items={years}
        onChange={(e) => setYear(e.target.value)}
        name="year"
        value={year}
        placeholder={l.registration.birth_year}
        theme="secondary"
        disabled={isId}
        showError={showErrors && !year.length && !isId}
      />

      <div className={styles.bottom}>
        <LoadingBaseButton
          theme="white"
          buttonType="primary"
          type="submit"
          className={styles.complete_button}
          loading={inRequest}
        >
          {l.registration.confirm}
        </LoadingBaseButton>

        <div className={styles.wrapper}>
          <p className={styles.wrapper_text}>{l.registration.your_otb_elo}</p>
        </div>
      </div>
    </form>
  );
};

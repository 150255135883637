import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Звезда
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 */
export const StarIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 14 13" },
    React.createElement("path", { d: "M7 0l1.5716 4.83688h5.0858L9.5429 7.82624l1.5716 4.83686L7 9.67376 2.8855 12.6631l1.5716-4.83686L.342604 4.83688H5.4284L7 0z", fill: fill })));

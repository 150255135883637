import React, { Fragment } from 'react';
import { useApplicationContext } from '@application';
import styles from './_challenge_options_text.module.scss';
import { Preloader } from 'shared/molecules';
import {
  IAdvancedTimeControl,
  ITimeControl,
  GameRatingMode,
} from 'shared/types';
import { FideRatedText } from '@components/atoms/FideRatedText';
import { useUserDataStore } from '@store/storeshed';
import { userGameRating } from '@utils/_helpers';

interface IChallengeOptionsText {
  currentRating: string;
  opponentHasFide: boolean;
  selectedTimeControl: IAdvancedTimeControl | ITimeControl | null;
}

export const ChallengeOptionsText: React.FC<IChallengeOptionsText> = ({
  selectedTimeControl,
  currentRating,
  opponentHasFide,
}) => {
  const { localization: l } = useApplicationContext();
  const userData = useUserDataStore('data');
  const ratings = useUserDataStore('ratings');
  const ratingsReguest = useUserDataStore('ratings_request');

  if (ratingsReguest || !selectedTimeControl) {
    return (
      <div className={styles.text}>
        <Preloader />
      </div>
    );
  }

  if (!userData && currentRating !== GameRatingMode.UNRATED) {
    return (
      <div className={styles.text}>{l?.main_page?.game?.text.no_account}</div>
    );
  }

  return (
    <Fragment>
      {currentRating === GameRatingMode.UNRATED && (
        <div className={styles.text}>
          {l?.main_page?.game?.text.unrated_challenge}
        </div>
      )}

      {currentRating === GameRatingMode.RATED && (
        <div className={styles.text}>
          {`${l?.main_page?.game?.text.rated} ${userGameRating(
            ratings,
            { id: GameRatingMode.RATED },
            selectedTimeControl
          )}. ${l?.main_page?.game?.text.try_fide_rated} `}
        </div>
      )}

      {userData &&
        currentRating === GameRatingMode.FIDERATED &&
        opponentHasFide && (
          <div className={styles.text}>
            <FideRatedText
              userRating={userGameRating(
                ratings,
                { id: GameRatingMode.FIDERATED },
                selectedTimeControl
              )}
            />
          </div>
        )}

      {userData &&
        currentRating === GameRatingMode.FIDERATED &&
        !opponentHasFide && (
          <div className={styles.text}>
            {l?.main_page?.game?.text.opponent_cant_play_foa}
          </div>
        )}
    </Fragment>
  );
};

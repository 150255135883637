import { useApplicationContext } from '@application';
import { boardSettingsActions } from '@store/storeshed';
import { IUpdateBoardSettingsProps } from '@store/storeshed';
import { useBoardSettingsData } from '@store/storeshed';
import { useCallback, useMemo } from 'react';

/**
 * Хук который получает из хранилища данные о текущей теме,
 * возвращает функцию сохранения настроек, локализацию настроек
 *
 */
export const useSettings = () => {
  const boardTheme = useBoardSettingsData('boardTheme');
  const lastMoveTheme = useBoardSettingsData('lastMoveTheme');
  const legalMovesTheme = useBoardSettingsData('legalMovesTheme');
  const soundsTheme = useBoardSettingsData('soundsTheme');
  const premovesTheme = useBoardSettingsData('premovesTheme');
  const autoPromotion = useBoardSettingsData('autoPromotion');

  const { updateBoardSettings } = boardSettingsActions;

  const { localization } = useApplicationContext();

  const saveSettingsClick = useCallback(
    (boardSettings: IUpdateBoardSettingsProps) => {
      updateBoardSettings(boardSettings);
    },
    [updateBoardSettings]
  );

  const currentSettings = useMemo(
    () => ({
      boardTheme,
      lastMoveTheme,
      legalMovesTheme,
      soundsTheme,
      premovesTheme,
      autoPromotion,
      updateBoardSettings,
    }),
    [
      autoPromotion,
      boardTheme,
      lastMoveTheme,
      legalMovesTheme,
      premovesTheme,
      soundsTheme,
      updateBoardSettings,
    ]
  );

  return {
    currentSettings,
    saveSettingsClick,
    localization,
  };
};

import styled, { css } from 'styled-components';
import { buttonColors, shapeColors } from '../../../../styles';
export const $Container = styled.div `
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;
export const $AvatarStyle = css `
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  object-fit: cover;
  border: ${({ isDefaultImage }) => isDefaultImage ? `1px solid ${buttonColors['grey_deep']}` : ''};

  &.clickable {
    background-color: ${buttonColors['white']};
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      box-shadow: 0 0 6px 2px rgba(255, 255, 255, 0.2);
      transition: 0.1s;
      opacity: 0;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
`;
export const $IsOnlineStatus = styled.div `
  position: absolute;
  bottom: 6%;
  right: 6%;
  width: 15%;
  min-width: 8px;
  height: 15%;
  min-height: 8px;
  border-radius: 50%;
  background-color: ${shapeColors['green']};
  border: 2px solid #191919;
`;

import styled, { css } from 'styled-components';
import { buttonColors, shapeColors, screenSizes, transparentColor, } from '../../../../styles';
export const $PopupWrapper = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(20px);
  background: ${transparentColor(buttonColors.black, 0.6)};
`;
export const $ClickArea = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
export const $Popup = styled.div `
  border-radius: 5px 5px 0px 0px;
  max-width: 920px;
  width: 100%;
  max-height: 760px;
  height: 100%;
  position: relative;
  margin-top: auto;

  @media ${screenSizes.MOBILE} {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
    max-height: 100%;
  }

  ${({ theme }) => {
    switch (theme) {
        case 'brown':
            return css `
          background: ${buttonColors['brown']};
        `;
        case 'pink':
            return css `
          background: ${shapeColors['pink']};
        `;
        case 'grey':
            return css `
          background: ${shapeColors['grey']};
        `;
    }
}}
`;
export const $Content = styled.div `
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 40px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${screenSizes.MOBILE} {
    padding: 20px 15px 35px;
  }
`;
export const $IconButtonStyles = css `
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
`;

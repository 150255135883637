import { PaywallPupupFeature } from '@chessarena/components/lib/paywall/PaywallPupup/types';

import { routesConstants } from '@constants';

export const paywallItems: PaywallPupupFeature[] = [
  {
    type: 'fide',
    label: '',
  },
  {
    type: 'skins',
    label: '',
  },
  {
    type: 'statistics',
    label: '',
  },
  {
    type: 'analysis',
    label: '',
  },
  {
    type: 'more',
    label: '',
    link: routesConstants.PRICING,
  },
];

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { BaseInput, CloseIcon, IconButton, TextButton } from '../../../atoms';
import { PasswordResetForm } from '../_passwordResetForm';
import { $SigninForm, $Title, $NewUserContainer, $Text, $CreateAccountBtn, inputStyles, $ForgotContainer, passwordResetBtnStyles, $LoginErrorText, $IconButton, $ActivateAccountBtn, $SubmitLoadingButton, } from './_styles';
import { useForm } from '../../../../hooks/_useForm.hook';
import { emailIsNotValid } from '../../../../utils/_validators';
/**
 * Форма логина
 * @param {Function} setShowSignInForm - задает состояние формы логина
 * @param {Function} setShowRegisterForm - закрытие формы входа
 * @param {Function} setShowPasswordForm - закрытие формы восстановления пароля
 * @param {string} loginError - текст ошибки авторизации
 * @param {Function} login - обработчик запроса на логин
 * @param {boolean} inRequest  - состояние запроса на логин
 * @param {string} baseUrl - урл текущей страницы
 * @param {string} l - локализации
 * @param {Function} resendActivationCode - запрос на проверочный код
 * @param {Function} activationCode - проверка проверочного кода
 * @param {string} activationCodeError - сообщение об ошибке
 * @param {Function} setActivationCodeError - установка сообщения об ошибке
 * @param {boolean} showActivationButton - показывать ли кнопку активации аккаунта
 * @param {Function} redirectToActivationForm - переход к активации аккаунта
 * @param {object} otherProps - любые другие значения, передаваемые компоненту
 */
export const SignInForm = (_a) => {
    var { children, closePopup, setShowRegisterForm, setShowPasswordForm, login, loginError, inRequest, baseUrl, resendActivationCode, activationCode, activationCodeError, setActivationCodeError, showActivationButton, redirectToActivationForm, resetError, l, className = '' } = _a, otherProps = __rest(_a, ["children", "closePopup", "setShowRegisterForm", "setShowPasswordForm", "login", "loginError", "inRequest", "baseUrl", "resendActivationCode", "activationCode", "activationCodeError", "setActivationCodeError", "showActivationButton", "redirectToActivationForm", "resetError", "l", "className"]);
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const handleSubmit = (state) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield login(state.email.value, state.password.value);
        }
        catch (error) {
            console.log(error);
        }
    });
    const changeHandler = (e) => {
        onChange(e);
        if (resetError)
            resetError();
    };
    const { inputState, onChange, onSubmit } = useForm({
        email: {
            value: '',
            required: true,
            validator: (val) => emailIsNotValid(val),
        },
        password: {
            value: '',
            required: true,
        },
    }, handleSubmit);
    return (React.createElement(Fragment, null,
        React.createElement($SigninForm, Object.assign({ className: className }, otherProps),
            !showPasswordReset && (React.createElement(Fragment, null,
                React.createElement($Title, null, "Sign in"),
                React.createElement($NewUserContainer, null,
                    React.createElement($Text, null, "New user?"),
                    React.createElement($CreateAccountBtn, { onClick: () => {
                            if (typeof setShowRegisterForm === 'function') {
                                setShowRegisterForm();
                            }
                        } }, "Create an account")))),
            React.createElement($IconButton, { as: IconButton, onClick: closePopup },
                React.createElement(CloseIcon, { width: 20, height: 20, fill: "#ADACAC" })),
            showPasswordReset && (React.createElement(PasswordResetForm, { resendActivationCode: resendActivationCode, setShowPasswordForm: setShowPasswordForm, activationCode: activationCode, activationCodeError: activationCodeError, setActivationCodeError: setActivationCodeError, l: l })),
            !showPasswordReset && (React.createElement("form", { onSubmit: onSubmit },
                React.createElement($BaseInput, { name: "email", type: "email", placeholder: (l === null || l === void 0 ? void 0 : l.common.email) || 'email', theme: "login", value: inputState.email.value, errorText: inputState.email.errorMessage, onChange: changeHandler }),
                React.createElement($BaseInput, { name: "password", type: "password", theme: "login", value: inputState.password.value, placeholder: (l === null || l === void 0 ? void 0 : l.registration.password) || 'Password', onChange: changeHandler }),
                children,
                React.createElement($ForgotContainer, null,
                    React.createElement($Text, null, "Forgot password?"),
                    React.createElement($TextButton, { type: "button", iconSize: "big", iconPosition: "left", onClick: () => {
                            console.log('Enter');
                            setShowPasswordReset(!showPasswordReset);
                        } }, "Recover")),
                !!loginError && (React.createElement($LoginErrorText, null, (l === null || l === void 0 ? void 0 : l.registration[loginError]) ||
                    'Unable to log in with provided credentials')),
                typeof redirectToActivationForm === 'function' && (React.createElement($ActivateAccountBtn, { show: !!showActivationButton &&
                        typeof redirectToActivationForm === 'function', onClick: () => {
                        redirectToActivationForm(inputState.email.value);
                    } }, "Activate your account")),
                React.createElement($SubmitLoadingButton, { type: "submit", theme: "white", style: { width: '100%', height: '60px' }, loading: inRequest, disabled: inputState.email.value.length < 1 ||
                        !!inputState.email.errorMessage ||
                        inputState.password.value.length < 1 }, (l === null || l === void 0 ? void 0 : l.registration.sign_in) || 'Sign in'))))));
};
const $BaseInput = styled(BaseInput) `
  ${inputStyles}
`;
const $TextButton = styled(TextButton) `
  ${passwordResetBtnStyles}
`;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$IconArrowLeft = exports.$IconArrowRight = exports.$SubscribeToUnlock = exports.$SubscribeToUnlockContent = exports.$SubscribeToUnlockTitle = exports.$PremiumBanner = exports.$SliderImgWrapper = exports.$BoardName = exports.$SliderItem = exports.$Navigation = exports.$Slider = void 0;
const constants_1 = require("../../common/constants");
const styled_components_1 = __importStar(require("styled-components"));
exports.$Slider = styled_components_1.default.div `
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`;
exports.$Navigation = styled_components_1.default.div `
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.shapes.black};
  opacity: 0.7;
  z-index: 10;
`;
const transformUpKeyframes = (0, styled_components_1.keyframes) `
  0% { transform: scale(1) translateY(25px); opacity: 0; }
  20% { transform: scale(1) translateY(15px); opacity: 0.10; }
  40% { transform: scale(1) translateY(10px); opacity: 0.20; }
  60% { transform: scale(1) translateY(5px); opacity: 0.40; }
  80% { transform: scale(0.9) translateY(3px); opacity: 0.80; }
  100% { transform: scale(1) translateY(0); opacity: 1; }
`;
exports.$SliderItem = styled_components_1.default.div `
  display: ${(props) => (props.$active ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  overflow: hidden;

  @media (${constants_1.media.tablet}) {
    gap: 8px;
  }
`;
exports.$BoardName = styled_components_1.default.div `
  font-size: 12px;
  line-height: 16px;

  @media (${constants_1.media.tablet}) {
    font-size: 16px;
    line-height: 20px;
  }
`;
exports.$SliderImgWrapper = styled_components_1.default.div `
  position: relative;
  display: inherit;
  width: 100%;
  height: 100%;

  @media (${constants_1.media.tablet}) {
    width: 360px;
    height: 360px;
  }

  ${({ $active }) => $active &&
    (0, styled_components_1.css) `
      animation: ${transformUpKeyframes} 0.5s ease-in-out both;
    `}
`;
exports.$PremiumBanner = styled_components_1.default.div `
  position: absolute;
  box-sizing: border-box;
  top: 8px;
  left: 8px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 8px;
  font-family: ${constants_1.fonts.primary};
  font-weight: ${constants_1.fontWeights.bold};
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text.black};
  background-color: ${({ theme }) => theme.semantic_color.text.attention};
  z-index: 10;
`;
exports.$SubscribeToUnlockTitle = styled_components_1.default.p `
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  margin: 0;
  transition: inherit;
  color: inherit;
`;
exports.$SubscribeToUnlockContent = styled_components_1.default.div `
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 111ms ease-out;
  color: white;

  svg {
    transition: inherit;
    path {
      transition: inherit;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.semantic_color.text.attention};
    transition: 0s;
  }
`;
exports.$SubscribeToUnlock = styled_components_1.default.div `
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(40, 40, 40, 0) 0%, rgba(40, 40, 40, 0.8) 100%);
  padding-bottom: 16px;
`;
exports.$IconArrowRight = (0, styled_components_1.default)(exports.$Navigation) `
  right: 10px;
`;
exports.$IconArrowLeft = (0, styled_components_1.default)(exports.$Navigation) `
  left: 10px;
`;

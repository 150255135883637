import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { BasePopup, BaseInput, ReloadIcon, SwitchCheckbox } from 'shared/atoms';
import { formatNumber } from 'shared/helpers/_common';
import { useCountdown } from '@utils/hooks/_useCountdown';
import { useForm } from 'shared/hooks/_useForm.hook';
import { LoadingBaseButton } from 'shared/molecules';
import { eActivationErrorCode } from 'shared/types';
import styles from './_confirm_popup.module.scss';
import { useHelpersStore } from '@store/storeshed';
import { loginActions } from '@store/storeshed';
import { useLoginStore } from '@store/storeshed';
import { GTM_EVENTS, gtmFind, gtmPush } from '@utils/_gtm';
import { openPopup } from '@utils/_router';
import { useApplicationContext } from '@application';
import { usePopupsContext } from '@store/context/popupsContext/_context';
import dayjs from 'dayjs';
import { ePopupPaths } from '@constants';
import { ePaywallPopupType } from '../PaywallPopup/_types';

const TIMER_MILLISECONDS = 5 * 60 * 1000; // min * sec * ms

// TODO: описание компонента
export const ConfirmPopup: React.FC = () => {
  const router = useRouter();
  const activationEmail = router.query.email as string;
  const from = router.query.from as string;

  const plans = useHelpersStore('plans');
  const inRequest = useLoginStore('activation_code_request');
  const error = useLoginStore('activation_code_error');
  const resendActivationCodeRequest = useLoginStore(
    'resend_activation_code_request'
  );
  const { localization: l } = useApplicationContext();

  const {
    state: {
      registration: { updates, isVerification },
    },
    computed: {
      registration: { formData },
    },
    actions: { setUpdates, closeRegistrationPopup },
  } = usePopupsContext();

  const { milliseconds, setMilliseconds, updateTimer } = useCountdown({
    timeInMilliseconds: TIMER_MILLISECONDS,
  });

  const { milliseconds: disabledMs, setMilliseconds: setDisabledMs } =
    useCountdown({
      timeInMilliseconds: 0,
    });

  const [showErrors, setShowErrors] = useState(false);

  const { inputState, onChange } = useForm<'code'>({
    code: {
      value: '',
      required: true,
      formatter: (val) => val.replace(/\D/g, '').substring(0, 6),
    },
  });

  const email = useMemo(() => {
    return formData.inputState.email.value;
  }, [formData.inputState.email.value]);

  const errorText = useMemo(() => {
    if (!error) return null;

    if (typeof error === 'object') {
      switch (error.code) {
        case eActivationErrorCode.THROTTLED:
          const seconds = formatNumber(disabledMs / 1000, 0);

          return seconds
            ? `${l.popups.activation.throttled} ${seconds} ${l.common.seconds}`
            : null;
        case eActivationErrorCode.INVALID_CODE:
          return l.popups.activation.invalid_code;
        case eActivationErrorCode.RESEND_THROTTLED:
          return null;

        default:
          return error.detail;
      }
    }

    return error;
  }, [error, l, disabledMs]);

  const prevStep = (): void => {
    const params = new URLSearchParams();
    params.append('popup', ePopupPaths.REGISTRATION);
    if (from) {
      params.append('from', from);
    }

    openPopup(`?${params.toString()}`);
  };

  const nextStep = (): void => {
    if (!gtmFind('event', GTM_EVENTS.COMPLETE_REGISTRATION)) {
      gtmPush({
        event: GTM_EVENTS.COMPLETE_REGISTRATION,
        paramEmail: email || activationEmail,
      });
    }

    const params = new URLSearchParams();
    params.append('popup', ePopupPaths.PAYWALL);
    params.append('type', ePaywallPopupType.REGISTRATION);

    if (from) {
      params.append('from', from);
    }

    openPopup(`?${params.toString()}`);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!inputState.code.value) {
      setShowErrors(true);
      return;
    }

    if (inRequest || !inputState.code.value) {
      return;
    }

    loginActions.activationCode({
      code: inputState.code.value,
      receiveNewsletters: updates,
      email: isVerification ? email : activationEmail,
      nextStep,
    });
  };

  const resendActivationCode = useCallback((): void => {
    if (milliseconds > 0 || resendActivationCodeRequest) return;

    const successCallback = () => setMilliseconds(TIMER_MILLISECONDS);
    loginActions.resendActivationCode(email, successCallback);
  }, [email, milliseconds, resendActivationCodeRequest, setMilliseconds]);

  const onResendCodeButtonClick = useCallback(() => {
    resendActivationCode();
  }, [resendActivationCode]);

  useEffect(() => {
    if (!email && !activationEmail) {
      prevStep();
    }
  }, [plans]);

  useEffect(() => {
    if (activationEmail) {
      loginActions.resendActivationCode(activationEmail);
    }
  }, [activationEmail]);

  useEffect(() => {
    if (error) {
      loginActions.setActivationCodeError(null);
    }
  }, [inputState.code.value]);

  useEffect(() => {
    if (error && typeof error === 'object') {
      if (error.time) {
        if (error.code === eActivationErrorCode.THROTTLED) {
          setDisabledMs(error.time * 1000);
        }

        if (error.code === eActivationErrorCode.RESEND_THROTTLED) {
          updateTimer(error.time * 1000);
        }
      }

      if (error.code === eActivationErrorCode.CODE_IS_RESET) {
        updateTimer(0);
      }
    }
  }, [error]);

  return (
    <BasePopup
      setShowPopup={closeRegistrationPopup}
      className={styles.popup}
      contentClassName={styles.popup_content}
      color="grey"
    >
      <div className={styles.container}>
        <div className={styles.heading}>
          <h3 className={styles.title}>{l.registration.new_account}</h3>
        </div>

        <div className={styles.subheading}>
          <div className={styles.email_text}>
            <p>{`${l.registration.confirmation_code}`}</p>
            <p>{`${l.registration.to} ${
              activationEmail ? activationEmail : email
            }`}</p>
          </div>
          <div className={styles.nav}>
            <div className={styles.back} onClick={() => prevStep()}>
              Back
            </div>
          </div>
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.input_row}>
            <BaseInput
              className={styles.input_code}
              name="code"
              type="text"
              placeholder={l.registration.code}
              value={inputState.code.value}
              theme="not_label_dark"
              onChange={onChange}
              showError={(showErrors && !inputState.code.value) || !!errorText}
            />

            <div
              className={`${styles.resend_code} ${
                !milliseconds && !resendActivationCodeRequest
                  ? styles.active
                  : ''
              }`}
            >
              <ReloadIcon width={13} height={15} />
              {milliseconds > 0 ? (
                <p className={styles.resend_code_text}>
                  {`${l.registration.resend_code} ${l.registration.in} ${dayjs(
                    milliseconds
                  ).format('mm:ss')}`}
                </p>
              ) : (
                <p
                  className={styles.resend_code_text}
                  onClick={onResendCodeButtonClick}
                >
                  {l.registration.resend_code}
                </p>
              )}
            </div>
          </div>

          <div className={styles.error}>{errorText}</div>

          <div className={styles.checkbox_container}>
            <SwitchCheckbox
              checked={updates}
              changeHandler={() => setUpdates(!updates)}
            >
              {l.registration.recieve_updates}
            </SwitchCheckbox>

            <p className={styles.checkbox_info_mobile}>
              {l.registration.get_event}
            </p>
          </div>

          <LoadingBaseButton
            type="submit"
            theme="white"
            buttonType="primary"
            className={styles.confirm}
            loading={inRequest}
            disabled={disabledMs !== 0}
          >
            {l.registration.confirm}
          </LoadingBaseButton>
        </form>
      </div>
    </BasePopup>
  );
};

import React from 'react';

import { PaymentInfo } from './_PaymentInfo';
import styles from './_paypal.module.scss';
import { useApplicationContext } from '@application';
import { Preloader } from 'shared/molecules/preloaders/_preloader';
interface IPayPal {
  awaitingPaypal: boolean;
  fideId: number;
  gameId: string;
}

export const PayPal: React.FC<IPayPal> = ({ awaitingPaypal }) => {
  const { localization: l } = useApplicationContext();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.text}>{l.membership.click_button_below}</div>

        <div className={styles.bottom}>
          {awaitingPaypal ? (
            <div className={styles.paypal_button}>
              <Preloader size="40px" />
            </div>
          ) : (
            <div
              id="paypal-button-container"
              className={styles.paypal_button}
            />
          )}

          <PaymentInfo />
        </div>
      </div>
    </div>
  );
};

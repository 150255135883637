import React from 'react';
import { $TableCell } from './_styles';
/**
 * Ячейка таблицы (DataCell)
 * @param {ReactNode} children - пробрасываемый компонент/текст
 * @param {string} className - внешний класс
 * @param {string} width - ширина ячейки
 * @param {string} align - горизонтальное позиционирование
 * @param {object} style - стили ячейки
 */
export const TD = ({ children, className = '', width, align = 'left', flex, style, }) => {
    const computedStyle = Object.assign(Object.assign({}, style), { justifyContent: align });
    if (width) {
        computedStyle.width = width;
    }
    if (flex) {
        computedStyle.flex = flex;
    }
    return (React.createElement($TableCell, { className: className, style: computedStyle }, children));
};

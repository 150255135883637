import React from 'react';

import styles from './_payment_info.module.scss';
import { useApplicationContext } from '@application';

export const PaymentInfo: React.FC = () => {
  const { localization: l } = useApplicationContext();
  return (
    <div className={styles.payment_info}>
      <span>{l.popups.tournament_payment.if_you_want}</span>
      <a
        href="mailto:support@chessarena.com"
        className={styles.terms_link}
        rel="noreferrer"
      >
        {l.popups.tournament_payment.support}
      </a>
    </div>
  );
};

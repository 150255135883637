import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { ISignInFormInputs } from '../../_types';
import {
  $Text,
  inputStyles,
  $ForgotContainer,
  passwordResetBtnStyles,
  $LoginErrorText,
  $ActivateAccountBtn,
  $SubmitLoadingButton,
} from '../../_styles';
import { useApplicationContext } from '@application';
import { BaseInput, TextButton } from 'shared/atoms';
import { UseFormState, useForm } from 'shared/hooks/_useForm.hook';
import { emailIsNotValid } from 'shared/utils/_validators';

interface IForm {
  login: (email: string, password: string) => void;
  loginError: string | null;
  inRequest: boolean;
  showActivationButton?: boolean;
  redirectToActivationForm?: (email: string) => void;
  showPasswordReset: boolean;
  setShowPasswordReset: (showPasswordReset: boolean) => void;
  resetError?: () => void;
}

export const Form: React.FC<PropsWithChildren<IForm>> = ({
  children,
  loginError,
  inRequest,
  showActivationButton,
  showPasswordReset,
  login,
  redirectToActivationForm,
  setShowPasswordReset,
  resetError,
}) => {
  const { localization: l } = useApplicationContext();

  const handleSubmit = async (
    state: UseFormState<ISignInFormInputs>
  ): Promise<void> => {
    try {
      await login(state.email.value, state.password.value);
    } catch (error) {
      console.log(error);
    }
  };

  const { inputState, onChange, onSubmit } = useForm<ISignInFormInputs>(
    {
      email: {
        value: '',
        required: true,
        validator: (val) => emailIsNotValid(val),
      },
      password: {
        value: '',
        required: true,
      },
    },
    handleSubmit
  );

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    onChange(e);
    if (resetError) resetError();
  };

  if (showPasswordReset) return null;

  return (
    <form onSubmit={onSubmit}>
      <$BaseInput
        name="email"
        type="email"
        placeholder={l?.common.email || 'email'}
        theme="login"
        value={inputState.email.value}
        errorText={inputState.email.errorMessage}
        onChange={changeHandler}
      />
      <$BaseInput
        name="password"
        type="password"
        theme="login"
        value={inputState.password.value}
        placeholder={l?.registration.password || 'Password'}
        onChange={changeHandler}
      />
      {children}

      <$ForgotContainer>
        <$Text>Forgot password?</$Text>

        <$TextButton
          type="button"
          iconSize="big"
          iconPosition="left"
          onClick={() => {
            console.log('Enter');

            setShowPasswordReset(!showPasswordReset);
          }}
        >
          Recover
        </$TextButton>
      </$ForgotContainer>

      {!!loginError && (
        <$LoginErrorText>
          {l?.registration[loginError] ||
            'Unable to log in with provided credentials'}
        </$LoginErrorText>
      )}

      {typeof redirectToActivationForm === 'function' && (
        <$ActivateAccountBtn
          show={
            !!showActivationButton &&
            typeof redirectToActivationForm === 'function'
          }
          onClick={() => {
            redirectToActivationForm(inputState.email.value);
          }}
        >
          Activate your account
        </$ActivateAccountBtn>
      )}

      <$SubmitLoadingButton
        type="submit"
        theme="white"
        style={{ width: '100%', height: '60px' }}
        loading={inRequest}
        disabled={
          inputState.email.value.length < 1 ||
          !!inputState.email.errorMessage ||
          inputState.password.value.length < 1
        }
      >
        {l?.registration.sign_in || 'Sign in'}
      </$SubmitLoadingButton>
    </form>
  );
};

const $BaseInput = styled(BaseInput)`
  ${inputStyles}
`;

const $TextButton = styled(TextButton)`
  ${passwordResetBtnStyles}
`;

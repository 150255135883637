import React, { useState, useEffect } from 'react';
import { IBaseSelectItem, BaseSelect } from 'shared/atoms';
import { countries } from 'shared/constants';
import styles from './_registration_form.module.scss';
import { useApplicationContext } from '@application';
import { usePopupsContext } from '@store/context/popupsContext/_context';

interface ICountrySelect {
  showErrors: boolean;
}

export const CountrySelect: React.FC<ICountrySelect> = ({ showErrors }) => {
  const { localization: l } = useApplicationContext();

  const {
    computed: {
      registration: {
        paid,
        formData: { inputState, onChange },
      },
    },
  } = usePopupsContext();

  const [items, setItems] = useState<Array<IBaseSelectItem> | null>(null);
  const [selectedCountryFlag, setSelectedCountryFlag] =
    useState<JSX.Element | null>(null);

  useEffect(() => {
    const newItems: Array<IBaseSelectItem> = [];

    countries.forEach((country) =>
      newItems.push({ label: country.name, value: country.id })
    );

    setItems(newItems);
  }, []);

  useEffect(() => {
    if (countries) {
      const selectedCountry = countries.find(
        (country) => country.id.toString() === inputState.country.value
      );

      if (selectedCountry) {
        setSelectedCountryFlag(
          <img
            src={selectedCountry.flag_image.svg}
            className={styles.flag_icon}
            alt={selectedCountry.long_code}
          />
        );
      } else {
        setSelectedCountryFlag(null);
      }
    }
  }, [inputState.country.value]);

  return (
    items && (
      <BaseSelect
        className={styles.input}
        items={items}
        onChange={onChange}
        name="country"
        value={inputState.country.value}
        placeholder={l.common.country}
        theme={paid ? 'not_label' : 'not_label_dark'}
        showError={showErrors && !inputState.country.value}
        icon={selectedCountryFlag}
      />
    )
  );
};

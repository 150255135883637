import styled from 'styled-components';

import {
  AppleAuthButton,
  FacebookAuthButton,
  GoogleAuthButton,
} from '@chessarena/components/lib/auth/socialAuthButtons';
import { screenSizes } from 'shared/styles';
import { useSocialAuthActions } from '@utils/hooks/_useSocialAuthActions.hook';

export const SocialAuthButtons: React.FC = () => {
  const { authWithApple, authWithFacebook, authWithGoogle } =
    useSocialAuthActions();
  return (
    <$Container>
      <AppleAuthButton onClick={authWithApple} />
      <GoogleAuthButton onClick={authWithGoogle} />
      <FacebookAuthButton onClick={authWithFacebook} />
    </$Container>
  );
};

const $Container = styled.div`
  display: flex;
  gap: 20px;
  width: 424px;

  @media ${screenSizes.MOBILE} {
    flex-direction: column;
    max-width: 308px;
    height: 172px;
  }
`;

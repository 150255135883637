import { $TextButtonText } from 'shared/atoms/buttons';
import { LoadingBaseButton } from 'shared/molecules';
import {
  shapeColors,
  baseColumn,
  screenSizes,
  Sign_in_H1,
  baseRow,
  Sign_in_P1,
  textColors,
  Game_P1,
  link,
} from 'shared/styles';
import styled, { css } from 'styled-components';

export const $SigninForm = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 15;
  width: 300px;
  min-height: 412px;
  background: ${shapeColors.grey};
  padding: 10px;
  ${baseColumn('stretch')};

  @media ${screenSizes.MOBILE} {
    top: 100%;
  }
`;

export const $IconButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const $Title = styled.h2`
  ${Sign_in_H1()}
`;

export const $NewUserContainer = styled.div`
  position: relative;
  ${baseRow('flex-start')};
  margin-top: 5px;

  margin-bottom: 15px;
`;

export const $Text = styled.p`
  ${Sign_in_P1()}
`;

export const $CreateAccountBtn = styled.p`
  text-decoration: none;
  ${link({
    borderWidth: '1px',
  })};
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    color: ${textColors.white};
  }

  &::after {
    width: 7px;
    height: 7px;
    border-width: 1px;
    margin-top: 2px;
  }

  &:hover::after {
    border-color: ${textColors.white};
    transition: 0s;
  }
`;

export const inputStyles = css`
  margin-bottom: 20px;
`;

export const $ForgotContainer = styled.div`
  position: relative;
  ${baseRow('flex-start')};
  margin-top: 35px;
  margin-bottom: 15px;
`;

export const passwordResetBtnStyles = css`
  margin-left: 5px;
  cursor: pointer;

  ${link({
    borderWidth: '1px',
  })};

  ${$TextButtonText} {
    font-size: 15px;
    line-height: 20px;
  }

  &::after {
    width: 7px;
    height: 7px;
    border-width: 1px;
    margin-top: 2px;
  }

  &:hover::after {
    border-color: ${textColors.white};
    transition: 0s;
  }
`;

export const $LoginErrorText = styled.p`
  ${Game_P1(textColors.red)};
  margin-bottom: 15px;
`;

export const $ActivateAccountBtn = styled.p<{ show: boolean }>`
  text-decoration: none;
  ${link({
    borderWidth: '1px',
  })};
  margin-bottom: 15px;
  cursor: pointer;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  &::after {
    width: 7px;
    height: 7px;
    border-width: 1px;
    margin-top: 2px;
  }
`;

export const $InviteContainer = styled.div`
  ${baseColumn('flex-start')}
  margin-bottom: 30px;
`;

export const $InviteSignInText = styled.p`
  margin-bottom: 5px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
  color: ${textColors['white']};
`;

export const $InviteNonRatedGameLink = styled.a`
  ${link({
    lineHeight: '20px',
    borderWidth: '1px',
  })}
  color: #ffde32;
  cursor: pointer;

  &::after {
    width: 7px;
    height: 7px;
    margin-top: 2px;
    border-width: 1px;
    border-color: #ffde32;
  }

  &:hover::after {
    transition: 0s;
  }
`;

export const $SubmitLoadingButton = styled(LoadingBaseButton)`
  &:disabled {
    background: #8b8b8b;
  }
`;

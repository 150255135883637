import { useMemo } from 'react';

import { usePaywallPopupContext } from '@components/molecules/popups/PaywallPopup/_context';
import { paywallItems } from './_constants';
import { ePaywallPopupStep } from './_types';
import { useApplicationContext } from '@application';

export const usePaywallPopupFeatures = () => {
  const { localization } = useApplicationContext();

  const { step } = usePaywallPopupContext();

  const features = useMemo(
    () =>
      paywallItems.map((item) => ({
        ...item,
        label: localization.paywall.info.items[item.type],
      })),
    [localization.paywall.info.items]
  );

  return step === ePaywallPopupStep.INFO ? features : undefined;
};

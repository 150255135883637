import React, { useEffect, Fragment } from 'react';

import styles from './_tabs.module.scss';
import { useApplicationContext } from '@application';
import { TabSelector } from 'shared/atoms/selectors/_tabSelector/_component';
import { BaseRadio } from 'shared/atoms/selectors/_baseRadio/_component';

interface ITabs {
  currentTab: string | null;
  setCurrentTab: (urrentTab: string | null) => void;
}

export const Tabs: React.FC<ITabs> = ({ currentTab, setCurrentTab }) => {
  const { localization: l } = useApplicationContext();
  const tabs = [
    { id: 1, value: 'i_pay', text: l.profile.payment.i_pay },
    { id: 2, value: 'paypal', text: l.registration.paypal },
    {
      id: 3,
      value: 'somebody_pay',
      text: l.profile.payment.somebody_pay,
      disabled: true,
    },
  ];

  const changeTabHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTab(e.target.value);
  };

  useEffect(() => {
    setCurrentTab(tabs[0].value);
  }, []);

  return (
    <Fragment>
      <div className={styles.popup_tabs_container}>
        <TabSelector
          className={styles.popup_tabs}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          type="white_outline"
        />
      </div>
      <div className={styles.popup_radio_container}>
        {tabs.map((tab) => (
          <BaseRadio
            key={tab.value}
            className={styles.popup_radio}
            value={tab.value}
            selected={currentTab}
            changeHandler={changeTabHandler}
            disabled={tab.disabled}
          >
            {tab.text}
          </BaseRadio>
        ))}
      </div>
    </Fragment>
  );
};

import React, { Fragment, memo, useMemo } from 'react';
import { useRouter } from 'next/router';
import { ISystemPopups, oppMode } from 'shared/types';

import { routesConstants } from '@constants';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import {
  $Title,
  $NewUserContainer,
  $Text,
  $CreateAccountBtn,
  $InviteContainer,
  $InviteNonRatedGameLink,
  $InviteSignInText,
} from '../../_styles';

interface IHeading {
  userUid: string;
  inviteFriendName?: string | null;
  setShowRegisterForm?: () => void;
  closePopup: (redirectToHome: boolean) => void;
  showPasswordReset: boolean;
  popups: ISystemPopups;
}

export const Heading: React.FC<IHeading> = memo(function Heading({
  inviteFriendName,
  closePopup,
  setShowRegisterForm,
  showPasswordReset,
  popups,
}) {
  const router = useRouter();
  const pathname = router.asPath;

  const {
    actions: { getGameFromInvite },
  } = useLobbyContext();

  const inviteCode = useMemo(() => {
    if (pathname.includes(routesConstants.FRIEND_INVITE)) {
      const uniqueId = pathname.split('?')[0].split('/')[2];
      return uniqueId;
    }

    return null;
  }, [pathname]);

  const onSkipAndPlayNonRatedClick = (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    evt.preventDefault();

    if (!inviteCode) {
      closePopup(true);
      return null;
    }

    getGameFromInvite({
      oppMode: oppMode.FRIEND,
      inviteCode: inviteCode,
      router,
      popups: popups,
    });

    closePopup(false);
  };

  if (showPasswordReset) return null;

  return (
    <Fragment>
      <$Title>Sign in</$Title>
      <$NewUserContainer>
        <$Text>New user?</$Text>
        <$CreateAccountBtn
          onClick={() => {
            if (typeof setShowRegisterForm === 'function') {
              setShowRegisterForm();
            }
          }}
        >
          Create an account
        </$CreateAccountBtn>
      </$NewUserContainer>
      <$InviteContainer>
        <$InviteSignInText>
          {inviteFriendName} invited you to play a rated game, which requires an
          account.
        </$InviteSignInText>
        <$InviteNonRatedGameLink onClick={onSkipAndPlayNonRatedClick}>
          Skip and play non-rated
        </$InviteNonRatedGameLink>
      </$InviteContainer>
    </Fragment>
  );
});

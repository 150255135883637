import React, { useState, useEffect } from 'react';
import { IBaseSelectItem, BaseSelect } from 'shared/atoms';
import { countries } from 'shared/constants';
import styles from './_fide_form.module.scss';
import { useApplicationContext } from '@application';
import { usePopupsContext } from '@store/context/popupsContext/_context';

interface IFormCountries {
  showErrors: boolean;
}

export const FormCountries: React.FC<IFormCountries> = ({ showErrors }) => {
  const { localization: l } = useApplicationContext();

  const {
    computed: {
      fide: {
        formData: { inputState, onChange },
      },
    },
  } = usePopupsContext();

  const [items, setItems] = useState<Array<IBaseSelectItem> | null>(null);

  useEffect(() => {
    const newItems: Array<IBaseSelectItem> = [];

    countries.forEach((country) => {
      newItems.push({
        label: country.name,
        value: country.id,
        icon: country.flag_image && (
          <img src={country.flag_image.svg} className={styles.flag_icon} />
        ),
      });
    });

    setItems(newItems);
  }, []);

  return (
    items && (
      <div className={styles.form_row}>
        <BaseSelect
          className={styles.citizenship}
          name="nationality"
          items={items}
          value={inputState.nationality.value}
          theme="secondary"
          onChange={onChange}
          placeholder={l.registration.citizenship}
          showError={showErrors && !inputState.nationality.value}
        />
        <BaseSelect
          className={styles.federation}
          name="federation"
          items={items}
          value={inputState.federation.value}
          theme="secondary"
          onChange={onChange}
          placeholder={l.registration.federation}
          showError={showErrors && !inputState.federation.value}
        />
      </div>
    )
  );
};

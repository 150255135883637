import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Закруглённая стрелка
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 */
export const ReloadIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 13 15", fill: "none" },
    React.createElement("path", { d: "M5.46288 8.21526L7.61927 2.14578L1.19115 0L0.718799 0.940054L4.55924 2.35014C1.93049 3.18801 0 5.66076 0 8.5218C0 12.0981 2.93681 15 6.48973 15C10.0837 15 13 12.0981 13 8.5218C13 6.35559 11.891 4.39373 10.207 3.22888L9.50869 4.23025C10.8641 5.19074 11.7062 6.7643 11.7062 8.5218C11.7062 11.4237 9.406 13.7534 6.48973 13.7534C3.61453 13.7534 1.25276 11.4237 1.25276 8.5218C1.25276 5.90599 3.22433 3.71935 5.7504 3.3515L4.39494 7.80654L5.46288 8.21526Z", fill: fill })));

import React from 'react';
import { Observer } from './_observerElement';
const hasWindow = () => {
    return typeof window !== 'undefined';
};
/**
 * Переписанная копия
 * https://github.com/sanishkr/react-progressive-graceful-image
 */
export class ProgressiveImage extends React.Component {
    constructor(props) {
        super(props);
        this.image = null;
        this.timeout = null;
        this.handleOnlineStatus = () => {
            this.setState({
                isOnline: window.navigator.onLine,
            });
        };
        this.loadImage = (src, srcSetData) => {
            // If there is already an image we nullify the onload
            // and onerror props so it does not incorrectly set state
            // when it resolves
            if (this.image) {
                this.image.onload = null;
                this.image.onerror = null;
            }
            const image = new Image();
            this.image = image;
            image.onload = this.onLoad;
            image.onerror = (errorEvent) => {
                this.onError(errorEvent);
                return;
                // this.handleImageRetries(image);
            };
            image.src = src;
            if (srcSetData) {
                image.srcset = srcSetData.srcSet;
                image.sizes = srcSetData.sizes;
            }
        };
        this.onLoad = () => {
            // use this.image.src instead of this.props.src to
            // avoid the possibility of props being updated and the
            // new image loading before the new props are available as
            // this.props.
            if (this.props.delay) {
                this.setImageWithDelay();
            }
            else {
                this.setImage();
            }
        };
        this.setImageWithDelay = () => {
            this.timeout = setTimeout(() => {
                this.setImage();
            }, this.props.delay);
        };
        this.setImage = () => {
            var _a, _b, _c;
            if (this._isMounted) {
                this.setState({
                    image: (_a = this.image) === null || _a === void 0 ? void 0 : _a.src,
                    loading: false,
                    srcSetData: {
                        srcSet: ((_b = this.image) === null || _b === void 0 ? void 0 : _b.srcset) || '',
                        sizes: ((_c = this.image) === null || _c === void 0 ? void 0 : _c.sizes) || '',
                    },
                }, () => {
                    window.removeEventListener('online', this.handleOnlineStatus);
                    window.removeEventListener('offline', this.handleOnlineStatus);
                });
            }
        };
        this.onError = (errorEvent) => {
            const { onError } = this.props;
            if (onError) {
                onError(errorEvent);
            }
        };
        this.handleIntersection = (event, unobserve, isOnline) => {
            if (event.isIntersecting) {
                const { src, srcSetData } = this.props;
                if (isOnline) {
                    this.loadImage(src, srcSetData);
                    unobserve();
                }
            }
        };
        this._isMounted = false;
        this.state = {
            isOnline: hasWindow() ? window.navigator.onLine : true,
            image: props.placeholder,
            loading: true,
            srcSetData: { srcSet: '', sizes: '' },
        };
    }
    componentDidMount() {
        this._isMounted = true;
        if (!hasWindow()) {
            return;
        }
        window.addEventListener('online', this.handleOnlineStatus);
        window.addEventListener('offline', this.handleOnlineStatus);
    }
    componentDidUpdate(prevProps) {
        const { src, placeholder, srcSetData } = this.props;
        // We only invalidate the current image if the src has changed.
        if (src !== prevProps.src) {
            this.setState({ image: placeholder, loading: true }, () => {
                this.loadImage(src, srcSetData);
            });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        if (this.image) {
            this.image.onload = null;
            this.image.onerror = null;
        }
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        window.removeEventListener('online', this.handleOnlineStatus);
        window.removeEventListener('offline', this.handleOnlineStatus);
        // this.clearEventListeners();
    }
    render() {
        const options = {
            onChange: (event, unobserve) => this.handleIntersection(event, unobserve, this.state.isOnline),
            rootMargin: this.props.rootMargin || '0% 0% 25%',
            threshold: this.props.threshold || [0],
            // disabled: this.props.noLazyLoad || false,
        };
        const { image, loading, srcSetData } = this.state;
        const { src, children, noLazyLoad } = this.props;
        if (!children || typeof children !== 'function') {
            throw new Error(`ProgressiveImage requires a function as its only child`);
        }
        if (noLazyLoad) {
            return children(src, false, this.props.srcSetData);
        }
        return (React.createElement(Observer, { options: options }, children(image, loading, srcSetData)));
    }
}

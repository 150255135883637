"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleAuthButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const _internal_1 = require("./_internal");
// TODO: Доработать, когда будет готов итоговый дизайн авторизаии
/**
 * Google social authorization button
 * @param {() => void} onClick
 * @param {boolean} showTitle - is text "Google" shown
 */
const GoogleAuthButton = (props) => {
    return ((0, jsx_runtime_1.jsx)(_internal_1.BaseAuthButton, Object.assign({ title: "Google" }, props, { children: (0, jsx_runtime_1.jsxs)("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsxs)("g", { clipPath: "url(#clip0_306_2123)", children: [(0, jsx_runtime_1.jsx)("path", { d: "M17.8242 9.2067C17.8242 8.59491 17.7746 7.9798 17.6688 7.37793H9.17969V10.8437H14.041C13.8393 11.9614 13.1911 12.9502 12.242 13.5786V15.8273H15.1422C16.8453 14.2598 17.8242 11.9449 17.8242 9.2067Z", fill: "#4285F4" }), (0, jsx_runtime_1.jsx)("path", { d: "M9.17934 18.001C11.6067 18.001 13.6537 17.204 15.1452 15.8283L12.2449 13.5795C11.438 14.1285 10.3963 14.4393 9.18265 14.4393C6.83468 14.4393 4.84387 12.8553 4.12955 10.7256H1.13672V13.0438C2.66455 16.0829 5.77644 18.001 9.17934 18.001Z", fill: "#34A853" }), (0, jsx_runtime_1.jsx)("path", { d: "M4.12659 10.725C3.74959 9.60727 3.74959 8.39691 4.12659 7.27914V4.96094H1.13706C-0.139438 7.50402 -0.139438 10.5002 1.13706 13.0432L4.12659 10.725Z", fill: "#FBBC04" }), (0, jsx_runtime_1.jsx)("path", { d: "M9.17934 3.56224C10.4625 3.5424 11.7026 4.02523 12.6318 4.9115L15.2014 2.34196C13.5743 0.814129 11.4149 -0.0258495 9.17934 0.000606499C5.77644 0.000606499 2.66455 1.91867 1.13672 4.96111L4.12625 7.27931C4.83725 5.1463 6.83137 3.56224 9.17934 3.56224Z", fill: "#EA4335" })] }), (0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("clipPath", { id: "clip0_306_2123", children: (0, jsx_runtime_1.jsx)("rect", { width: "18", height: "18", fill: "white" }) }) })] }) })));
};
exports.GoogleAuthButton = GoogleAuthButton;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderItem = SliderItem;
const jsx_runtime_1 = require("react/jsx-runtime");
const IconOuterLink_1 = require("../../../icons/IconOuterLink");
const react_1 = require("react");
const style_1 = require("../style");
function SliderItem(props) {
    const { value, isPremium, premiumProps, active, name, content } = props;
    const premiumContent = (0, react_1.useMemo)(() => {
        return (isPremium && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(style_1.$PremiumBanner, { children: premiumProps.bannerTitle }), (0, jsx_runtime_1.jsx)(style_1.$SubscribeToUnlock, { children: (0, jsx_runtime_1.jsxs)(style_1.$SubscribeToUnlockContent, { onClick: () => premiumProps.onTextButtonClick(value), children: [(0, jsx_runtime_1.jsx)(style_1.$SubscribeToUnlockTitle, { children: premiumProps.textButtonTitle }), (0, jsx_runtime_1.jsx)(IconOuterLink_1.IconOuterLink, { size: "xs" })] }) })] })));
    }, [isPremium, premiumProps, value]);
    return ((0, jsx_runtime_1.jsxs)(style_1.$SliderItem, { "$active": value === active, children: [(0, jsx_runtime_1.jsx)(style_1.$BoardName, { "$isPremium": isPremium, children: name }), (0, jsx_runtime_1.jsxs)(style_1.$SliderImgWrapper, { "$active": value === active, children: [content, premiumContent] })] }));
}

import React, { useEffect, useRef, } from 'react';
/**
 * Обертка над компонентом для использования IntersectionObserver
 */
export const Observer = ({ options, children, }) => {
    const { onChange, rootMargin, threshold } = options;
    const observableRef = useRef();
    useEffect(() => {
        let observer = null;
        const options = {
            rootMargin,
            threshold,
        };
        observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((element) => {
                if (element) {
                    onChange(element, () => observer.unobserve(element.target));
                }
            });
        }, options);
        if (observableRef.current) {
            observer.observe(observableRef.current);
        }
        return () => {
            observableRef.current && (observer === null || observer === void 0 ? void 0 : observer.unobserve(observableRef.current));
        };
    }, []);
    return children !== null
        ? React.cloneElement(React.Children.only(children), {
            ref: observableRef,
        })
        : null;
};

import React from 'react';
/**
 * Иконка: Черно-белый круг (ин-янь)
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 * @param {Function} onClick - обработчик клика
 */
export const BlackAndWhiteIcon = ({ width = '100%', height = '100%', onClick, className = '', }) => {
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 34 35", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className, onClick: onClick },
        React.createElement("path", { d: "M17.0214 4.41032e-05C26.4173 0.0212061 34.0145 7.63248 34.0004 17.0143C33.9863 26.4173 26.3397 34.0427 16.9579 34.0074C7.56198 33.9722 -0.0281376 26.3468 7.84148e-05 16.972C0.0282944 7.57605 7.64662 -0.0211179 17.0214 4.41032e-05ZM8.91634 3.28721C8.85286 3.32248 8.78232 3.35775 8.71178 3.40713C3.11795 7.10343 0.500913 12.2881 1.22042 18.9683C1.74947 23.8497 4.25365 27.6306 8.33086 30.3605C10.7081 31.9477 13.3533 32.7589 16.2031 32.9282C18.1853 33.0481 19.9911 32.5755 21.5853 31.3551C25.1335 28.6393 25.4016 23.1725 22.1356 20.1251C20.6895 18.7778 18.9754 18.0936 17.0002 18.0583C14.5031 18.016 12.3305 17.1625 10.4753 15.4695C7.7877 13.0147 6.7296 8.91631 7.94289 5.48806C8.21094 4.74034 8.59186 4.02083 8.91634 3.28721Z", fill: "white" })));
};

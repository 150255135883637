import styled from 'styled-components';
import { textColors, Game_H2, baseText, transparentColor, baseRow, shapeColors, } from '../../../../styles';
export const $Icon = styled.span `
  position: absolute;
  top: 50%;
  bottom: 12px;
  pointer-events: none;
  width: 25px;
  display: flex;
  max-height: 20px;
  text-align: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;
export const $Option = styled.option `
  color: ${textColors['black']};
`;
export const $Select = styled.select `
  position: relative;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 15px 15px 5px 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  cursor: pointer;
  border-bottom: 1px solid;
  border-radius: 0;

  &::-ms-expand {
    display: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 20px transparent inset;
  }
`;
export const $Placeholder = styled.span `
  position: absolute;
  top: 0;
  left: 0px;
  transform: translateY(10px);
  transition: 0.1s ease-in-out;
  transform-origin: left;
  pointer-events: none;
`;
export const $SelectWrapper = styled.label `
  position: relative;
  ${baseRow('flex-start', 'flex-end')};

  &::after {
    content: '';
    position: absolute;
    right: 2px;
    top: 50%;
    width: 10px;
    height: 10px;
    border-left: 1px solid ${shapeColors['white']};
    border-top: 1px solid ${shapeColors['white']};
    transform-origin: 50% 50%;
    border-radius: 0;
    transform: rotateZ(-135deg);
    transition: 0.1s;
    pointer-events: none;
  }

  &.primary {
    ${$Select} {
      ${Game_H2(textColors['white'])};
      border-color: ${textColors['grey_middle']};

      &:focus {
        + ${$Placeholder} {
          transform: translateY(-10px) scale(0.6);
          color: ${textColors['white']};
        }
      }
    }

    .disabled {
      border-color: ${transparentColor(textColors['black'], 0.5)};
      ${Game_H2(transparentColor(textColors['black'], 0.4))};
    }

    ${$Placeholder} {
      ${Game_H2(textColors['grey_middle'])};

      &.active {
        transform: translateY(-10px) scale(0.5);
        color: ${textColors['grey_middle']};
      }
    }
  }

  &.secondary {
    ${$Select} {
      border-color: ${transparentColor(textColors['white'], 0.5)};
      ${Game_H2(textColors['white'])};

      &:focus {
        + ${$Placeholder} {
          transform: translateY(-10px);
          ${baseText({
    color: textColors['black'],
})}
        }
      }
    }

    ${$Placeholder} {
      ${Game_H2(transparentColor(textColors['white'], 0.5))};

      &.active {
        transform: translateY(-10px);
        ${baseText({
    color: textColors['black'],
})}
      }
    }
  }

  &.primary_new_design {
    ${$Select} {
      border-color: ${transparentColor(textColors['white'], 0.5)};
      ${Game_H2(textColors['white'])};

      &:focus {
        + ${$Placeholder} {
          transform: translateY(-10px);
          ${baseText({
    color: textColors['white'],
})}
        }
      }
    }

    ${$Placeholder} {
      ${Game_H2(transparentColor(textColors['white'], 0.5))};

      &.active {
        transform: translateY(-10px);
        ${baseText({
    color: textColors['white'],
})}
      }
    }
  }

  &.not_label {
    ${$Select} {
      ${Game_H2(textColors['white'])};
      border-color: ${transparentColor(textColors['white'], 0.5)};
    }

    ${$Placeholder} {
      ${Game_H2(transparentColor(textColors['white'], 0.5))};

      &.active {
        display: none;
      }
    }
  }

  &.not_label_dark {
    ${$Select} {
      ${Game_H2(textColors['white'])};
      border-color: ${transparentColor(textColors['white'], 0.5)};

      &:focus {
        border-bottom: 1px solid ${textColors['white']};

        & + ${$Placeholder} {
          display: none;
        }
      }
    }

    ${$Placeholder} {
      ${Game_H2(transparentColor(textColors['white'], 0.5))};

      &.active {
        display: none;
      }
    }

    &.error {
      ${$Select} {
        &:not(:focus) {
          border-color: ${textColors['orange']};

          & + ${$Placeholder} {
            color: ${textColors['orange']};
          }
        }
      }
    }
  }

  &.not_border {
    ${$Select} {
      padding: 5px 15px 5px 0px;
      ${baseText({
    color: transparentColor(textColors['white'], 0.4),
})}
      border: none;
    }

    ${$Placeholder} {
      ${baseText({
    color: transparentColor(textColors['white'], 0.4),
})}

      &.active {
        display: none;
      }
    }

    &::after {
      border-color: ${transparentColor(textColors['white'], 0.4)};
      width: 8px;
      height: 8px;
      transform: translateY(-50%) rotateZ(-135deg);
    }
  }

  &.small {
    ${$Select} {
      padding: 5px 15px 5px 0px;
      ${baseText({
    color: transparentColor(textColors['white']),
})}
      border-color: ${transparentColor(textColors['white'], 0.1)};
      font-feature-settings: 'tnum' on, 'lnum' on;
    }

    ${$Placeholder} {
      ${baseText({
    color: transparentColor(textColors['white'], 0.4),
})}

      &.active {
        display: none;
      }
    }

    &::after {
      border-color: ${transparentColor(textColors['white'], 0.4)};
      width: 8px;
      height: 8px;
      transform: translateY(-50%) rotateZ(-135deg);
    }
  }

  &.error {
    ${$Select} {
      &:not(:focus) {
        border-color: ${textColors['red']} !important;

        & + ${$Placeholder} {
          color: ${textColors['red']};
        }
      }
    }
  }

  &.with_icon {
    ${$Select} {
      padding-left: 30px;
    }
  }

  &.disabled {
    &::after {
      border-left: 1px solid ${transparentColor(textColors['grey'], 0.7)};
      border-top: 1px solid ${transparentColor(textColors['grey'], 0.7)};
    }

    ${$Select} {
      cursor: default;
      border-bottom: 1px solid ${transparentColor(textColors['grey'], 0.7)};
    }

    ${$Placeholder} {
      color: ${transparentColor(textColors['grey'], 0.7)} !important;
    }
  }
`;

import React, { useCallback, useMemo } from 'react';
import { Table, TR, TH, TD, BaseButton } from 'shared/atoms';
import { getCountryById } from 'shared/helpers/_get_country_by_id';
import { IFideUsers } from 'shared/types';
import { FideUserListConfirmButton } from './_FideUserListConfirmButton';
import styles from './_fide_users_list.module.scss';
import { fideSubscriptionActions } from '@store/storeshed';
import { useApplicationContext } from '@application';
import { usePopupsContext } from '@store/context/popupsContext/_context';

interface IFideUsersList {
  users: Array<IFideUsers>;
  nextStep: () => void;
}

export const FideUsersList: React.FC<IFideUsersList> = ({
  users,
  nextStep,
}) => {
  const { localization: l } = useApplicationContext();

  const {
    state: {
      fide: { currentTab },
    },
    actions: { setFideId },
  } = usePopupsContext();

  const handleSubmit = (user: IFideUsers): void => {
    setFideId(user.fide_id);

    nextStep();
  };

  const onConfirmButtonClick = useCallback(
    () =>
      currentTab === 'create'
        ? nextStep()
        : fideSubscriptionActions.setFindFideUsers(null),
    [currentTab, nextStep]
  );

  const confirmButtonText = useMemo(
    () =>
      users && users.length > 1
        ? l.registration.im_not_in_the_list
        : l.registration.its_not_me,
    [l.registration.im_not_in_the_list, l.registration.its_not_me, users]
  );

  return (
    <div className={styles.container}>
      <div className={styles.users_list}>
        <Table className={styles.table} width="860px">
          <TR>
            <TH width="110px">{l.registration.is_this_you}</TH>
            <TH flex="1">{l.common.player}</TH>
            <TH width="100px">{l.registration.id_code}</TH>
            <TH width="90px">{l.common.federation}</TH>
            <TH width="65px">{l.common.title}</TH>
            <TH width="70px">{l.common.rating}</TH>
            <TH width="50px">{l.registration.b_year}</TH>
            <TH width="75px">{l.common.gender}</TH>
          </TR>
          {users.map((user) => (
            <TR key={user.fide_id} className={styles.table_row}>
              <TD width="110px">
                <BaseButton
                  theme="white"
                  buttonType="secondary"
                  className={styles.its_me_button}
                  onClick={() => handleSubmit(user)}
                >
                  {l.registration.its_me}
                </BaseButton>
              </TD>
              <TD flex="1" className={styles.table_cell}>
                <div className={styles.photo}>
                  {user.avatar ? (
                    <img className={styles.avatar_img} src={user.avatar} />
                  ) : (
                    <span className={styles.no_photo_text}>No photo</span>
                  )}
                </div>
                <div className={styles.name}>{user.full_name}</div>
              </TD>
              <TD width="100px" className={styles.table_cell}>
                {user.fide_id}
              </TD>
              <TD width="90px" className={styles.table_cell}>
                {getCountryById(user.federation)?.name}
              </TD>
              <TD width="65px" className={styles.table_cell}>
                {user.rank ? user.rank : '—'}
              </TD>
              <TD width="70px" className={styles.table_cell}>
                <ul className={styles.rating_list}>
                  <li className={styles.rating_item}>{`S ${
                    user.rating ? user.rating : '—'
                  }`}</li>
                  <li className={styles.rating_item}>{`R ${
                    user.rapid_rating ? user.rapid_rating : '—'
                  }`}</li>
                  <li className={styles.rating_item}>{`B ${
                    user.blitz_rating ? user.blitz_rating : '—'
                  }`}</li>
                </ul>
              </TD>
              <TD width="50px" className={styles.table_cell}>
                {user.birth_year}
              </TD>
              <TD width="75px" className={styles.table_cell}>
                {user.is_male ? 'Male' : 'Female'}
              </TD>
            </TR>
          ))}
        </Table>
      </div>

      {/* {fideError?.fide_id && (
        <div className={styles.form_error}>{fideError.fide_id}</div>
      )} */}

      <div className={styles.bottom}>
        <div className={styles.bottom_left}>
          {/* <div className={styles.not_you}>{l.registration.not_you}</div> */}

          <FideUserListConfirmButton
            onClick={onConfirmButtonClick}
            text={confirmButtonText}
          />

          <p className={styles.wrapper_text}>
            {l.registration.choose_yourself_from_list}
          </p>
        </div>
      </div>
    </div>
  );
};

import React from 'react';

interface IRoundedBorderWithStar {
  width?: number;
  height?: number;
  className?: string;
}

export const RoundedBorderWithStar: React.FC<IRoundedBorderWithStar> = ({
  width,
  height,
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 126 121"
      fill="none"
      className={className}
    >
      <path
        d="M11.6 29.2a1 1 0 0 0-1.9.8l.3 3a14.5 14.5 0 0 1-4 9.7l-2.3 2a1 1 0 0 0 .7 1.8c1-.2 2-.4 3-.4 1.8 0 3.6.4 5.3 1a13.3 13.3 0 0 1 6.3 5.2 1 1 0 0 0 1.8-.7l-.3-3c0-1.8.4-3.6 1-5.2a12.7 12.7 0 0 1 3-4.5c.6-.7 1.4-1.3 2.2-1.9a1 1 0 0 0-.7-1.8l-3 .3a12.8 12.8 0 0 1-9.5-4c-.7-.7-1.4-1.4-2-2.3Z"
        fill="#00F0FF"
        stroke="#525252"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <g filter="url(#a)">
        <path
          d="M9.9 38.6a15.5 15.5 0 0 0 .8-8.8c.6.9 1.3 1.7 2.1 2.4A13.8 13.8 0 0 0 23 36.5l3.2-.3c-1 .5-1.8 1.2-2.5 2-1.3 1.4-2.4 3-3 4.8-.7 1.8-1.1 3.6-1.2 5.5l.3 3.3a14.3 14.3 0 0 0-6.8-5.6 15.8 15.8 0 0 0-8.8-.7c1-.6 1.8-1.3 2.5-2.1 1.3-1.4 2.4-3 3.2-4.8Z"
          fill="#00F0FF"
        />
      </g>
      <g filter="url(#b)">
        <path
          d="M117.5 39.7A56.1 56.1 0 0 0 51.3 6.5a55.7 55.7 0 0 0-32 23.1l.4.2a55.2 55.2 0 0 1 51.6-24A55.2 55.2 0 0 1 116 40.3a54.5 54.5 0 0 1-25.2 67.5 53.4 53.4 0 0 1-78.2-39.5l-.5-4.9a54 54 0 0 1 .3-10 1.8 1.8 0 0 0-1.8-1.6A1.7 1.7 0 0 0 9 53.4c-.4 3.4-.5 6.8-.2 10.3 0 1.6.4 3.4.6 5.1a56.4 56.4 0 1 0 108.1-29.1Z"
          fill="#00F0FF"
        />
      </g>
      <defs>
        <filter
          id="a"
          x=".2"
          y="25.8"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.94 0 0 0 0 1 0 0 0 0.5 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_829_448"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_829_448"
            result="shape"
          />
        </filter>
        <filter
          id="b"
          x="4.6"
          y=".6"
          width="120.9"
          height="120"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.94 0 0 0 0 1 0 0 0 0.5 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_829_448"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_829_448"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

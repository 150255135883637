import React from 'react';

import { IPopup } from '@types';
import { useApplicationContext } from '@application';
import styles from './_preloader_popup.module.scss';
import { IBasePopup, BasePopup, BaseButton } from 'shared/atoms';
import { Preloader } from 'shared/molecules';
import { transparentColor, textColors } from 'shared/styles';

interface IPreloaderPopup extends IPopup {
  awaitingPaypal: boolean;
  color: IBasePopup['color'];
  className?: string;
  invoiceUrl?: string | null;
  paymentPopup?: Window | null;
  invoiceCallback?: (invoiceUrl: string) => void;
}

export const PreloaderPopup: React.FC<IPreloaderPopup> = ({
  awaitingPaypal,
  color,
  closePopup,
  className,
  paymentPopup,
  invoiceUrl,
  invoiceCallback,
}) => {
  const { localization: l } = useApplicationContext();
  return (
    <BasePopup
      className={className}
      contentClassName={styles.popup_preloader}
      setShowPopup={closePopup}
      color={color}
    >
      <Preloader
        className={styles.preloader}
        color={transparentColor(textColors.white, 0.2)}
      />

      {awaitingPaypal && (
        <div className={styles.awaiting_paypal}>
          <p>{l.popups.common.processing_payment}</p>
          <p>{l.popups.common.might_take}</p>
        </div>
      )}

      {!paymentPopup && invoiceUrl && invoiceCallback ? (
        <BaseButton
          className={styles.invoice_button}
          onClick={() => invoiceCallback(invoiceUrl)}
          theme="white"
        >
          Confirm Payment
        </BaseButton>
      ) : null}
    </BasePopup>
  );
};

import React, { useRef } from 'react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import styled from 'styled-components';
import { BaseInput, BaseSelect } from '../../../atoms';
import { $CardForm, $FormRow, inputStyles, dateInputStyles, cvcInputStyles, $Line, $PaypalContainer, $PaypalOr, $PaypalButton, } from './_styles';
import { syncedMoment } from '../../../../helpers/_common';
dayjs.extend(localeData);
/**
 * Форма карты оплаты
 * @param {boolean} showErrors - показывать ли ошибки
 * @param {object} cardState - объект с полями формы
 * @param {Function} onChange - функция изменения значений полей
 * @param {Function} showPaypal - показывать ли кнопку оплаты paypal
 */
export const CardForm = ({ cardState, onChange, showErrors, showPaypal = false, paypalInited = false, theme = 'black', }) => {
    const buttonContainerRef = useRef(null);
    const months = dayjs.months().map((month, index) => {
        const value = index + 1;
        const label = value < 10 ? `0${value}` : `${value}`;
        return { label, value };
    });
    const years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
        const value = syncedMoment().year() + i;
        return { label: String(value).slice(-2), value };
    });
    return (React.createElement($CardForm, { theme: theme },
        React.createElement($FormRow, null,
            React.createElement($BaseInput, { name: "card_number", type: "text", placeholder: "Card number", value: cardState.card_number.value, theme: theme === 'white' ? 'primary_new_design' : 'secondary', onChange: onChange, errorText: cardState.card_number.errorMessage, showError: showErrors && !cardState.card_number.value })),
        React.createElement($FormRow, null,
            React.createElement($BaseSelect, { items: months, name: "exp_month", placeholder: "MM", value: cardState.exp_month.value, theme: theme === 'white' ? 'primary_new_design' : 'secondary', onChange: onChange, showError: showErrors && !cardState.exp_month.value }),
            React.createElement($Line, null),
            React.createElement($BaseSelect, { items: years, name: "exp_year", placeholder: "YY", value: cardState.exp_year.value, theme: theme === 'white' ? 'primary_new_design' : 'secondary', onChange: onChange, showError: showErrors && !cardState.exp_year.value }),
            React.createElement($CvcInput, { name: "securitycode", type: "text", placeholder: "CVC", value: cardState.securitycode.value, theme: theme === 'white' ? 'primary_new_design' : 'secondary', onChange: onChange, errorText: cardState.securitycode.errorMessage, showError: showErrors && !cardState.securitycode.value })),
        React.createElement($FormRow, null,
            React.createElement($BaseInput, { name: "name", type: "text", placeholder: "Name on card", value: cardState.name.value, theme: theme === 'white' ? 'primary_new_design' : 'secondary', onChange: onChange, showError: showErrors && !cardState.name.value })),
        showPaypal && (React.createElement($PaypalContainer, { style: {
                display: paypalInited ? 'flex' : 'none',
            } },
            React.createElement($PaypalOr, null, "or"),
            React.createElement($PaypalButton, { id: "paypal-button-container", ref: buttonContainerRef })))));
};
const $BaseInput = styled(BaseInput) `
  ${inputStyles}
`;
const $BaseSelect = styled(BaseSelect) `
  ${dateInputStyles}
`;
const $CvcInput = styled(BaseInput) `
  ${cvcInputStyles}
`;

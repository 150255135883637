import React, { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { BasePopup } from 'shared/atoms';
import { PreloaderPopup } from '../_PreloaderPopup';
import styles from './_tournament_payment_popup.module.scss';
import { Heading } from './fragments/_Heading';
import { Tabs } from './fragments/_Tabs';
import { Form } from './fragments/_Form';
import { closePopup } from '@utils/_router';
import { usePaypal } from '@utils/hooks/_usePaypal.hook';
import {
  useUserDataStore,
  tournamentActions,
  useTournamentStore,
} from '@store/storeshed';

export const TournamentPaymentPopup: React.FC = () => {
  const userData = useUserDataStore('data');

  const router = useRouter();
  const id = router.query.id as string;

  const { getTournament } = tournamentActions;

  const tournamentData = useTournamentStore('tournament_data');

  const alreadyPaid: boolean = useMemo(() => {
    if (tournamentData?.product_sku && userData?.orders?.length) {
      const tournamentOrder = userData?.orders?.find(
        (order) =>
          order.product.stripe_id === tournamentData.product_sku?.stripe_id
      );

      return !!tournamentOrder;
    }

    return false;
  }, [userData?.orders, tournamentData?.product_sku]);

  const [currentTab, setCurrentTab] = useState<string | null>(null);
  const [timeIsOver, setTimeIsOver] = useState(true);

  const successCallback = async (): Promise<void> => {
    if (tournamentData?.id) {
      getTournament(tournamentData?.id);
    }
    closePopup();
  };

  const { paypalInited, awaitingPaypal } = usePaypal({
    customId:
      userData?.player.player_id && tournamentData?.product_sku?.stripe_id
        ? `${userData.player.player_id},${tournamentData.product_sku.stripe_id}`
        : null,
    paymentSuccess: alreadyPaid,
    showPaypalButton: currentTab === 'i_pay' && !timeIsOver && !alreadyPaid,
    type: 'product',
    price: tournamentData?.product_sku
      ? tournamentData.product_sku.price / 100
      : null,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (id && !router.pathname.includes(`/tournaments/${id}`)) {
      getTournament(id);
    }
  }, [router.pathname, id]);

  if (!tournamentData || awaitingPaypal) {
    return (
      <PreloaderPopup
        className={styles.popup}
        closePopup={closePopup}
        awaitingPaypal={awaitingPaypal}
        color="brown"
      />
    );
  }

  return (
    <BasePopup className={styles.popup} setShowPopup={closePopup} color="brown">
      <div className={styles.container}>
        <Heading />

        <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />

        {currentTab === 'i_pay' && (
          <Form
            closePopup={closePopup}
            paypalInited={paypalInited}
            timeIsOver={timeIsOver}
            setTimeIsOver={setTimeIsOver}
            alreadyPaid={alreadyPaid}
          />
        )}
      </div>
    </BasePopup>
  );
};

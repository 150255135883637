var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Fragment } from 'react';
import { BaseInput } from '../../../atoms';
import { LoadingBaseButton } from '../../buttons';
import { $Promocode, promocodeInputStyles, promocodeApplyStyles, $PromocodeApplied, $PromocodeInfo, } from './_styles';
import styled from 'styled-components';
/**
 * Форма проверки промокода
 * @param {string} promocod - промокод
 * @param {Function} promocodeHandler - колбэк сабмита промокода
 * @param {boolean} promocodeRequest - выполняется ли запрос на проверку промокода
 * @param {number} promocodeApplied - применён ли промокод
 * @param {string} promocodeError - текст ошибки проверки промокода
 */
export const PromocodeForm = ({ promocode, setPromocode, promocodeHandler, promocodeRequest, promocodeApplied, promocodeError, }) => {
    const handlePromocode = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        return promocodeHandler(promocode);
    });
    return (React.createElement(Fragment, null,
        React.createElement($Promocode, null,
            React.createElement($BaseInput, { name: "promocode", type: "text", placeholder: "Promo code", value: promocode, theme: "secondary", readOnly: promocodeApplied, onChange: ({ target }) => setPromocode(target.value), errorText: promocodeError, errorTheme: "min" }),
            promocodeApplied ? (React.createElement($PromocodeApplied, null, "Applied")) : (React.createElement($LoadingBaseButton, { theme: "white_inverted", buttonType: "primary", loading: promocodeRequest, onClick: handlePromocode }, "Apply"))),
        React.createElement($PromocodeInfo, null, "If you enter a promo code, we will charge your card \u20AC 1 and return it immediately. When paying with PayPal, the promo code is not applicable")));
};
const $BaseInput = styled(BaseInput) `
  ${promocodeInputStyles}
`;
const $LoadingBaseButton = styled(LoadingBaseButton) `
  ${promocodeApplyStyles}
`;

// TODO: Рефактор
import { helpersService } from '@services/_helpers.service';
import {
  useProfileStore,
  useUserDataStore,
  userDataActions,
} from '@store/storeshed';
import { getIsUserAllowedToPlayFOAGames } from '@utils/_getIsUserAllowedToPlayFOAGames';
import { getLSItem, setLSItem } from '@utils/_local_storage';
import { closePopup } from '@utils/_router';
import { useAlertPopup } from '@utils/hooks/_useAlertPopup.hook';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BasePopup, RatingIcon, TabSelector } from 'shared/atoms';
import { syncedMoment } from 'shared/helpers/_common';
import { LazyAvatar, Preloader, TimeControlSelector } from 'shared/molecules';
import {
  GameRatingMode,
  IChallengeItem,
  ITimeControl,
  IUserData,
} from 'shared/types';
import { AlertPopup } from '../_AlertPopup';
import styles from './_challenge_popup.module.scss';
import { ChallengeOptionsButton } from './fragments/_ChallengeOptionsButton';
import { ChallengeOptionsText } from './fragments/_ChallengeOptionsText';
import { ILongChallengeRequestParams } from '@types';

const ratingTabs = [
  { id: 1, value: GameRatingMode.UNRATED, text: 'Non-rated' },
  { id: 2, value: GameRatingMode.RATED, text: 'Rated', icon: RatingIcon },
];

const timeControls = helpersService.getGroupedTimeControls(
  process.env.NEXT_PUBLIC_ENV
);

export const ChallengePopup: React.FC = () => {
  const profileData = useProfileStore('data');
  const isOnline = useProfileStore('is_online');
  const userDataRequest = useUserDataStore('data_request');
  const userChallenges = useUserDataStore('challenges');
  const userChallengesRequest = useUserDataStore('challenges_request');
  const challengeOpponentData = useUserDataStore('challenge_opponent_data');

  const {
    isAlertPopupShown,
    alertPopupRef,
    onClose,

    alertText,
    showAlertPopup,
  } = useAlertPopup();

  const [selectedTimeControl, setTimeControl] = useState<ITimeControl | null>(
    getLSItem('challenge_time_control') || timeControls[1].controls[0]
  );

  const [currentRating, setCurrentRating] = useState<string>(
    getLSItem('challenge_current_rating') || GameRatingMode.UNRATED
  );

  const handleChangeTimeControl = (val: ITimeControl) => {
    setLSItem('challenge_time_control', val);
    setTimeControl(val);
  };

  const handleChangeCurrentRating = (val: string) => {
    setLSItem('challenge_current_rating', val);
    setCurrentRating(val);
  };

  const declinedChallenge = useMemo(() => {
    let index: IChallengeItem[] = [];

    if (profileData && userChallenges && userChallenges?.declined.length > 0) {
      index = userChallenges?.declined
        .filter((challenge) => challenge.opponent?.id === profileData.id)
        .filter((item) => 300 > syncedMoment().diff(item.created_at, 's'));
    }

    return !!index && !!index.length ? index[0] : null;
  }, [profileData, userChallenges]);

  useEffect(() => {
    if (userChallenges?.outcoming[0] || declinedChallenge) {
      const challengeData = userChallenges?.outcoming.length
        ? userChallenges?.outcoming[0]
        : declinedChallenge;

      if (!challengeData) return;

      if (challengeData.opponent?.id) {
        userDataActions.getChallengeOpponentProfileData(
          challengeData.opponent.id
        );
      }

      handleChangeTimeControl(challengeData.time_control);
      handleChangeCurrentRating(challengeData.rating_type);
    }
  }, [userChallenges]);

  const handleSubmitChallenge = async () => {
    if (userChallenges?.outcoming[0]) {
      userDataActions.deleteChallenge(userChallenges?.outcoming[0].id);

      return;
    }

    if (!profileData || !selectedTimeControl) return null;

    const params: ILongChallengeRequestParams = {
      opponentId: profileData.player.player_id,
      timeControlId: selectedTimeControl.id,
      ratingType: currentRating as GameRatingMode,
    };

    userDataActions.createChallenge({
      params,
      popups: {
        alert: showAlertPopup,
      },
    });
  };

  const currentProfileData = useMemo(() => {
    if (challengeOpponentData) {
      return challengeOpponentData;
    }

    if (profileData) {
      return { ...profileData, is_online: isOnline };
    }
  }, [challengeOpponentData, profileData, isOnline]);

  const existChallenge = useMemo(() => {
    return !!userChallenges?.outcoming[0];
  }, [userChallenges]);

  const opponentHasFideSubscribtion = useMemo(() => {
    if (currentProfileData) {
      return getIsUserAllowedToPlayFOAGames(currentProfileData as IUserData);
    }

    return false;
  }, [currentProfileData]);

  const setCurrentTabHandler = useCallback(
    (value: string) => handleChangeCurrentRating(value),
    []
  );

  if ((userDataRequest || userChallengesRequest) && !currentProfileData) {
    return (
      <BasePopup
        setShowPopup={closePopup}
        contentClassName={styles.popup_content}
        className={styles.popup}
        color="brown"
      >
        <Preloader size="100px" />
      </BasePopup>
    );
  }

  if (!currentProfileData) {
    closePopup();
    return null;
  }

  return (
    <BasePopup
      setShowPopup={closePopup}
      contentClassName={styles.popup_content}
      className={styles.popup}
      color="brown"
    >
      <div className={styles.challenge_popup_title}>
        <div className={styles.challenge_popup_avatar}>
          <LazyAvatar
            size="100%"
            preloaderSize="40px"
            image={currentProfileData?.avatar?.full}
            isOnline={currentProfileData?.is_online}
          />
        </div>
        <div className={styles.challenge_popup_title_text}>
          Game against{' '}
          {currentProfileData.first_name
            ? currentProfileData.first_name + ' ' + currentProfileData.last_name
            : currentProfileData.full_name
            ? currentProfileData.full_name
            : 'Anonymous'}
        </div>
      </div>
      <div className={styles.challenge_popup_container_wrapper}>
        <div className={styles.challenge_popup_left_container}>
          <div className={styles.challenge_popup_time_control}>
            <TimeControlSelector
              timeControls={timeControls}
              selectedTimeControl={selectedTimeControl}
              setTimeControl={handleChangeTimeControl}
              disabled={existChallenge}
            />
          </div>
          <div className={styles.challenge_popup_tabs_wrapper}>
            <TabSelector
              className={styles.challenge_popup_rating_selector}
              tabs={ratingTabs}
              currentTab={currentRating}
              setCurrentTab={setCurrentTabHandler}
              type="secondary"
              iconPosition="right"
              disabled={existChallenge}
            />
          </div>
          <ChallengeOptionsText
            currentRating={currentRating}
            selectedTimeControl={selectedTimeControl}
            opponentHasFide={!!opponentHasFideSubscribtion}
          />
          <ChallengeOptionsButton
            currentRating={currentRating}
            handleSubmitChallenge={handleSubmitChallenge}
            existChallenge={existChallenge}
            declinedChallenge={declinedChallenge}
            opponentHasFide={!!opponentHasFideSubscribtion}
          />
        </div>
        <div className={styles.challenge_popup_right_container}>
          <img
            src="https://gaming-images.worldchess.com/media/main_page/chessboard/standart.svg"
            alt="standart chessboard"
          />
        </div>
        {isAlertPopupShown && (
          <AlertPopup ref={alertPopupRef} onClick={onClose} text={alertText} />
        )}
      </div>
    </BasePopup>
  );
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { BaseButton } from '../../../atoms';
import { Preloader } from '../../preloaders';
/**
 * BaseButton с отображением загрузки
 * @param {ReactNode} children - текст кнопки
 * @param {boolean} loading  - определяет отображение загрузки на кнопке
 * @param {object} otherProps - любые другие значения, передаваемые кнопке
 */
export const LoadingBaseButton = (_a) => {
    var { loading = false, disabled = false, children } = _a, otherProps = __rest(_a, ["loading", "disabled", "children"]);
    return (React.createElement(BaseButton, Object.assign({}, otherProps, { disabled: loading || disabled }),
        React.Children.map(children, (child) => {
            const style = { opacity: loading ? 0 : 1, padding: '0' };
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    style,
                });
            }
            else if (typeof child === 'string') {
                return React.createElement('p', {
                    style,
                }, child);
            }
        }),
        loading && React.createElement(Preloader, { size: "80%" })));
};

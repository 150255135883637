import styled from 'styled-components';
import { buttonColors, textColors, baseText, transparentColor } from '../../../../styles';
export const $CheckboxWrapper = styled.label `
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:not(.disabled) {
    &:hover {
      .checkbox {
        border: 1px solid ${buttonColors['white']};
      }

      .input:checked + .checkbox {
        background-color: ${buttonColors['grey_light']};
        border-color: ${buttonColors['grey_light']};
      }
    }
  }

  &.disabled {
    cursor: default;

    .content {
      color: ${transparentColor(buttonColors['white'], 0.5)};
    }
  }
`;
export const $Checkbox = styled.span `
  display: inline-block;
  position: relative;
  border-radius: 90px;
  border: 1px solid ${transparentColor(buttonColors['white'], 0.5)};
  transition: 0.1s;
  cursor: pointer;

  &.normal {
    width: 55px;
    min-width: 55px;
    height: 26px;

    &:before {
      width: 20px;
      height: 20px;
      transform: translate(2px, -50%);
    }
  }

  &.big {
    width: 81px;
    min-width: 81px;
    height: 43px;

    &:before {
      width: 33px;
      height: 33px;
      transform: translate(4px, -50%);
    }
  }

  // circle
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    border-radius: 50%;
    background-color: ${buttonColors['white']};
    transition: transform 0.3s ease-in-out, background-color 0.1s;
    will-change: transform, background-color;
  }
`;
export const $Input = styled.input `
  visibility: hidden;
  width: 0;
  height: 0;

  &:checked {
    + ${$Checkbox} {
      background-color: ${buttonColors['white']};
      border: 1px solid ${buttonColors['white']};

      &:before {
        background-color: ${buttonColors['black']};
      }

      &.normal:before {
        transform: translate(31px, -50%) scale(1.1);
      }

      &.big:before {
        transform: translate(41px, -50%) scale(1.1);
      }
    }
  }

  &:disabled {
    + ${$Checkbox} {
      cursor: default;
      opacity: 0.33;
    }
  }
`;
export const $Content = styled.div `
  margin-left: 10px;
  ${baseText({ color: textColors['white'], fontSize: '22px' })}
  line-height: 25px;
`;

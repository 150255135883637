import styled, { css } from 'styled-components';
import { screenSizes, textColors, baseRow, baseText, baseColumn, } from '../../../../styles';
export const $PaymentContainer = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;

  @media ${screenSizes.MOBILE} {
    display: block;
  }
`;
export const $PaymentForm = styled.form `
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const $PaymentBody = styled.div `
  display: flex;
  margin-bottom: auto;

  @media ${screenSizes.MOBILE} {
    flex-direction: column;
  }
`;
export const $PaymentLeft = styled.div `
  flex: 1;
  max-width: 416px;
`;
export const $PaymentRight = styled.div `
  ${baseColumn('flex-start')}
  margin-left: 20px;
  max-width: 416px;
  flex: 1;

  @media ${screenSizes.MOBILE} {
    margin-left: 0;
    margin-top: 20px;
  }
`;
export const paymentCheckboxStyles = css `
  display: flex;
`;
export const $FormError = styled.p `
  ${baseText({ color: textColors.red })};

  @media ${screenSizes.MOBILE} {
    margin-top: 30px;
  }
`;
export const $PaymentFooter = styled.div `
  display: flex;
  margin-top: 30px;

  @media ${screenSizes.MOBILE} {
    max-width: 416px;
    margin-top: 36px;
    flex-direction: column;
  }
`;
export const $PaymentInfo = styled.div `
  margin-left: 20px;
  flex: 1;

  @media ${screenSizes.MOBILE} {
    margin-left: 0;
    margin-top: 10px;
  }
`;
export const paymentButtonStyles = css `
  width: 242px;
  height: 60px;
  ${baseRow()};

  @media ${screenSizes.MOBILE} {
    width: 100%;
  }
`;

import styled, { css, keyframes } from 'styled-components';
import { baseColumn, baseRow, buttonColors, Game_P1, Landing_H2, shapeColors, textColors, screenSizes, baseTransition, } from '../../../../styles';
export const $Row = styled.div `
  padding: 14px 23px 14px 20px;
  align-self: center;
  position: relative;
  ${baseColumn()}

  @media ${screenSizes.MOBILE} {
    padding: 1px 7px;
    flex-direction: row;
    justify-content: flex-start;
  }
`;
export const $Time = styled.div `
  ${Landing_H2()}
  line-height: 20px;
`;
export const $Addition = styled.div `
  ${Game_P1()}
  line-height: 15px;

  @media ${screenSizes.MOBILE} {
    margin-left: 5px;
    margin-top: 4px;
  }
`;
export const $Title = styled.div `
  padding: 9px 5px 9px 12px;
  align-self: flex-start;
  ${Game_P1()}
  position: relative;

  @media ${screenSizes.MOBILE} {
    padding: 1px 0 0 7px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const starAnimation = keyframes `
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;
export const $StarIconWrapper = styled.span `
  position: absolute;
  top: 5px;
  right: 7px;
  animation: ${starAnimation} 2s infinite;
  animation-timing-function: linear;
  will-change: opacity;
`;
export const $Button = styled.button `
  position: relative;
  width: 65px;
  height: 65px;
  border: none;
  background: transparent;
  border: 1px solid ${shapeColors['grey']};
  cursor: pointer;

  ${baseRow()}
  ${baseTransition};

  @media ${screenSizes.MOBILE} {
    /* width: 117px; */
    width: 116px;
    height: 48px;
  }

  @media ${screenSizes.PHONE} {
    width: 99px;
    height: 50px;
  }

  &:hover:not([disabled]) {
    background: ${buttonColors['grey_dark']};
  }

  &:disabled {
    cursor: default;
    ${$Time},
    ${$Addition},
  ${$Title} {
      color: ${textColors['grey']};
    }

    ${$StarIconWrapper} {
      animation: none;
      path {
        fill: ${textColors['grey']};
      }
    }
  }

  ${({ haveTitle }) => {
    return haveTitle
        ? css `
          display: flex;
          width: 130px;
          justify-content: space-between;

          ${$Row} {
            padding: 14px 23px 14px 10px;

            @media ${screenSizes.MOBILE} {
              padding: 8px 0 0;
            }
          }

          @media ${screenSizes.MOBILE} {
            justify-content: center;
            /* width: 117px; */
            width: 116px;
            height: 68px;
          }

          @media ${screenSizes.PHONE} {
            width: 99px;
            height: 67px;
          }
        `
        : '';
}}

  ${({ active }) => active
    ? css `
          border-color: ${buttonColors['white']} !important;
          z-index: 10;

          &:hover:not([disabled]) {
            background: transparent;
          }

          &:disabled {
            border: 1px solid ${shapeColors['grey']};

            ${$Time},
            ${$Addition},
          ${$Title} {
              color: ${textColors['white']};
            }
            ${$StarIconWrapper} {
              animation: none;
              path {
                fill: ${textColors['white']};
              }
            }
          }
        `
    : ''}
`;

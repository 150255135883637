import React, { Fragment } from 'react';
import { $ExternalClickArea } from './_styles';
/**
 * Компонент для отображения передаваемого ему компонента и окружающей его зоны, при
 * клике на который окно закрывается
 * @param {ReactComponent} component - компонент, который необходимо отобразить
 * @param {function} setShowPopup - задает состояние отображения текущего окна
 * @param {boolean} showPopup - показывать ли окно
 */
export const ExternalClosingPopup = ({ component, setShowPopup, showPopup, clickAreaStyles, }) => {
    const externalClick = () => {
        if (typeof setShowPopup === 'function') {
            setShowPopup(false);
        }
    };
    if (!showPopup)
        return null;
    return (React.createElement(Fragment, null,
        component,
        React.createElement($ExternalClickArea, { style: clickAreaStyles, onClick: externalClick })));
};

import React, { FC } from 'react';
import { useRouter } from 'next/router';

import { PaywallPopupContextProvider } from './_context';
import { PaywallPopupContent } from './_component';
import { ePaywallPopupType, ePaywallPopupStep } from './_types';
import { ePriceId } from 'shared/types';

export const PaywallPopup: FC = () => {
  const router = useRouter();
  const { query } = router;
  const { type, step, plan } = query;

  return (
    <>
      <PaywallPopupContextProvider
        type={type as ePaywallPopupType}
        step={step as ePaywallPopupStep}
        plan={plan as ePriceId}
      >
        <PaywallPopupContent />
      </PaywallPopupContextProvider>
    </>
  );
};

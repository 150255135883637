import { FC, memo } from 'react';

import PaywallPupup from '@chessarena/components/lib/paywall/PaywallPupup';

import { usePaywallPopupDataHook } from './_usePaywallPopupData.hook';

export const PaywallPopupContent: FC = memo(function PaywallPopupContent() {
  const {
    title,
    description,
    features,
    periods,
    plan,
    onChange,
    buttons,
    details,
    loading,
    seeAllLink,
    renderLink,
    onClose,
    handleButtonClick,
  } = usePaywallPopupDataHook();

  return (
    <PaywallPupup
      title={title}
      description={description}
      features={features}
      periods={periods}
      active={plan}
      onChange={onChange}
      buttons={buttons}
      details={details}
      loading={loading}
      seeAllLink={seeAllLink}
      renderLink={renderLink}
      onClose={onClose}
      onButtonClick={handleButtonClick}
    />
  );
});

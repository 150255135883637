import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { useBoardSettingsActions } from './_actions';
import { boardSettingsPopupReducer } from './_reducer';
import { IChessboardSettingsContextProps } from './_types';
import { useSettings } from './hooks/_useSettings.hook';
import { useComputed } from './hooks/useComputed.hook';

export const ChessboardSettingsContext = createContext(
  {} as IChessboardSettingsContextProps
);

export const ChessboardSettingsContextProvider: FC<PropsWithChildren> = memo(
  function ChessboardSettingsContextProvider({ children }) {
    const { currentSettings, localization } = useSettings();
    console.log('current settings', currentSettings);

    const [state, dispatch] = useReducer(boardSettingsPopupReducer, {
      currentSettings,
      playerCurrentSettings: currentSettings,
      translation: {
        popup_title: 'Appearance',
        board_pieces: 'Board and pieces',
        sounds: 'Sounds',
        premove: 'Premove',
        auto_queen: 'Auto-Queen',
        last_move: 'Last move',
        legal_moves: 'Legal moves',
        done: 'Done',
        cancel: 'Cancel',
        hide_dots: "Don't show",
        show_dots: 'Show with dots',
        textbook: 'Textbook',
        standard: 'Standard',
        standart: 'Standart',
        arma: 'Arma',
        arrow: 'Arrow',
        highlight: 'Highlight',
        worldchess: 'World Chess',
        ...localization,
      },
      opened: false,
    });

    const actions = useBoardSettingsActions(state, dispatch);
    const computed = useComputed(state, actions);

    const providerValue = useMemo(
      () => ({
        state,
        dispatch,
        actions,
        computed,
      }),
      [actions, computed, state]
    );

    return (
      <ChessboardSettingsContext.Provider value={providerValue}>
        {children}
      </ChessboardSettingsContext.Provider>
    );
  }
);

export const useBoardSettingsPopupContext = () =>
  useContext(ChessboardSettingsContext);

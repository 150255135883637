import React, { CSSProperties, FC, PropsWithChildren, memo } from 'react';
import Link from 'next/link';
import { Url } from 'next/dist/shared/lib/router/router';
import {
  BaseButton,
  TBaseButtonTheme,
  TBaseButtonType,
} from 'shared/atoms/buttons/_baseButton';
import styled from 'styled-components';

export interface ILinkButton {
  shallow?: boolean;
  href: Url;
  className?: string;
  buttonClassName?: string;
  theme?: TBaseButtonTheme;
  buttonType?: TBaseButtonType;
  disabled?: boolean;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  // type?: string;
}

/**
 * Компонент ссылки внутренней навигации, которая имеет вид базовой кнопки
 */
export const LinkButton: FC<PropsWithChildren<ILinkButton>> = memo(
  function LinkButton({
    shallow,
    href,
    buttonType,
    children,
    className,
    buttonClassName,
    disabled,
    theme,
    style,
    onClick,
  }) {
    return (
      <$StyledLink
        className={className}
        href={href}
        onClick={onClick}
        shallow={shallow}
      >
        <BaseButton
          className={buttonClassName}
          theme={theme}
          buttonType={buttonType}
          disabled={disabled}
          style={style}
        >
          {children}
        </BaseButton>
      </$StyledLink>
    );
  }
);

const $StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
`;

import React, { useEffect, useState } from 'react';
import { $Icon, $Option, $Select, $Placeholder, $SelectWrapper, } from './_styles';
import { useClassNames } from '../../../../hooks/_useClassNames.hook';
import { capitalizeString } from '../../../../helpers/_common';
import { areObjectsEqual } from '../../../../helpers/_equality';
function arePropsEqual(prevProps, nextProps) {
    return areObjectsEqual(prevProps, nextProps);
}
// TODO: refactor
/**
 * Селект
 * @param {Array.<{label: string; value: string;}>} items - опции селекта
 * @param {string} items.label - отображаемый текст опции
 * @param {string} items.value - значение опции
 * @param {string} value - текущее значение селекта
 * @param {string} placeholder - динамический плейсхолдер типа material
 * @param {function} onChange - обработчик события изменения значения селекта
 * @param {string} theme - тема селекта
 * @param {ReactNode} icon - иконка
 * @param {boolean} showError - отображать ли ошибку
 * @param {string} className - внешний класс
 * @param {CSS.Properties} style - внешние стили
 * @param {boolean} showEmptyValue - показывать ли в списке значений пустое
 */
export const BaseSelect = React.memo(({ onChange, value, placeholder = '', className = '', theme = 'primary', items, style, showError, disabled = false, icon, name = '', showEmptyValue = true, }) => {
    const [localValue, setLocalValue] = useState({
        label: '',
        value: '',
    });
    const labelClasses = useClassNames({
        [className]: true,
        [theme]: !!theme,
        ['disabled']: !!disabled,
        ['error']: !!showError,
        ['with_icon']: !!icon,
    });
    const spanClasses = useClassNames({
        ['active']: (value !== undefined &&
            value !== null &&
            value.toString().length !== 0) ||
            localValue.value.toString().length !== 0,
    });
    function changeHandler(event) {
        if (typeof onChange === 'function') {
            onChange(event);
        }
    }
    useEffect(() => {
        const currentItem = items.find((item) => item.value.toString() === value);
        if (currentItem) {
            setLocalValue(currentItem);
        }
    }, [value]);
    return (React.createElement($SelectWrapper, { className: labelClasses, style: style },
        React.createElement($Select, { value: value, disabled: disabled, onChange: changeHandler, name: name },
            showEmptyValue && React.createElement($Option, { value: "" }),
            items.map((item) => (React.createElement($Option, { key: item.value, value: item.value }, item.label)))),
        icon && React.createElement($Icon, null, icon),
        React.createElement($Placeholder, { className: spanClasses }, capitalizeString(placeholder))));
}, arePropsEqual);

import styled, { css } from 'styled-components';
import { shapeColors, Sign_in_H1, Sign_in_P1, link, Game_P1, textColors, baseRow, baseColumn, } from '../../../../styles';
import { LoadingBaseButton } from '../../buttons';
import { $TextButtonText } from '../../../atoms/buttons';
export const $SigninForm = styled.div `
  position: absolute;
  right: 0;
  top: 0;
  z-index: 15;
  width: 300px;
  min-height: 412px;
  background: ${shapeColors.grey};
  padding: 10px;
  ${baseColumn('stretch')};
`;
export const $IconButton = styled.div `
  position: absolute;
  right: 10px;
  top: 10px;
`;
export const $Title = styled.h2 `
  ${Sign_in_H1()}
`;
export const $NewUserContainer = styled.div `
  position: relative;
  ${baseRow('flex-start')};
  margin-top: 5px;
  margin-bottom: 70px;
`;
export const $Text = styled.p `
  ${Sign_in_P1()}
`;
export const $CreateAccountBtn = styled.p `
  text-decoration: none;
  ${link({
    borderWidth: '1px',
})};
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    color: ${textColors.white};
  }

  &::after {
    width: 7px;
    height: 7px;
    border-width: 1px;
    margin-top: 2px;
  }

  &:hover::after {
    border-color: ${textColors.white};
    transition: 0s;
  }
`;
export const inputStyles = css `
  margin-bottom: 20px;
`;
export const $ForgotContainer = styled.div `
  position: relative;
  ${baseRow('flex-start')};
  margin-top: 35px;
  margin-bottom: 15px;
`;
export const passwordResetBtnStyles = css `
  margin-left: 5px;
  cursor: pointer;

  ${link({
    borderWidth: '1px',
})};

  ${$TextButtonText} {
    font-size: 15px;
    line-height: 20px;
  }

  &::after {
    width: 7px;
    height: 7px;
    border-width: 1px;
    margin-top: 2px;
  }

  &:hover::after {
    border-color: ${textColors.white};
    transition: 0s;
  }
`;
export const $LoginErrorText = styled.p `
  ${Game_P1(textColors.red)};
  margin-bottom: 15px;
`;
export const $ActivateAccountBtn = styled.p `
  text-decoration: none;
  ${link({
    borderWidth: '1px',
})};
  margin-bottom: 15px;
  cursor: pointer;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  &::after {
    width: 7px;
    height: 7px;
    border-width: 1px;
    margin-top: 2px;
  }
`;
export const $SubmitLoadingButton = styled(LoadingBaseButton) `
  &:disabled {
    background: #8b8b8b;
  }
`;

import IconAutoQueen from '@chessarena/components/lib/game/IconAutoQueen';
import IconPremove from '@chessarena/components/lib/icons/IconPremove';
import IconSound from '@chessarena/components/lib/icons/IconSound';
import { useMemo } from 'react';
import {
  eAutoPromotionTheme,
  ePremovesTheme,
  eSoundsTheme,
} from 'shared/types';
import {
  IChessboardSettingsContextState,
  TChessboardSettingsActions,
} from '../_types';

export const useSwitchItems = (
  { currentSettings }: IChessboardSettingsContextState,
  {
    onSoundsSwitchChange,
    onPremoveSwitchChange,
    onAutoPromotionSwitchChange,
  }: TChessboardSettingsActions
) => {
  const switchItems = useMemo(() => {
    return [
      {
        description: 'Sounds',
        icon: <IconSound />,
        id: 'sounds',
        checked: currentSettings.soundsTheme === eSoundsTheme.ON,
        onChange: onSoundsSwitchChange,
      },
      {
        description: 'Premove',
        icon: <IconPremove />,
        id: 'premove',
        checked: currentSettings.premovesTheme === ePremovesTheme.ON,
        onChange: onPremoveSwitchChange,
      },
      {
        description: 'Auto-Queen',
        icon: <IconAutoQueen />,
        id: 'autoQueen',
        checked: currentSettings.autoPromotion === eAutoPromotionTheme.ON,
        onChange: onAutoPromotionSwitchChange,
      },
    ];
  }, [
    currentSettings.soundsTheme,
    currentSettings.premovesTheme,
    currentSettings.autoPromotion,
    onSoundsSwitchChange,
    onPremoveSwitchChange,
    onAutoPromotionSwitchChange,
  ]);

  return switchItems;
};

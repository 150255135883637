import React, { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { BasePopup } from 'shared/atoms';
import { product } from '@constants';
import { PreloaderPopup } from '../_PreloaderPopup';
import styles from './_analysis_popup.module.scss';
import { Form } from './fragments/_Form';
import { PayPal } from './fragments/_PayPal';
import { Heading } from './fragments/_Heading';
import { Tabs } from './fragments/_Tabs';
import { useUserDataStore } from '@store/storeshed';
import { closePopup } from '@utils/_router';
import { usePaypal } from '@utils/hooks/_usePaypal.hook';

export const AnalysisPopup: React.FC = () => {
  const router = useRouter();

  const userData = useUserDataStore('data');

  const [currentTab, setCurrentTab] = useState<string | null>(null);

  const fideId = useMemo(() => {
    const query = router.query;

    return Number(query.analyzer);
  }, [router.query]);

  const gameId = useMemo(() => {
    const gameId = router.asPath.split('/')[3];
    return gameId;
  }, [router.asPath]);

  const successCallback = async (): Promise<void> => {
    closePopup();
  };

  const { awaitingPaypal } = usePaypal({
    customId:
      userData?.player.player_id && product.stripe_id
        ? `${userData.player.player_id},${product.stripe_id},${fideId},${gameId}`
        : null,
    paymentSuccess: false,
    showPaypalButton: currentTab === 'paypal',
    type: 'product',
    price: product.price / 100,
    height: 55,
    closeAfterPayment: true,
    successCallback: successCallback,
  });

  const content = useMemo(() => {
    if (currentTab === 'i_pay') {
      return <Form closePopup={closePopup} fideId={fideId} gameId={gameId} />;
    }

    if (currentTab === 'paypal') {
      return (
        <PayPal
          awaitingPaypal={awaitingPaypal}
          fideId={fideId}
          gameId={gameId}
        />
      );
    }

    return null;
  }, [awaitingPaypal, currentTab, closePopup, fideId, gameId]);

  if (awaitingPaypal || !fideId) {
    return (
      <PreloaderPopup
        className={styles.popup}
        closePopup={closePopup}
        awaitingPaypal={awaitingPaypal}
        color="grey"
      />
    );
  }

  return (
    <BasePopup className={styles.popup} setShowPopup={closePopup} color="grey">
      <div className={styles.container}>
        <Heading fideId={fideId} />

        <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />

        {content}
      </div>
    </BasePopup>
  );
};

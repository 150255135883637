import { useCallback, useMemo } from 'react';
import Link from 'next/link';

import { PaywallPupupRenderLinkParams } from '@chessarena/components/lib/paywall/PaywallPupup/types';
import { usePaywallPopupContext } from './_context';
import { ePaywallPopupStep } from './_types';
import { usePaywallPopupTitle } from './_usePaywallPopupTitle';
import { usePaywallPopupDescription } from './_usePaywallPopupDescription';
import { usePaywallPopupFeatures } from './_usePaywallPopupFeatures.hook';
import { usePaywallPopupPeriods } from './_usePaywallPopupPeriods.hook';
import { usePaywallPopupButtons } from './_usePaywallPopupButtons.hook';
import { routesConstants } from '@constants';
import { useApplicationContext } from '@application';
import { closePopup } from '@utils/_router';
import { ePriceId } from 'shared/types';

export const usePaywallPopupDataHook = () => {
  const { localization } = useApplicationContext();

  const { step, plan, setPlan } = usePaywallPopupContext();

  const title = usePaywallPopupTitle();

  const description = usePaywallPopupDescription();

  const features = usePaywallPopupFeatures();

  const periods = usePaywallPopupPeriods();

  const { buttons, handleButtonClick, loading } = usePaywallPopupButtons();

  const onChange = useCallback(
    (plan: string) => {
      if (step === ePaywallPopupStep.PAYMENT_SETTINGS) {
        setPlan(plan as ePriceId);
      }
    },
    [setPlan, step]
  );

  const seeAllLink = useMemo(() => {
    if (step === ePaywallPopupStep.INFO) {
      return {
        href: routesConstants.PRICING,
        text: localization.paywall.info.buttons.seeAll,
      };
    }
    return undefined;
  }, [localization.paywall.info.buttons.seeAll, step]);

  const details = useMemo(() => {
    if (step === ePaywallPopupStep.INFO) {
      return localization.paywall.info.details;
    }
    return undefined;
  }, [localization.paywall.info.details, step]);

  const renderLink = useCallback((params: PaywallPupupRenderLinkParams) => {
    const { link, children } = params;

    return (
      <Link type="secondary" href={link}>
        {children}
      </Link>
    );
  }, []);

  return {
    title,
    description,
    features,
    periods,
    plan,
    onChange,
    buttons,
    details,
    seeAllLink,
    renderLink,
    onClose: closePopup,
    handleButtonClick,
    loading,
  };
};

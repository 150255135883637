import styled, { css } from 'styled-components';
import { screenSizes, textColors, baseRow, transparentColor } from '../../../../styles';
export const $FormRow = styled.div `
  display: flex;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;
export const inputStyles = css `
  width: 100%;

  input {
    text-transform: uppercase;
  }
`;
export const dateInputStyles = css `
  width: 70px;

  @media ${screenSizes.MOBILE} {
    &:not(:first-of-type) {
      margin-left: 15px;
    }
  }

  input {
    text-transform: uppercase;
  }
`;
export const cvcInputStyles = css `
  width: 55px;
  margin-left: auto;

  input {
    text-transform: uppercase;
  }
`;
export const $Line = styled.div `
  width: 32px;
  margin: 0 15px;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 47px;
    height: 47px;
    transform: rotate(-45deg);
    left: -25px;
    top: -7px;
  }

  @media ${screenSizes.MOBILE} {
    display: none;
  }
`;
export const $PaypalContainer = styled.div `
  ${baseRow('flex-start')};
  position: absolute;
  right: 5px;
  top: 5px;
`;
export const $PaypalOr = styled.span `
  margin-right: 6px;
`;
export const $PaypalButton = styled.div `
  display: flex;
  width: 150px;
  height: 25px;
  overflow: hidden;
`;
export const $CardForm = styled.div `
  position: relative;
  border: 1px solid
    ${({ theme }) => theme === 'white'
    ? transparentColor(textColors.white, 0.5)
    : transparentColor(textColors.black, 0.5)};
  border-radius: 5px;
  padding: 15px 15px 10px;
  max-width: 416px;
  width: 100%;
  height: 236px;

  ${$Line} {
    &::after {
      border-bottom: 1px solid
        ${({ theme }) => theme === 'white'
    ? transparentColor(textColors.white, 0.5)
    : transparentColor(textColors.black, 0.5)};
    }
  }
`;

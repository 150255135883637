"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaywallPupupFeatureTypes = void 0;
exports.PaywallPupupFeatureTypes = {
    fide: 'fide',
    skins: 'skins',
    statistics: 'statistics',
    analysis: 'analysis',
    more: 'more',
};

import React from 'react';
import { $TableRow } from './_styles';
/**
 * Строка таблицы
 * @param {ReactNode} children - пробрасываемый компонент
 * @param {string} className - внешний класс
 * @param {object} style - стили строки
 * @param {Function} onClick - внешний класс
 */
export const TR = ({ children, className = '', style, onClick, }) => (React.createElement($TableRow, { className: className, onClick: onClick, style: style }, children));

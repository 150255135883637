"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$InputSwitchIcon = exports.$IconSwitchDescription = exports.$InputSwitchWrapper = exports.$AdditionalSettings = exports.$Divider = void 0;
const constants_1 = require("../../common/constants");
const styled_components_1 = __importStar(require("styled-components"));
exports.$Divider = styled_components_1.default.div `
  flex-shrink: 0;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.shapes.white010};

  @media (${constants_1.media.tablet}) {
    margin-top: -4px;
    margin-bottom: -4px;
  }
`;
exports.$AdditionalSettings = styled_components_1.default.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 70px;

  @media (${constants_1.media.tablet}) {
    gap: 16px;
  }
`;
exports.$InputSwitchWrapper = styled_components_1.default.div `
  display: flex;
  align-items: center;
  gap: 8px;
`;
exports.$IconSwitchDescription = styled_components_1.default.div `
  font-size: 16px;
  line-height: 20px;
`;
exports.$InputSwitchIcon = styled_components_1.default.div `
  ${({ $colorIcon }) => $colorIcon === 'sounds' &&
    (0, styled_components_1.css) `
      color: ${({ theme }) => theme.colors.buttons.yellow};
    `}
  ${({ $colorIcon }) => $colorIcon === 'premove' &&
    (0, styled_components_1.css) `
      color: ${({ theme }) => theme.colors.shapes.orange};
    `}
`;

import React from 'react';

import styles from './_payment_info.module.scss';
import { useApplicationContext } from '@application';

export const PaymentInfo: React.FC = () => {
  const { localization: l } = useApplicationContext();
  return (
    <div className={styles.payment_info}>{l.popups.analysis.we_will_send}</div>
  );
};

import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import { usePaywallPopupContext } from './_context';
import { ePaywallPopupPaymentMethod, ePaywallPopupStep } from './_types';
import { paymentService } from '@services/_payment.service';
import { useApplicationContext } from '@application';
import { closePopup } from '@utils/_router';
import {
  ButtonClickEventParams,
  ButtonProps,
} from '@chessarena/components/lib/general/Button/types';
import { usePaywallPopupPaypal } from './_usePaywallPopupPaypal.hook';
import { useUserDataStore, userDataActions } from '@store/storeshed';
import { eAccessLevel, ePriceId } from 'shared/types';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

export const usePaywallPopupButtons = () => {
  const { localization } = useApplicationContext();
  const router = useRouter();

  const userData = useUserDataStore('data');

  const { step, setStep, plan, awaitingPaypal, setAwaitingPaypal } =
    usePaywallPopupContext();

  const [inRequest, setInRequest] = useState(false);
  const [openPaypal, setOpenPaypal] = useState(false);
  const intervalRef: MutableRefObject<ReturnType<typeof setInterval> | null> =
    useRef(null);

  const buySubscriptionStripe = useCallback(async () => {
    setInRequest(true);
    const pageUrl = `${window.location.origin}${window.location.pathname}?subscriptionType=${plan}`;
    const successUrl = `${pageUrl}&paidResult=1`;
    const cancelUrl = `${pageUrl}&paidResult=0`;

    try {
      const { data, ok } =
        await paymentService.getSubscriptionCheckoutSessionUrl({
          priceId: plan,
          successUrl,
          cancelUrl,
        });

      if (ok) {
        router.push(data.checkout_session_url);
      }
    } catch (err) {
      console.log('buy subscription error', err);
    } finally {
      setInRequest(false);
      closePopup();
    }
  }, [plan, router]);

  const buySubscriptionPaypal = useCallback(async () => {
    setInRequest(true);
  }, []);

  const buySubscriptionPaypalClose = useCallback(async () => {
    setInRequest(false);
  }, []);

  const buySubscriptionPaypalSuccess = useCallback(async () => {
    setAwaitingPaypal(true);
    gtmPush({
      event: GTM_EVENTS.PURCHASE,
      paramUserId: userData?.player.player_id,
      paramProductId: plan as string,
    });
    gtmPush({
      event:
        plan === ePriceId.PRO_ANNUAL
          ? GTM_EVENTS.PURCHASE_YEAR
          : GTM_EVENTS.PURCHASE_MONTH,
      paramUserId: userData?.player.player_id,
    });
  }, [plan, setAwaitingPaypal, userData?.player.player_id]);

  const { paypalInitialized } = usePaywallPopupPaypal({
    userId: `${userData?.player.player_id}`,
    plan,
    open: openPaypal,
    onClick: buySubscriptionPaypal,
    onClose: buySubscriptionPaypalClose,
    onSuccess: buySubscriptionPaypalSuccess,
  });

  const onBuySubscriptionClick = useCallback(
    (id?: string) => {
      switch (id) {
        case ePaywallPopupPaymentMethod.STRIPE:
          buySubscriptionStripe();
          break;
        case ePaywallPopupPaymentMethod.PAYPAL:
          break;
        default:
          break;
      }
    },
    [buySubscriptionStripe]
  );

  const handleButtonClick = useCallback(
    ({ id }: ButtonClickEventParams) => {
      switch (step) {
        case ePaywallPopupStep.INFO:
          setStep(ePaywallPopupStep.PAYMENT_SETTINGS);
          break;
        case ePaywallPopupStep.PAYMENT_SETTINGS:
          onBuySubscriptionClick(id);
          break;
        default:
          return;
      }
    },
    [setStep, onBuySubscriptionClick, step]
  );

  const buttons = useMemo<ButtonProps[]>(() => {
    switch (step) {
      case ePaywallPopupStep.PAYMENT_SETTINGS:
        return [
          {
            id: ePaywallPopupPaymentMethod.STRIPE,
            type: 'yellow',
            children: localization.paywall.payment_settings.buttons.stripe,
            loading: inRequest,
          },
          {
            id: ePaywallPopupPaymentMethod.PAYPAL,
            type: 'tertiary',
            children: localization.paywall.payment_settings.buttons.paypal,
            loading: inRequest || !paypalInitialized,
            custom: <div id="paywall_popup_paypal_button_container" />,
          },
        ];
      case ePaywallPopupStep.INFO:
      default:
        return [
          {
            id: 'nextStep',
            type: 'yellow',
            children: localization.paywall.info.buttons.submit,
            loading: inRequest,
          },
        ];
    }
  }, [inRequest, localization.paywall, paypalInitialized, step]);

  useEffect(() => {
    if (step === ePaywallPopupStep.PAYMENT_SETTINGS) setOpenPaypal(true);
    else setOpenPaypal(false);
  }, [step]);

  useEffect(() => {
    if (awaitingPaypal) {
      userDataActions.getMeData();

      const interval = setInterval(async () => {
        await userDataActions.getMeData();
      }, 3000);

      intervalRef.current = interval;
    }
  }, [awaitingPaypal]);

  useEffect(() => {
    if (inRequest && userData?.access_level === eAccessLevel.PRO) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      closePopup();
      setInRequest(false);
    }
  }, [inRequest, userData?.access_level]);

  return { buttons, handleButtonClick, loading: awaitingPaypal };
};

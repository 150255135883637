import { useCallback, useState } from 'react';
import { IRequestError } from '@types';
import { httpStatuses } from 'shared/constants';
import { userService } from '@services/_user.service';

export const useLoginData = () => {
  const [inRequest, setInRequest] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [showActivationCodePopup, setShowActivationCodePopup] = useState(false);

  const login = useCallback(async (email: string, pwd: string) => {
    setInRequest(true);
    setError(null);

    try {
      const {
        ok,
        data: { token },
      } = await userService.login(email, pwd);

      if (ok) {
        setToken(token);
      }
    } catch (err) {
      const error = err as IRequestError<{ code: string; detail: string }>;
      const statusCode = error.status;
      const errorCode = error.data.code;
      const authError = error.data.detail;

      if (statusCode === httpStatuses.BAD_REQUEST) {
        if (errorCode === 'not_verified') {
          setShowActivationCodePopup(true);
        } else {
          setError(errorCode);
        }
      } else if (statusCode === 401) {
        if (errorCode === 'player_banned') {
          setError(authError);
        }
      }
    }

    setInRequest(false);
  }, []);

  return { login, inRequest, error, token, showActivationCodePopup };
};

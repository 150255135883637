"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("./style");
const InputSwitch = (props) => {
    const { id, size = 'm', error = false, disabled = false, onFocus = () => null, onBlur = () => null, onChange = () => null, value = false, block = false, children, } = props;
    const [focused, setFocused] = (0, react_1.useState)(false);
    const [disallowBlurFlag, setDisallowBlurFlag] = (0, react_1.useState)(false);
    const inputNode = (0, react_1.useRef)(null);
    const handleFocus = (0, react_1.useCallback)(() => {
        if (!focused) {
            setFocused(true);
            onFocus({ id, value });
        }
    }, [focused, id, onFocus, value]);
    const handleBlur = (0, react_1.useCallback)(() => {
        if (disallowBlurFlag)
            return;
        setFocused(false);
        onBlur({ id, value });
    }, [disallowBlurFlag, onBlur, id, value]);
    const handleChange = (0, react_1.useCallback)((e) => {
        onChange({ id, value: e.currentTarget.checked });
    }, [onChange, id]);
    const disallowBlur = () => {
        setDisallowBlurFlag(true);
    };
    const allowBlur = (0, react_1.useCallback)(() => {
        if (disallowBlurFlag) {
            setDisallowBlurFlag(false);
            inputNode.current.focus();
        }
    }, [disallowBlurFlag]);
    (0, react_1.useEffect)(() => {
        if (disabled && focused)
            handleBlur();
    }, [disabled, focused, handleBlur]);
    const checked = (0, react_1.useMemo)(() => !!value, [value]);
    return ((0, jsx_runtime_1.jsx)(style_1.$InputSwitch, { htmlFor: id, "data-checked": checked, "$size": size, "$disabled": disabled, "$checked": checked, "$focused": focused, "$error": error, "$block": block, onMouseDown: disallowBlur, onMouseUp: allowBlur, children: (0, jsx_runtime_1.jsxs)(style_1.$InputSwitchInner, { children: [(0, jsx_runtime_1.jsx)(style_1.$InputSwitchHtmlInput, { ref: inputNode, id: id, checked: checked, "data-checked": checked, disabled: disabled, onFocus: handleFocus, onBlur: handleBlur, onChange: handleChange }), (0, jsx_runtime_1.jsx)(style_1.$InputSwitchIcon, { "$size": size, "$checked": checked, children: (0, jsx_runtime_1.jsx)(style_1.$InputSwitchIconInner, {}) }), children && (0, jsx_runtime_1.jsx)(style_1.$InputSwitchLabel, { "$size": size, children: children })] }) }));
};
exports.default = InputSwitch;
